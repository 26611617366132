import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as EditPencil } from "../../Assets/Svg/EditPencil.svg";
import AddSubWorkspacePopup from "../../Components/Popup/AddSubWorkspacePopup";
import EditSubDebatePopup from "../../Components/Popup/EditSubDebatePopup";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import {
  useGetDebatedUsers,
  useGetPrivateUsers,
  useGetSemiDebatedUsers,
  useGetSemiVotedUsers,
  useGetVotedUsers,
} from "../../Web-Hooks/Debate/use-users";
import AccordionNav from "./AccordionNav";
import MessageToggleNav from "./MessageToggleNav";

export default function FeedSidebar() {
  const location = useLocation();
  const { pathname } = location;
  const [toggle, setToggle] = useState();
  const [open, setOpen] = useState(0);
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace, subWorkspaceList, selectSubWorkspace } =
    useSubWorkspace();
  const { user: loggedInUser } = useAuth();
  const { data: recentVotedUsers } = useGetVotedUsers(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId
  );
  const { data: recentSemiVotedUsers } = useGetSemiVotedUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );
  const { data: recentPrivateUser } = useGetPrivateUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );
  const { data: recentDebatedUsers } = useGetDebatedUsers(
    loggedInUser?.userId,
    selectedWorkspace?.workSpaceId
  );
  const { data: recentSemiDebatedUsers } = useGetSemiDebatedUsers(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId
  );

  const [openEditWorkspace, setEditWorkspacePopup] = useState(false);
  const [popup, setPopup] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);

  const PublicFeedBarOptions = [
    {
      option: "Feed ",
      optionLabel: "(Public)",
      subOption: [],
      route: "/feed-public",
      name: "public-feed",
    },
    {
      option: "Debated",
      optionLabel: "(Public)",
      name: "public-debated",
      subOption: recentDebatedUsers,
      route: "/debated-public",
    },
    {
      option: "Voted",
      optionLabel: "(Public)",
      name: "public-voted",
      subOption: recentVotedUsers,
      route: "/voted-public",
    },
    {
      option: "Reference",
      subOption: [],
      route: "/reference",
      name: "reference",
    },
    // {
    //   option: "News",
    //   subOption: [],
    //   route: "/news",
    //   name: "news",
    // },
  ];
  const FeedBarOptions = [
    {
      option: "Feed",
      subOption: [],
      route: "/feed",
      name: "feed",
    },
    {
      option: "Debated",
      subOption: recentSemiDebatedUsers,
      route: "/debated",
      name: "debated",
    },
    {
      option: "Voted",
      subOption: recentSemiVotedUsers,
      route: "/voted",
      name: "voted",
    },
    {
      option: "Private",
      subOption: recentPrivateUser,
      route: "/private",
      name: "private",
    },
  ];

  const handleOpen = (update) => {
    setOpen(open === update ? 0 : update);
  };

  useEffect(() => {
    const path = pathname.slice(1).split("/")[0];
    if (path === "debated") {
      setOpen("debated");
    } else if (path === "voted") {
      setOpen("voted");
    } else if (path === "private") {
      setOpen("private");
    } else if (path === "message") {
      setOpen("message");
    } else if (path === "debated-public") {
      setOpen("public-debated");
    } else if (path === "voted-public") {
      setOpen("public-voted");
    } else {
      setOpen(0);
    }
  }, [pathname]);

  const pilotPlanRestriction = subWorkspaceList?.length >= 5;

  return (
    <div className={`hidden bg-secondary h-full rounded-l-lg md:block w-full`}>
      <div className="h-16 px-4 flex justify-between items-center ">
        {pathname !== "/createprofile" && (
          <Menu
            ripple={false}
            placement="bottom-start"
            open={toggle}
            handler={() => setToggle(!toggle)}
          >
            <MenuHandler>
              <div className="flex w-[80%]">
                <Button
                  ripple={false}
                  variant="text"
                  className="flex p-0 gap-2 justify-start w-full items-center font-normal capitalize tracking-normal hover:bg-transparent active:bg-transparent"
                >
                  <span className="flex-1 xs:line-clamp-1 w-full text-lg text-left text-debatePalette-background">
                    {selectedWorkspace?.WorkSpaceName ||
                      selectedWorkspace?.workSpaceName}
                  </span>
                  <ChevronDownIcon
                    strokeWidth={2.5}
                    className={`h-3.5 w-3.5 transition-transform mt-1 text-debatePalette-background ${
                      toggle ? "rotate-180" : ""
                    }`}
                  />
                </Button>
              </div>
            </MenuHandler>
            <EditPencil
              className="h-6 w-6 text-white cursor-pointer hover:text-debatePalette-buttonBorder"
              onClick={(e) => {
                e.stopPropagation();
                setEditWorkspacePopup(true);
              }}
            />
            <MenuList>
              {subWorkspaceList?.map((subWorkspace, index) => (
                <MenuItem
                  className="flex gap-3 p-2 text-debatePalette-title font-medium"
                  key={index}
                  onClick={() => {
                    setOpen(0);
                    selectSubWorkspace(subWorkspace);
                  }}
                >
                  {subWorkspace?.subWorkSpaceName}
                  {/* {<ChevronRightIcon className="h-4 w-4" />} */}
                </MenuItem>
              ))}
              {loggedInUser?.role === "Admin" && !pilotPlanRestriction ? (
                <MenuItem
                  className="flex gap-3 p-2 text-debatePalette-title font-medium"
                  onClick={() => setPopup(true)}
                >
                  Create Forum {<ChevronRightIcon className="h-4 w-4" />}
                </MenuItem>
              ) : (
                subWorkspaceList?.length === 0 && (
                  <MenuItem
                    disabled={true}
                    className="flex gap-3 p-2 text-debatePalette-title font-medium"
                  >
                    No forum
                  </MenuItem>
                )
              )}
            </MenuList>
          </Menu>
        )}

        {/* <ButtonWithIcon
                  SVG={PencilSquareIcon}
                  BtnHeight={"h-10"}
                  BtnWidth={"w-10"}
                  color={"hover:!text-primary"}
                /> */}
      </div>
      {pathname !== "/createprofile" && (
        <hr className=" bg-gray-500 border-debatePalette-hr border-1 dark:bg-gray-700" />
      )}
      <div className=" h-[calc(100vh-9.1rem)] overflow-y-auto sidebar-scroller">
        {PublicFeedBarOptions.length > 0 ? (
          <AccordionNav
            open={open}
            pathname={pathname}
            Options={PublicFeedBarOptions}
            handleClose={() => {}}
            handleOpen={handleOpen}
            loggedInUser={loggedInUser}
          />
        ) : null}
        <div className="px-4 flex justify-between items-center">
          <div
            key={selectedSubWorkspace?.subWorkSpaceId}
            className={` py-2 rounded-none text-lg truncate text-ellipsis scrollbar-hide font-semibold text-debatePalette-background `}
          >
            {/* <ListItemPrefix> <InboxIcon className="h-5 w-5 text-white" /> </ListItemPrefix> */}
            {selectedSubWorkspace?.subWorkSpaceName}
            {/* <ListItemSuffix> <Chip value="14" size="sm" variant="ghost" color="white" className="rounded-full"/> </ListItemSuffix> */}
          </div>
          <EditPencil
            className="h-6 w-6 text-white cursor-pointer hover:text-debatePalette-buttonBorder"
            onClick={(e) => {
              e.stopPropagation();
              setOpenPopup(true);
            }}
          />
        </div>
        {selectedSubWorkspace && FeedBarOptions.length > 0 ? (
          <div className="mx-4">
            <AccordionNav
              open={open}
              pathname={pathname}
              Options={FeedBarOptions}
              handleClose={() => {}}
              handleOpen={handleOpen}
              loggedInUser={loggedInUser}
            />
          </div>
        ) : null}

        <MessageToggleNav />
      </div>
      <EditSubDebatePopup
        open={openPopup}
        handleClose={() => setOpenPopup(false)}
      />
      <AddSubWorkspacePopup
        handleClose={() => setPopup(false)}
        setPopup={setPopup}
        open={popup}
      />
      <EditSubDebatePopup
        popupFor="workspace"
        open={openEditWorkspace}
        handleClose={() => setEditWorkspacePopup(false)}
      />
    </div>
  );
}
