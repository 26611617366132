import React, { useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import CitationTagListPopup from "../../Components/Popup/CitationTagListPopup";
import DebateCard from "../../Components/UI/Cards/DebateCard";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useGetSearchedDebateList } from "../../Web-Hooks/Search/use-search";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";

const SearchDebates = ({ query = "" }) => {
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const [citationPopup, setCitationPopup] = useState(false);
  const [debate, setDebate] = useState(null);
  const { data: debateList, isLoading: isDebateListLoading } =
    useGetSearchedDebateList(
      loggedInUser?.userId,
      selectedWorkspace?.workSpaceId,
      encodeURIComponent(query)
    );
  return (
    <div className="flex h-full flex-col gap-3">
      <CitationTagListPopup
        debate={debate}
        citationPopup={citationPopup}
        setCitationPopup={setCitationPopup}
        setDebate={setDebate}
      />
      {isDebateListLoading ? RenderLoaderSkeleton(3) : null}
      {!debateList || debateList?.length !== 0 ? (
        debateList?.map((debate, i) => (
          <DebateCard
            debate={debate}
            key={debate?.debateId}
            setCitationPopup={setCitationPopup}
            setDebate={setDebate}
          />
        ))
      ) : (
        <NoData message={"No debates found"} size={"w-full h-[80%]"} />
      )}
    </div>
  );
};

export default SearchDebates;
