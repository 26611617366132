import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { signOut } from "firebase/auth";
import React from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../Services/Google-Signin/config";
import { useAuth } from "../../../Context/AuthContext";
import { useGetUserById } from "../../../Web-Hooks/Profile/use-profile";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { getInitials, getInitialsFromEmail } from "../../../Utils/Helper";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { agentSessionLogout } from "../../../api/agents/agentsApi";

const BurgerMenu = ({
  toggle,
  setToggle1,
  Options,
  openProfile,
  children,
  openDrawerNotification,
}) => {
  const navigate = useNavigate();
  const { logout, user } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();

  const { data: profileDetail } = useGetUserById(
    user?.userId,
    selectedSubWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );
  const handleClick = (route) => {
    if (route === "/") {
      agentSessionLogout({
        user_id: user?.userId,
        session_id: user?.sessionId,
      });
      signOut(auth);
      logout();
      navigate(route);
    } else if (route === "profile") {
      openProfile();
    } else if (route === "rate-us") {
      setToggle1(true);
    } else if (route === "setting") {
      openDrawerNotification();
    } else {
      navigate(`/${route}`);
    }
  };
  return (
    <Menu placement="top-end">
      <MenuHandler>
        <div id="profile-options">{children}</div>
      </MenuHandler>
      <MenuList className="min-w-[200px] p-0 border border-debatePalette-buttonBorder shadow-xl  my-3">
        <div className="flex items-center justify-center gap-4 bg-primary p-2 my-2">
          <ImageOrInitials
            imageSrc={profileDetail?.userImage}
            alt={profileDetail?.userName}
            initials={
              getInitials(profileDetail?.userName) ||
              getInitialsFromEmail(profileDetail?.email)
            }
            size={"h-12 w-12"}
            classNameInitials={" text-lg"}
          />
          <p className="text-debatePalette-background font-bold text-xl capitalize">
            {profileDetail?.userName
              ? profileDetail?.userName
              : profileDetail?.email?.split("@")[0]}
          </p>
        </div>
        {Options.map((option, i) => (
          <div
            key={i}
            className={`hover:bg-primary hover:text-debatePalette-background w-full px-5 ${
              i === Options?.length - 1 ? "mb-3" : "mb-0"
            }`}
          >
            {option.isHRline && <hr className="my-2 border-blue-gray-50" />}
            <MenuItem
              id={option?.item}
              className="flex items-center gap-2 hover:bg-transparent w-full rounded-none"
              onClick={() => handleClick(option?.route)}
            >
              {option.SVG && (
                <img src={option.SVG} alt={option.item} className="h-5 w-5" />
              )}
              <Typography variant="small" className="font-semibold">
                {option.item}
              </Typography>
            </MenuItem>
          </div>
        ))}
      </MenuList>
    </Menu>
  );
};

export default BurgerMenu;

BurgerMenu.ButtonMenu = function BurgerMenuButton({ ripple, label }) {
  return (
    <Button
      id="profile-options"
      ripple={ripple}
      variant="text"
      className="flex px-3 py-1 justify-between w-full items-center bg-transparent gap-3 text-base font-normal capitalize tracking-normal"
    >
      <div className="flex ">{label}</div>
    </Button>
  );
};

BurgerMenu.TextMenu = function TextMenu({ label }) {
  return (
    <div className="flex text-blue-gray-500 gap-2 items-center">{label}</div>
  );
};
