import { Button, Card, CardBody, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateNominationStatus } from "../../../Web-Hooks/Notification/use-notification";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import { getUTCDate } from "../../../Utils/Helper";
import toast from "react-hot-toast";

const NominatedContext = React.createContext({
  notification: undefined,
  isStatusChangePending: false,
});

const NominatedCard = ({ notification, children, isStatusChangePending }) => {
  return (
    <NominatedContext.Provider value={{ notification, isStatusChangePending }}>
      <div className="flex flex-col w-full gap-8 shadow-[rgba(50,_50,_105,_0.15)_0px_2px_5px_0px,_rgba(0,_0,_0,_0.05)_0px_1px_1px_0px] border rounded-md justify-items-center items-center my-4 cursor-pointer">
        <Card className="w-full" shadow={false}>
          <CardBody className="flex flex-col sm:flex-row md:flex-col lg:flex-row gap-4 items-center !p-3">
            <ImageOrInitials
              imageSrc={notification?.userImage}
              initials={notification?.userName}
              size="h-12 w-12"
            />
            <div className="grow text-wrap truncate">
              <Typography
                variant="h6"
                color="black"
                className="text-center sm:text-left !line-clamp-1"
              >
                {notification?.debateText}
              </Typography>
              <Typography
                variant="paragraph"
                className="text-justify sm:text-left "
                color="black"
              >
                {notification?.displayData}
              </Typography>
            </div>
            {children}
          </CardBody>
        </Card>
      </div>
    </NominatedContext.Provider>
  );
};

export default NominatedCard;

NominatedCard.Buttons = function NominationButton({ notification }) {
  const [isAcceptLoading, setAcceptLoading] = useState(false);
  const [isDeclineLoading, setDeclineLoading] = useState(false);
  const { mutateAsync: handleStatusChange } = useUpdateNominationStatus(
    notification?.userId,
    "get"
  );
  const navigate = useNavigate();

  const handleClick = async (notification, type) => {
    if (!notification?.nominationId && !notification?.subWorkSpaceId && !type)
      return;

    const nominationObj = {
      NominationId: notification.nominationId,
      IsAccept: type === "accept",
      CreatedAt: getUTCDate(),
      SubWorkSpaceId: notification.subWorkSpaceId,
    };

    if (type === "accept") {
      setAcceptLoading(true);
    } else {
      setDeclineLoading(true);
    }

    try {
      if (type === "accept") {
        navigate(
          `/debate/${notification?.debateId}/add-argument?userName=Add Argument`,
          {
            state: {
              ...nominationObj
            },
          }
        );
        return;
      }
      const res = await handleStatusChange(nominationObj);
      toast.success(res?.message?.message);
    } catch (error) {
      console.error(error);
    } finally {
      if (type === "accept") {
        setAcceptLoading(false);
      } else {
        setDeclineLoading(false);
      }
    }
  };

  return (
    <div className="flex gap-2">
      <Button
        size="sm"
        className="bg-[#D8FFE9] text-[#009545] shadow-none"
        onClick={() => handleClick(notification, "accept")}
        loading={isAcceptLoading}
        id="accept-button"
      >
        Accept
      </Button>
      <Button
        size="sm"
        className="bg-[#FBE7E8] shadow-none text-[#FF0007]"
        onClick={() => handleClick(notification, "reject")}
        loading={isDeclineLoading}
        id="reject-button"
      >
        Decline
      </Button>
    </div>
  );
};
