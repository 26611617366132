import React from "react";
import DebateHeader from "../../../Components/Functionality/DebateHeader";
import RadioCardBlock from "../../../Components/Functionality/RadioCardBlock";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import DebateFooter from "../../../Components/Functionality/DebateFooter";

const SelectVisibility = ({ handleNext, handleBack}) => {
  const { debateInfo, handleStateUpdate } =
    useCreateDebateContext();
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();

  const content = {
    headLine: "Visibility",
    baseLine: "Office-party",
    page: 2,
    extraDetail: [
      {
        opt: `Public-anyone in ${selectedWorkspace?.workSpaceName}`,
        subOpt: "",
        value: "public",
      },
      {
        opt: `Semi-Public - anyone in ${selectedSubWorkspace?.subWorkSpaceName}`,
        subOpt: "",
        value: "semipublic",
      },
      {
        opt: "Private - Only specific people",
        subOpt: "Can only be viewed or joined by invitation",
        value: "private",
      },
    ],
    pageName: "DebatePreference",
  };
  const handleState = (value) => {
    const IsDebateSemiPublic = value === "semipublic" ? true : false;
    const IsDebatePublic = value === "public" ? true : false;
    const IsDebatePrivate = value === "private" ? true : false;
    handleStateUpdate({
      ...debateInfo,
      IsDebateSemiPublic,
      IsDebatePublic,
      IsDebatePrivate,
    });
  };

  const Visibility = debateInfo?.IsDebatePublic
    ? "public"
    : debateInfo?.IsDebateSemiPublic
    ? "semipublic"
    : debateInfo?.IsDebatePrivate
    ? "private"
    : "";

  const selectedVisibility =
    debateInfo?.IsDebatePrivate ||
    debateInfo?.IsDebatePublic ||
    debateInfo?.IsDebateSemiPublic;
  return (
    <div className="flex flex-col gap-4">
      <DebateHeader content={content} />
      <RadioCardBlock
        content={content}
        status={Visibility}
        setStatus={(value) => handleState(value)}
      />
      <DebateFooter
        isNext={!selectedVisibility}
        handleBack={handleBack}
        handleNext={handleNext}
      />{" "}
    </div>
  );
};

export default SelectVisibility;
