import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React, { useState } from "react";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import {
  useDeleteUserFromSubWorkspace,
  useGetSubWorkspaceUserBySubId,
} from "../../Web-Hooks/Workspace/use-subworkspace";
import {
  useDeleteUserFromWorkspace,
  useGetWorkspaceUserByWorkId,
} from "../../Web-Hooks/Workspace/use-workspace";
import ImageOrInitials from "../Functionality/ImageOrInitials";
import AddEditRole from "../Popup/AddEditRole";
import InfoPopup from "../Popup/InfoPopup";
import BasicButton from "../UI/Buttons/BasicButton";
import SearchField from "../UI/Inputs/SearchField";
import { useWorkspace } from "../../Context/WorkspaceContext";
import toast from "react-hot-toast";
import { useUnblockUser } from "../../Web-Hooks/Profile/use-profile";
import { useAuth } from "../../Context/AuthContext";
import useDebounce from "../../Services/useDebounce";
import { getWorkspaceRole } from "../../Utils/Helper";

const MemberList = ({ popupFor }) => {
  const [search, setSearch] = useState("");
  const deferredSearch = useDebounce(search, 500);
  const [editUserRoleDetail, setEditUserRoleDetail] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const { user: loggedInUser } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { data: SubWorkspaceUser } = useGetSubWorkspaceUserBySubId(
    selectedSubWorkspace?.subWorkSpaceId,deferredSearch
  );
  const { selectedWorkspace } = useWorkspace();
  const [profile, setProfile] = useState({});
  const { data: workspaceUsers } = useGetWorkspaceUserByWorkId(
    selectedWorkspace?.workSpaceId, deferredSearch
  );
  const USERS = popupFor === "subworkspace" ? SubWorkspaceUser : workspaceUsers;
  const {
    mutateAsync: removeUserFromSubWorkspace,
    isPending: isDeleteUserSucceeded,
  } = useDeleteUserFromSubWorkspace();

  const { mutateAsync: unblockUser, isPending: isUnblockingUser } =
    useUnblockUser();

  const {
    mutateAsync: removeUserFromWorkspace,
    isPending: isDeletedUserSucceededWorkspace,
  } = useDeleteUserFromWorkspace();
  const handleDelete = async () => {
    const deleteUser = {
      [popupFor === "subworkspace" ? "SubWorkSpaceId" : "WorkSpaceId"]:
        popupFor === "subworkspace"
          ? selectedSubWorkspace?.subWorkSpaceId
          : selectedWorkspace?.workSpaceId,
      UserId: deleteId,
      LoginUserId: loggedInUser?.userId,
    };
    try {
      await (popupFor === "subworkspace"
        ? removeUserFromSubWorkspace
        : removeUserFromWorkspace)(deleteUser);
      setDeleteId("");
      toast.success("User deleted successfully", "success");
    } catch (err) {
      console.error(err);
    }
  };

  const handleUnblockUser = (member) => {
    const unblockUserBody = {
      BlockedUserId: member?.userId,
      WorkSpaceId: member?.workSpaceId || selectedWorkspace?.workSpaceId,
      BlockedByUserId: loggedInUser?.userId,
    };

    if (Object.values(unblockUserBody).some((value) => !value)) {
      return;
    }
    unblockUser(unblockUserBody)
      .then(() => {
        toast.success("User unblocked successfully", "success");
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // const maxUserLengthForForum = 10;
  // const maxUserLengthForWorkspace = 20;
  // const isAddUserLimitExceeded =
  //   popupFor === "subworkspace"
  //     ? !(USERS?.length + 1 >= maxUserLengthForForum)
  //     : popupFor === "workspace"
  //     ? !(USERS?.length + 1 >= maxUserLengthForWorkspace)
  //     : false;

  return (
    <>
      <div className="h-full flex flex-col gap-2">
        <div className="flex justify-between items-center h-10">
          <div className="w-2/4 sm:w-2/3">
            <SearchField
              id="search-member"
              name="search-member"
              setSearch={setSearch}
              search={search}
              containerClass={"!min-w-full bg-white"}
              placeholder={"Search members"}
              variant={"square"}
              position="left"
            />
          </div>
          {(popupFor === "subworkspace"
            ? loggedInUser?.role === "Admin" || loggedInUser?.role === "Manager"
            : loggedInUser?.role === "Admin") &&
               (
                <BasicButton
                  color={"primary"}
                  className={"px-4 py-2"}
                  onClick={() => {
                    setEditUserRoleDetail("add");
                    setProfile({
                      workSpaceId: selectedSubWorkspace?.workSpaceId,
                      subWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
                      subworkspaceList: [
                        {
                          subworkspaceId: selectedSubWorkspace?.subWorkSpaceId,
                          subworkspaceName:
                            selectedSubWorkspace?.subWorkSpaceName,
                        },
                      ],
                    });
                  }}
                >
                  + Add
                </BasicButton>
              )}
        </div>
        <div className="flex flex-col gap-4 my-3 h-full overflow-y-auto">
          {USERS?.map((member) => {
            const isUserEditDelete =
              popupFor === "subworkspace"
                ? member?.loginUserRole !== "Member" &&
                  member?.roleName !== "Admin"
                : member?.loginUserRole === "Admin";
            const canManagerEdit = !(
              member?.loginUserRole === "Manager" &&
              member?.roleName === "Manager"
            );

            return (
              <div
                className="flex justify-between items-center pe-4"
                key={member?.userId || member?.subWorkSpaceInvitationId || member?.workSpaceInvitationId}
              >
                <div className="flex items-center gap-4">
                  <ImageOrInitials
                    imageSrc={member?.userImage}
                    initials={member?.userName || member?.email}
                  />
                  <div className="flex flex-col ">
                    <p className="text-sm font-semibold text-debatePalette-bodyText text-ellipsis capitalize">
                      {member?.userName || member?.email}
                    </p>
                    {popupFor === "subworkspace" && (
                      <p className="text-xs font-normal text-debatePalette-bodyText text-ellipsis capitalize">
                        {member?.isAccept
                          ? member?.isUserBlock
                            ? "Blocked"
                            : member?.roleName
                          : "Pending"}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  {member?.isUserBlock && (
                    <Button
                      variant="filled"
                      size="sm"
                      className="text-xs capitalize font-semibold px-2 py-1 bg-primary text-debatePalette-bodyText"
                      onClick={() => handleUnblockUser(member)}
                      loading={isUnblockingUser}
                    >
                      Unblock
                    </Button>
                  )}
                  {isUserEditDelete &&
                    !member?.isUserBlock &&
                    member?.isAccept &&
                    canManagerEdit && (
                      <Menu>
                        <MenuHandler>
                          <Button variant="text" className="p-2">
                            <EllipsisHorizontalIcon className="h-6 w-6" />
                          </Button>
                        </MenuHandler>
                        <MenuList className="!z-10000000 min-w-0 p-1 flex gap-1">
                          <MenuItem className="flex items-center gap-2 p-1.5">
                            <PencilIcon
                              className="h-5 w-5 text-debatePalette-title cursor-pointer hover:text-opacity-50"
                              onClick={() => {
                                setEditUserRoleDetail("Edit");
                                setProfile({
                                  userId: member?.userId,
                                  workSpaceId:
                                    selectedSubWorkspace?.workSpaceId,
                                  subWorkSpaceId:
                                    selectedSubWorkspace?.subWorkSpaceId,
                                  userName: member?.userName || member?.email,
                                  email: member?.email,
                                  subworkspaceList: member?.subworkspaceList,
                                  assigned: new Date(),
                                });
                              }}
                            />
                          </MenuItem>
                          <hr className="border border-debatePalette-title h-auto " />
                          <MenuItem className="flex items-center gap-2 p-1.5">
                            <TrashIcon
                              className="h-5 w-5 text-debatePalette-title cursor-pointer hover:text-opacity-50"
                              onClick={() => setDeleteId(member?.userId)}
                            />
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <AddEditRole
        open={!!editUserRoleDetail}
        handleClose={() => setEditUserRoleDetail("")}
        isAddNewMember={editUserRoleDetail === "add" ? true : false}
        profile={profile}
        setProfile={setProfile}
        popupFor={popupFor}
        role={popupFor === "subworkspace" ? selectedSubWorkspace?.loginUserRole : getWorkspaceRole(selectedWorkspace, loggedInUser?.userId)}
      />

      <InfoPopup
        open={deleteId ? true : false}
        handleClose={() => setDeleteId("")}
        onClickBtn1={() => setDeleteId("")}
        onClickBtn2={handleDelete}
        loading2={isDeleteUserSucceeded || isDeletedUserSucceededWorkspace}
        btn1={"cancel"}
        isBtn1Outlined={true}
        btn2={"Delete"}
        title={"Debate base"}
        description={`Are you sure you want to \n remove this user?`}
      />
    </>
  );
};

export default MemberList;
