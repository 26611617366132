import { Chip } from "@material-tailwind/react";
import React from "react";

const EditProfileInput = ({ label, name, value,limit, handleChange, isVerified, isDisable }) => {
  return (
    <div className={`flex flex-col gap-2 w-full ${isVerified ? "relative" : "static"}`}>
      <label
        htmlFor={label}
        className="text-sm text-debatePalette-bodyText font-semibold"
      >
        {label}
      </label>
      <input
        id={label}
        name={name}
        onChange={(e) => handleChange(e.target.name, e.target.value)}
        defaultValue={value}
        type="text"
        placeholder={label}
        className="border-b disabled:bg-transparent border-debatePalette-buttonBorder focus:outline-none focus:border-debatePalette-title"
        maxLength={limit}
        disabled={isDisable}
      />
      <span className="absolute right-3 bottom-2 ">{isVerified ? <Chip value="Verified" className="text-xs normal-case text-publicText" variant="ghost" color="green" /> : ""}</span>
    </div>
  );
};

export default EditProfileInput;
