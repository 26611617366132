import React, { useEffect, useState } from "react";
import Container from "../../Components/UI/Container";
import ReusableTabStructure from "../../Components/UI/Tabs/ReusableTabStructure";
import ActiveList from "./ActiveList";
import DraftList from "./DraftList";
import PendingList from "./PendingList";
import { useSearchParams } from "react-router-dom";

const ActiveDraftsTabs = () => {
  const [query, setQuery] = useSearchParams();
  const defaultSelected = query.get("selected"); 
  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
      setQuery({ selected: defaultSelected });
    }
  }, [defaultSelected, setQuery]);

  const NewsTabData = [
    {
      label: "Active",
      value: "active",
      Component: <ActiveList selected={selected} />,
    },
    {
      label: "Pending",
      value: "pending",
      Component: <PendingList selected={selected} />,
    },
    {
      label: "Draft",
      value: "draft",
      Component: <DraftList selected={selected} />,
    },
  ];



  return (
    <Container>
      <div className="h-full w-full flex flex-col gap-5">
      <ReusableTabStructure
      Options={NewsTabData}
      selected={selected}
      setSelected={(newSelected) => {
        setSelected(newSelected);
        setQuery({ selected: newSelected });
      }}
      size={"w-full md:!w-80"}
      />
    </div>
    </Container>
  );
};

export default ActiveDraftsTabs;
