import { useQuery } from "@tanstack/react-query";
import { validateSpaceName } from "../api/validation";

export const useValidateSpaceName = (type, name, workspaceId) =>
  useQuery({
    queryKey: ["validateSpaceName", name, workspaceId],
    queryFn: () => name && validateSpaceName(type, name, workspaceId),
    enabled: !!name || !!workspaceId,
    retry: false,
  });
