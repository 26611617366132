import { useEffect } from 'react';

export function useDebounceEffect(fn, waitTime, deps) {
  useEffect(() => {
    const t = setTimeout(() => {
      fn();
    }, waitTime);

    return () => {
      clearTimeout(t);
    }; // eslint-disable-next-line
  }, [waitTime,fn, ...(deps || [])]); // Include `waitTime` in the dependency array and handle undefined `deps`
}
