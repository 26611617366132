import React from "react";
import { Navigate } from "react-router-dom";

function ProtectedRoute({ children, ...rest }) {
  const user = localStorage.getItem("user");

  // Redirect to login page only if user is not authenticated
  if (!user) {
    return <Navigate to={"/"} replace />;
  }

  // If user is authenticated, render the children components
  return children;
}

export default ProtectedRoute;
