import { useState, useEffect } from "react";
import { Button, Dialog } from "@material-tailwind/react";
import {
  InputFocusBlue,
  shadowBlue,
} from "../../Utils/Constant";
import Logo from "../../Assets/Images/DB_AI.png";
import DebateTextArea from "../UI/Inputs/DebateTextArea";
import toast from "react-hot-toast";
import Confetti from "../UI/Confetti";

export default function AIMotionValidator({
  open,
  handleClose,
  handleStateUpdate,
  debateInfo,
  AIValidatorType,
  pauseAIButtonBlinking,
  citationSuggester,
  motionAssistance,
  setActiveBlinkingButton,
}) {
  // console.log(motionAssistance,"citationSuggester")
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValues, setInputValues] = useState("");
  const [isVisible, setIsVisible] = useState(false);

  // Effect to set the "Custom Option" selected when inputValues is not empty
  useEffect(() => {
    if (inputValues) {
      setSelectedOption("Custom Option");
    } else if (!inputValues && selectedOption === "Custom Option") {
      setSelectedOption(null); // Clear selection if inputValues is empty and "Custom Option" was selected
    }// eslint-disable-next-line
  }, [inputValues]);


  // Handle changes to the custom input field
  const handleInputChange = (value) => {
    setInputValues(value);
  };

  // Handle radio button changes
  const handleRadioChange = (option) => {
    setSelectedOption(option);
    if (option !== "Custom Option") {
      setInputValues(""); // Clear custom input when a predefined option is selected
    }
  };

  // Handle the "Verify" button click
  const handleVerify = () => {
    if (selectedOption === "Custom Option") {
      handleStateUpdate({
        ...debateInfo,
        MotionOrClaim: inputValues,
      });
      if (motionAssistance?.output?.Debate_motion === false) {
        toast(<p className="font-bold text-red-600">Motion is not valid</p>, {
          position: "top-center",
          icon: "❌",
        });
        setActiveBlinkingButton((prevState) => ({
          ...prevState, // Spread the previous state to keep other fields intact
          Motion: false, // Update only the Category field
        }));
      } else if (motionAssistance?.output?.Debate_motion === true) {
        setIsVisible(true);
        setActiveBlinkingButton((prevState) => ({
          ...prevState, // Spread the previous state to keep other fields intact
          Motion: true, // Update only the Category field
        }));
        toast(
          <p className="font-bold text-green-600">
            Motion verified successfully
          </p>,
          {
            position: "top-center",
            icon: "🎉",
          }
        );
        setTimeout(() => {
          handleClose();
          setIsVisible(false); // Reset isVisible when dialog closes
        }, 5000);
      }
    } else if (selectedOption !== "Custom Option") {
      setIsVisible(true);
      handleStateUpdate({
        ...debateInfo,
        MotionOrClaim: selectedOption,
      });
      setActiveBlinkingButton((prevState) => ({
        ...prevState, // Spread the previous state to keep other fields intact
        Motion: true, // Update only the Category field
      }));
      toast(
        <p className="font-bold text-green-600">
          Motion verified successfully
        </p>,
        {
          position: "top-center",
          icon: "🎉",
        }
      );

      setTimeout(() => {
        handleClose();
        setIsVisible(false); // Reset isVisible when dialog closes
      }, 5000);
    }
  };

  // Transform the data to the required format
  const transformData = (data) => {
    return {
      Corrected_Debate_motion: data?.output?.Corrected_Debate_motion?.map(
        (arg) => ({
          argument: arg,
        })
      ),
    };
  };

  const transformedMotionAssistance = motionAssistance
    ? transformData(motionAssistance)
    : { Corrected_Debate_motion: [] };

  return (
    <div >
      <Dialog
        open={open}
        className="rounded-[50px] border-gray-500 border-4 flex flex-col items-center p-3"
      >
        <div className="flex gap-3 justify-between">
          <div className="flex gap-4 items-center">
          <img
              src={Logo}
              alt="Logo"
              height="60"
              width="60"
              className=" rounded-full  shadow-sm "
            />
            <span className="text-primary font-semibold text-5xl">
              Suggestion
            </span>
          </div>
        </div>

        <div className="w-full mt-3 px-3">
          <div className="flex flex-col gap-4 min-w-full text-black">
            {motionAssistance?.output?.Debate_motion ||   isVisible  ? (
              <p>You're Motion verified successfully</p>
            ) : (
              <p>
                Friendly reminder: For a great debate, your motion should be a
                solid statement. This helps everyone know exactly which side
                they’re on!
              </p>
            )}
            <div className="flex flex-col w-full gap-3">
              {motionAssistance?.output?.Debate_motion === false &&
                transformedMotionAssistance.Corrected_Debate_motion.length >
                  0 &&
                transformedMotionAssistance?.Corrected_Debate_motion?.map(
                  (item) => (
                    <div
                      key={item.argument}
                      className={`flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border-2 ${
                        selectedOption === item.argument ? shadowBlue : "border-gray-400 !shadow-black"
                      } rounded-md shadow-sm`}
                      onClick={() => handleRadioChange(item.argument)}
                    >
                      <span className="mt-2">{item.argument}</span>
                    </div>
                  )
                )}
              {/* Manually added option with textarea */}
              {!isVisible &&<div
                className={`flex flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border ${
                  selectedOption === "Custom Option"
                    ? shadowBlue
                    : InputFocusBlue
                } rounded-md shadow-sm`}
                onClick={() => handleRadioChange("Custom Option")}
              >
                <DebateTextArea
                  id={"motion"}
                  maxLength={150}
                  value={inputValues}
                  handleChange={(e) => handleInputChange(e.target.value)}
                  placeholder="Write your motion here"
                  className="ml-2 border border-gray-300 rounded w-full my-0"
                  rows="4"
                />
              </div>}
            </div>
          </div>
          <div className="flex flex-col gap-4 items-center my-4">
            <div className="flex gap-4">
              {isVisible && <Confetti isVisible={isVisible} />}
              <Button
                id="verify-button"
                className="bg-primary text-black rounded-full border-black border w-40"
                onClick={handleVerify}
                disabled={
                  !selectedOption &&
                  motionAssistance?.output?.Debate_motion === false
                }
              >
                {  isVisible ? "Verified" : "Verify"}
              </Button>
              <Button
                id="keep-button"
                variant="outlined"
                className="rounded-full w-40"
                onClick={handleClose}
              >
                Keep Original
              </Button>
            </div>
            {AIValidatorType !== "MotionSuggestion" && (
              <Button
                id="cancel-button"
                variant="text"
                className="rounded-full text-debatePalette-link p-0 m-0 hover:bg-white active:bg-white focus:bg-white mt-4"
                onClick={handleClose}
              >
                Cancel
              </Button>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
}
