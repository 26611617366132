import React from "react";

const CustomSlider = ({  handleStateUpdate, debateInfo }) => {

  const getColor = () => {
    if (debateInfo?.ScalerPoint > 0) return "bg-green-500";
    if (debateInfo?.ScalerPoint === 0) return "bg-yellow-500";
    return "bg-red-500";
  };

  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    handleStateUpdate({
      ...debateInfo,
      SideForDebate: newValue === 0 ? "" : newValue > 0 ? "Agree" : "Disagree",
      ScalerPoint: newValue,
    });
  };
  return (
    <div className="flex flex-col items-center w-full max-w-xl mx-auto mt-10">
      <div className="relative w-full">
        {/* Slider */}
        <input
          type="range"
          min="-10"
          max="10"
          value={debateInfo?.ScalerPoint}
          onChange={handleSliderChange}
          className="w-full h-2 bg-gradient-to-r from-red-500 via-yellow-500 to-green-500 rounded-lg appearance-none cursor-pointer thumb-custom"
        />
        <div
          className="absolute bottom-5"
          style={{
            left: `${((debateInfo?.ScalerPoint + 10) / 20) * 100}%`,
            transform: "translateX(-50%)",
          }}
        >
          <div
            className={`${getColor()} text-white px-2 py-1 rounded-md shadow-lg`}
          >
            {Math.abs(debateInfo?.ScalerPoint)}
          </div>
        </div>
      </div>

      {/* Labels */}
      <div className="flex justify-between w-full text-sm mt-4">
        <span className="text-red-500">10 Disagree</span>
        <span className="text-green-500">10 Agree</span>
      </div>
    </div>
  );
};

export default CustomSlider;
