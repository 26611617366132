import { Switch, Typography } from "@material-tailwind/react";
import React from "react";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import { useAuth } from "../../Context/AuthContext";

const ForumWiseNotification = ({ type, settingInfo }) => {
  const { user } = useAuth();

  const handleChangeNotification = (type, value, workId, subId) => {
    const notifyWorkspaceObj = {
      loggedInUserId: user?.userId,
      workspaceId: workId,
      forumId: subId ? subId : null,
      notificationStatus: value,
      Type: type,
    };

    console.log(notifyWorkspaceObj);
  };
  return (
    <>
      <Typography
        variant="h6"
        className="text-debatePalette-title font-semibold py-3"
      >
        {type === "Draft" ? "Debatebase Draft" : "Debatebase Like and Vote"}
      </Typography>
      <hr className="border-gray-300" />
      <form className="flex flex-col gap-4 overflow-y-auto py-3">
        {settingInfo?.map((workspace) => (
          <div className="flex flex-col gap-4">
            <div className="flex w-full items-center justify-between">
              <Tooltip
                position="bottom-start"
                content={workspace?.workspaceName}
                containerClass="w-full"
              >
                <label
                  htmlFor={workspace?.workspaceId}
                  color="blue-gray"
                  className="font-medium line-clamp-1 overflow-x-hidden"
                >
                  {workspace?.workspaceName}
                </label>
              </Tooltip>
              <Switch
                id={workspace?.workSpaceId}
                name={workspace?.workspaceId}
                color="blue"
                checked={workspace?.allNotificationOn}
                onChange={() =>
                  handleChangeNotification(
                    "Invitation",
                    !settingInfo.PauseAllNotification,
                    workspace?.workspaceId
                  )
                }
                containerProps={{
                  className: "flex-shrink-0 p-0 w-16",
                }}
              />
            </div>
            {workspace?.workspaceOptions?.map((item) => (
              <div className="flex w-full items-center justify-between pl-5">
                <Tooltip
                  position="top-start"
                  content={item?.forumName}
                  containerClass="w-full"
                >
                  <label
                    htmlFor={item?.forumId}
                    color="blue-gray"
                    className="font-medium line-clamp-1 overflow-x-hidden"
                  >
                    {item?.forumName}
                  </label>
                </Tooltip>
                <Switch
                  id={item?.forumId}
                  name={item?.forumId}
                  color="blue"
                  checked={
                    workspace?.allNotificationOn
                      ? true
                      : item?.notificationStatus
                  }
                  onChange={() =>
                    handleChangeNotification(
                      "Invitation",
                      !settingInfo.PauseAllNotification,
                      workspace?.workspaceId,
                      item?.forumId
                    )
                  }
                  containerProps={{
                    className: "flex-shrink-0 p-0 w-16",
                  }}
                />
              </div>
            ))}
          </div>
        ))}
      </form>
    </>
  );
};

export default ForumWiseNotification;
