import React, { useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { convertDateToUTC } from "../../Utils/Helper";
import {
  useAddUserCategory,
  useGetCategoriesByWorkspaceId,
} from "../../Web-Hooks/Categories/use-categories";
import AddCategoriesPopup from "../Popup/AddCategoriesPopup";
import IconRoundedButton from "../UI/Buttons/ImageRoundedButton";

const CategoriesBlock = ({ selectedTags, setSelectedTags, className, disable }) => {
  const [title, setTitle] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const workspaceStorageValue = window.localStorage.getItem(
    "selectedSubWorkspace"
  );
  const subWorkSpaceId =
    workspaceStorageValue && workspaceStorageValue !== "undefined"
      ? JSON.parse(localStorage.getItem("selectedSubWorkspace"))?.subWorkSpaceId
      : null;
  const { user } = useAuth();
// console.log(selectedTags,"selectedTags")
  const { mutateAsync: addCategory } = useAddUserCategory();
  const { selectedWorkspace } = useWorkspace();
  const { data: categoryList, isLoading } = useGetCategoriesByWorkspaceId(
    selectedWorkspace?.workSpaceId
  );

  // const handleClick = (title) => {
  //   const {categoryName} = title
  //   selectedTags && selectedTags?.includes(categoryName)
  //     ? setSelectedTags(selectedTags.filter((tag) => tag !== categoryName))
  //     : setSelectedTags([...selectedTags, categoryName]);
  // };
  const handleClick = (title) => {
    // Ensure selectedTags is an array
    const currentSelectedTags = Array.isArray(selectedTags) ? selectedTags : [];
  
    const { categoryName } = title;
  
    if (currentSelectedTags.includes(categoryName)) {
      // Remove categoryName from selectedTags
      setSelectedTags(currentSelectedTags.filter((tag) => tag !== categoryName));
    } else {
      // Add categoryName to selectedTags
      setSelectedTags([...currentSelectedTags, categoryName]);
    }
  };
  const handleClose = () => {
    setOpen(!open);
  };

  const handleAddCategory = async () => {
    const categoryData = {
      userId: user?.userId,
      UCCategoryName: title,
      UcThumbNail: "NA",
      SubWorkSpaceId: subWorkSpaceId,
      WorkSpaceId: selectedWorkspace?.workSpaceId,
      CreatedAt: convertDateToUTC(new Date()),
      UpdatedAt: convertDateToUTC(new Date()),
    };

    const formData = new FormData();
    for (const [key, value] of Object.entries(categoryData)) {
      formData.append(key, value);
    }

    await addCategory(formData);
    handleClose();
  };
  return (
    <>
      <div
        className={` grid mt-5 place-items-stretch grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-4 w-full ${className}`}
      >
        {!isLoading &&
          categoryList?.map(
            (category, i) => (
              <span
                id={category?.categoryId}
                key={category?.categoryId}
                className="flex flex-col gap-2 justify-center items-center self-center"
              >
                {/* SVG */}
                <IconRoundedButton
                  className={`bg-primary-base text-black ${
                    selectedTags && selectedTags?.includes(category.categoryName) &&
                    "bg-secondary"
                  }`}
                  SVG={category?.thumbnail}
                  BtnWidth="w-14"
                  BtnHeight="h-14 "
                  toolcontent={category?.categoryName}
                  istooltip={`${true}`}
                  onClick={() => !disable && handleClick(category)}
                />
                <p className="flex justify-center items-center text-center font-semibold">
                  {category?.categoryName}
                </p>
              </span>
            )
          )}
      </div>
      <AddCategoriesPopup
        handleClose={handleClose}
        open={open}
        title={title}
        setTitle={setTitle}
        handleSubmit={() => handleAddCategory()}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
      />
    </>
  );
};

export default CategoriesBlock;
