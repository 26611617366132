// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkArgumentAgent } from "../../api/agents/agentsApi";
import { useAuth } from "../../Context/AuthContext";

export const useArgumentAgent = (motion, category) => {
  const { user: loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["argumenty-validate", motion, category],
    queryFn: async () => {
      return await checkArgumentAgent({
        motion,
        category,
        user_id: loggedInUser?.userId,
        sessionid: loggedInUser?.sessionId,
      });
    },
    select: (data) => data.data,
    enabled: !!motion && !!category && !!loggedInUser,
  });
};
