import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createGroupChat,
  getGroupHistory,
  getGroupList,
  leaveGroup,
  removeMember,
  updateGroupChat,
} from "../../api/chat/groupChat";
import toast from "react-hot-toast";

export const useGroupChatList = (
  userId,
  workId,
  subId = null,
  enabled,
  selectData = (data) => data.data.data
) => {
  return useQuery({
    queryKey: ["group-chat-list", userId, workId, subId],
    queryFn: async () => {
      return await getGroupList(userId, workId, subId);
    },
    select: selectData,
    enabled: !!userId && !!workId && enabled,
    // refetchOnWindowFocus: true,
  });
};

export const useCreateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      return await createGroupChat(group);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries([{ queryKey: ["group-chat-list"] }]);
      toast.success("Group created successfully");
    },
    onError: () => {
      toast.error("Failed to create group");
    },
  });
};
export const useUpdateGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      const { id: groupId, name, description } = group;
      return await updateGroupChat(groupId, {
        name,
        description
      });
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries([{ queryKey: ["group-chat-list"] }]);
      toast.success("Group details updated successfully");
    },
    onError: () => {
      toast.error("Failed to update group");
    },
  });
};


export const useLeaveGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      const { id: groupId, userId } = group;
      return await leaveGroup(groupId, userId);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries([{ queryKey: ["group-chat-list"] }]);
      toast.success("you left the group");
    },
    onError: () => {
      toast.error("Failed to leave group");
    },
  });
};
export const useRemoveMemberGroup = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (group) => {
      const { id: groupId, userId, removerId } = group;
      return await removeMember(groupId,removerId, userId);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries([{ queryKey: ["group-chat-list"] }]);
      toast.success(`you removed member from the group`);
    },
    onError: () => {
      toast.error("Failed to remove user group");
    },
  });
};

export const useGetGroupChatHistory = (groupId, page, limit) => {
  return useQuery({
    queryKey: ["group-chat-history", groupId, page, limit],
    queryFn: async () => {
      return await getGroupHistory(groupId, page, limit);
    },
    select: (data) => data.data.data || [],
    enabled: !!groupId,
  });
};
