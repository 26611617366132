import { Card } from "@material-tailwind/react";
import React from "react";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import { Link } from "react-router-dom";
import { extractTitle, getLocalTime } from "../../../Utils/Helper";

const CitationCard = ({ citation }) => {
  const formatCitation = (citation) => {
    let title = "";
    let description = "";

    if (citation.citationType === "url") {
      // Assuming title and description are separated by 'Title:' and 'Description:' in citationNote
      const noteParts = citation?.citationNote?.split("\n");
      title = noteParts[0]?.replace("Title: ", "")?.trim();
      description = noteParts[1]?.replace("Description: ", "")?.trim();
    } else {
      // Extract the file name from the URL as the title
      title = extractTitle(citation?.citationUrl);
      description = citation?.citationNote;
    }

    return { title, description };
  };

  const { title, description } = formatCitation({
    ...citation,
    citationType: citation?.citationFileType,
  });
  return (
    <Card className="flex p-2 flex-col md:flex-row  gap-2 items-start ">
      <ImageOrInitials
        imageSrc={citation?.createdByUserImage}
        initials={citation?.createdByUserName}
      />
      <div className="w-full flex flex-col gap-1">
        <div className="flex flex-col gap-2 w-full">
          <p className="truncate whitespace-pre-wrap text-sm">
            <span className="font-semibold ">Title:</span> {title}
          </p>
          <p className="truncate whitespace-pre-wrap text-sm">
            <span className="font-semibold">Description:</span> {description}
          </p>
        </div>
        <Link
          to={citation?.citationUrl}
          target="_blank"
          className="text-sm text-wrap break-all text-debatePalette-link hover:underline"
        >
          {citation?.citationUrl?.split("/")?.pop()
            ? citation?.citationUrl?.split("/")?.pop()
            : citation?.citationUrl}
        </Link>
        <span className="text-xs text-debatePalette-bodyText">
          {getLocalTime(citation?.createdAt)}
        </span>
      </div>
    </Card>
  );
};

export default CitationCard;
