import React from "react";

const BasicInputField = ({
  name,
  handleChange,
  type,
  value,
  emailError,
  placeholder,
  fieldSize,
  errorMessage,
  className,
  readOnly,
  ...props
}) => {
  return (
    <div>
      <input
      readOnly={readOnly}
      size={fieldSize}
        name={name}
        value={value}
        onChange={handleChange}
        type={type}
        className={`bg-transparent border border-gray-300 autofill:!shadow-[inset_0_0_0px_1000px_rgb(255,255,255)] text-gray-900 text-sm rounded-lg block w-full p-2.5 ${
          emailError
            ? "!border-red-400 focus:!border-red-500 active:!border-red-500"
            : ""
        } ${className}`}
        placeholder={placeholder}
        required
        {...props}
      />
      {emailError && (
        <p className="mt-2 text-sm text-red-600">
          <span className="font-medium">{errorMessage}</span>
        </p>
      )}
    </div>
  );
};

export default BasicInputField;
