import React from "react";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import { Button, Typography } from "@material-tailwind/react";
import { useRemoveMemberGroup } from "../../../Web-Hooks/Chat/use-group-chat";
import { useAuth } from "../../../Context/AuthContext";

const Member = ({ member, groupDetails }) => {
  const { mutateAsync: removeMember, isPending: isLoading } =
    useRemoveMemberGroup();
  const { user: loggedInUser } = useAuth();
  const handleRemoveMember = async () => {
    try {
      const member = {
        id: groupDetails?.id,
        userId: member?.id,
        removerId: loggedInUser?.userId,
        removerName: loggedInUser?.userName,
      };
      await removeMember(member);
    } catch (error) {
      console.log("Error occurred while removing member", error);
    }
  };

  return (
    <div className="flex items-center w-full justify-between gap-2">
      <div className="flex gap-3 items-center">
        <ImageOrInitials
          imageSrc={member?.avatar}
          initials={member?.username}
          size="h-10 w-10"
        />
        <Typography className=" capitalize text-debatePalette-title">
          {member?.username}
        </Typography>
      </div>
      <div className="flex gap-3 items-center justify-center">
        <Button
          variant="text"
          color="red"
          size="xs"
          className="text-xs"
          onClick={handleRemoveMember}
        >
          Remove
        </Button>
      </div>
    </div>
  );
};
const GroupMemberPopup = ({ groupDetails, setGroupDetails, groupMembers }) => {
  return (
    <div className="flex flex-col items-start gap-3 justify-center">
      {groupMembers?.map((member) => (
        <Member key={member?.id} member={member} groupDetails={groupDetails} />
      ))}
    </div>
  );
};

export default GroupMemberPopup;
