import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React from "react";

const DebateSummaryPopup = ({ isOpen, closeDialog, debateSummary }) => {
  return (
    <Dialog open={isOpen} handler={closeDialog} size="lg">
      <DialogHeader>Debate Summary</DialogHeader>
      <DialogBody>
        <div className="p-4 text-left">
          <h3 className="text-lg text-debatePalette-title font-bold mb-2">
            Category: {debateSummary?.Category?.split(";").join(", ")}
          </h3>

          <h4 className="text-lg text-debatePalette-title font-bold">
            Participants:
          </h4>
          <p className="text-secondary">
            <strong>Favored:</strong> {debateSummary?.Participants?.Favored}
          </p>
          <p className="text-secondary">
            <strong>Opposed:</strong> {debateSummary?.Participants?.Opposed}
          </p>

          <h4 className="text-lg text-debatePalette-title font-bold mt-4">
            Introduction:
          </h4>
          <p className="text-secondary">{debateSummary?.Introduction}</p>

          <h4 className="text-lg text-debatePalette-title font-bold mt-4">
            Favored Key Arguments:
          </h4>
          <p className="text-secondary">
            {debateSummary?.Favored_Key_Arguments}
          </p>

          <h4 className="text-lg text-debatePalette-title font-bold mt-4">
            Opposed Key Arguments:
          </h4>
          <p className="text-secondary">
            {debateSummary?.Opposed_Key_Arguments}
          </p>

          <h4 className="text-lg text-debatePalette-title font-bold mt-4">
            Conclusion Statements:
          </h4>
          <p className="text-secondary">
            {debateSummary?.Conclusion_Statements}
          </p>
        </div>
      </DialogBody>
      <DialogFooter>
        <Button color="red" onClick={closeDialog}>
          Close
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default DebateSummaryPopup;
