import React, { useEffect, useState } from "react";
import Container from "../../Components/UI/Container";
import ReusableTabStructure from "../../Components/UI/Tabs/ReusableTabStructure";
import SearchUsers from "./SearchUsers";
import SearchDebates from "./SearchDebates";
import { useSearchParams } from "react-router-dom";

const SearchTabs = () => {
  const [selected, setSelected] = useState("debates");
  const search = useSearchParams();
  const query = decodeURIComponent(search[0]?.get("query"));

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [selected]);
  const NewsTabData = [
    {
      label: "Debates",
      value: "debates",
      Component: <SearchDebates query={query} />,
    },
    {
      label: "Users",
      value: "users",
      Component: <SearchUsers query={query} />,
    },
  ];
  return (
    <Container>
      <ReusableTabStructure
        Options={NewsTabData}
        selected={selected}
        setSelected={setSelected}
        size={"w-full md:!w-80"}
      />
    </Container>
  );
};

export default SearchTabs;
