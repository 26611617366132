import React, { useRef } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Container = ({ children }) => {
  const containerRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    const container = document.getElementById("container");
    container?.scrollIntoView({
      inline: "start",
      block: "start",
      behavior: "instant",
    });
  }, [pathname]);
  return (
    <>
      <section
        id="container"
        className="flex w-full flex-col h-full px-3 md:px-8 py-4 justify-start items-start overflow-y-auto"
        ref={containerRef}
      >
        {children}
      </section>
    </>
  );
};

export default Container;
