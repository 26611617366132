import {
  Card,
  CardHeader,
  CardBody,
  Typography,
  Avatar,
} from "@material-tailwind/react";
import moment from "moment";
import BasicButton from "../Buttons/BasicButton";

export function NewsCard({ news, isBuyNow = false, handleClick }) {
  return (
    <Card
      shadow={false}
      className="w-full shadow-sm border flex-col sm:flex-row items-center sm:items-stretch p-4"
      onClick={handleClick}
    >
      <CardHeader
        shadow={false}
        floated={false}
        className="m-0 max-w-[100%] sm:max-w-[8rem] shrink-0"
      >
        <Avatar
          variant="rounded"
          src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
          alt="cardimage"
          className="h-full w-full object-cover"
        />
      </CardHeader>
      <CardBody className="py-0 px-1 w-full sm:px-2 m-0 text-wrap truncate">
        {/* <Typography variant="h6" color="gray" className=" uppercase">
            {news?.authors[0].name}
          </Typography> */}
        <Typography
          variant="h5"
          color="blue-gray"
          className="text-center sm:text-start"
        >
          {news?.title}
        </Typography>
        <Typography
          color="gray"
          className="font-normal text-justify !line-clamp-4 sm:!line-clamp-2"
        >
          {news?.body[0]}
        </Typography>
        <div className=" flex mt-2 flex-col lg:flex-row items-center lg:justify-between">
          {isBuyNow ? (
            <BasicButton color={"primary"}>
              Buy Now
            </BasicButton>
          ) : (
            <a
              href={news?.link}
              className="text-blue-500 text-justify !line-clamp-1"
            >
              {news?.link}
            </a>
          )}
        </div>
        <Typography
          color="black"
          className="font-normal text-sm p-0 mt-1 tracking-widest text-end w-[100%]"
        >
          {moment(news.createdAt).format("DD-MMM-YYYY")}
        </Typography>
      </CardBody>
    </Card>
  );
}
