import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Button, Dialog, Spinner, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Logo from "../../../Assets/Images/DB_AI.png";
import Confetti from "../../../Components/UI/Confetti";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import useDebounce from "../../../Services/useDebounce";
import { categoryValidatorUiError } from "../../../Utils/Constant";
import {
    convertArrayToString,
    convertStringToArray,
} from "../../../Utils/Helper";
import { useCategoryAgent } from "../../../Web-Hooks/Agents/use-category-agent";
import AddCategories from "../AddCategories";

const AddCategoryWithAI = () => {
  const { debateInfo, handleStateUpdate } = useCreateDebateContext();
  const motion = useDebounce(debateInfo.MotionOrClaim, 1000);
  const [showAIValidator, setShowAIValidator] = useState(false);
  const [activeBlinkingButton, setActiveBlinkingButton] = useState();
  const { data: categoryAssistance, isLoading: isCategoryAssistanceLoading } =
    useCategoryAgent(
      motion,
      debateInfo?.Fields?.length > 0
        ? convertArrayToString(debateInfo.Fields)
        : null,
      ""
    );
  const [query] = useSearchParams();
  const from = query?.get("from");

  return (
    <div className="flex flex-row gap-2 w-full">
      <Dialog
        open={showAIValidator}
        className="rounded-[50px] border-gray-500 border-4 flex flex-col gap-3 items-center p-3"
        // size="xs"
      >
        <div className="flex gap-3 justify-between">
          <div className="flex gap-4 items-center">
            <img
              src={Logo}
              alt="Logo"
              height="60"
              width="60"
              className=" rounded-full  shadow-sm "
            />
            <span className="text-primary font-semibold text-5xl">
              Suggestion
            </span>
          </div>
        </div>
        <div className="w-full mt-3 px-3 flex flex-col gap-4">
          <div className="flex flex-col gap-4 min-w-full text-black text-center">
            <>
              {categoryAssistance ? (
                <div className="flex flex-col w-full gap-4 justify-center">
                  <p className="text-center">
                    The motion{" "}
                    <span className="font-bold">
                      {debateInfo?.MotionOrClaim}
                    </span>{" "}
                    more suited to{" "}
                    <span className="font-bold">
                      {!categoryAssistance?.Matched
                        ? categoryAssistance["Appropriate Category"]
                        : convertArrayToString(debateInfo?.Fields)}
                    </span>{" "}
                    categories
                  </p>
                  <Typography className="text-center">
                    {categoryValidatorUiError}
                  </Typography>
                </div>
              ) : (
                <div className=" flex items-center justify-center">
                  {" "}
                  <Spinner className="h-10 w-10 text-gray-900/50" />
                </div>
              )}
            </>{" "}
          </div>
          <div className="flex flex-col gap-4 items-center my-4">
            <div className="flex gap-4">
              <Confetti isVisible={false} />
              <Button
                id="categoryValidatorAccept"
                className="bg-primary text-black rounded-full border-black border w-48"
                onClick={() => {
                  setShowAIValidator(false);
                  handleStateUpdate({
                    ...debateInfo,
                    Fields: categoryAssistance?.Matched
                      ? convertStringToArray(categoryAssistance?.Category) || []
                      : convertStringToArray(
                          categoryAssistance?.["Appropriate Category"]
                        ) || [],
                  });
                }}
                disabled={isCategoryAssistanceLoading}
              >
                {/* {AIValidatorType !== "Motion"
                  ? "Use Suggestions"
                  : "Show Suggestions"} */}
                Use Suggestion
              </Button>
              <Button
                id="categoryValidatorDecline"
                variant="outlined"
                className="rounded-full w-48"
                onClick={() => setShowAIValidator(false)}
              >
                Keep Original
              </Button>
            </div>
          </div>
        </div>
      </Dialog>

      <div className="flex flex-col -gap-5 w-full">
        {debateInfo?.MotionOrClaim && debateInfo?.Fields?.length > 0 ? (
          <button
            id="categoryValidatorButton"
            onClick={() => {
              setShowAIValidator(true);
              // mutateCategory({
              //   motion: debateInfo.MotionOrClaim,
              //   category: debateInfo.Fields[0],
              //   image: "",
              // });
            }}
            className={` flex justify-end -pl-8 ${
              activeBlinkingButton ? "animate-blinker" : ""
            }`}
          >
            <img
              src={Logo}
              alt="Logo"
              height="30"
              width="30"
              className="bg-gray-300 rounded-full object-contain  shadow-2xl shadow-black"
            />
          </button>
        ) : (
          <p> </p>
        )}

        <div
          className="mt-2"
          onMouseEnter={() => setActiveBlinkingButton(true)}
          onMouseLeave={() => setActiveBlinkingButton(false)}
        >
          <AddCategories
            debateInfo={debateInfo}
            handleStateUpdate={handleStateUpdate}
            disable={from === "Redebate" ? true : false}
          />
        </div>
      </div>
      <div className="pt-8">
        {categoryAssistance?.Matched && (
          <CheckCircleIcon color="green" className=" h-7 w-7 " />
        )}
      </div>
    </div>
  );
};

export default AddCategoryWithAI;
