import { useMutation, useQueryClient } from "@tanstack/react-query";
import { updateMotionLikeDislike } from "../../api/motion/motion";

export const useUpdateLikeDislike = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (Motion) => {
        return await updateMotionLikeDislike(Motion);
      },
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["motionList"],
        })
      ]),
      onError: (error) => {
        console.log(error);
      },
    });
  };