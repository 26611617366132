import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import InfoPopup from "../../../Components/Popup/InfoPopup";
import EditableInput from "../../../Components/UI/Inputs/EditableInput";
import EditableTextArea from "../../../Components/UI/Inputs/EditableTextArea";
import { useAuth } from "../../../Context/AuthContext";
import { useSocket } from "../../../Context/SocketContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { getUserImageUrl } from "../../../Utils/Helper";
import {
  useLeaveGroup,
  useUpdateGroup,
} from "../../../Web-Hooks/Chat/use-group-chat";


const GroupDetailsPopup = ({ groupDetails, setGroupDetails, groupMembers }) => {
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { user: loggedInUser } = useAuth();
  const { sendGroupMessage } = useSocket();
  const navigate = useNavigate();

  const [group, setGroup] = useState(groupDetails);
  const [leavePopup, setLeavePopup] = useState(false);

  const { mutateAsync: updateGroup, isPending: isUpdating } = useUpdateGroup();
  const { mutateAsync: leaveGroup, isPending: isLeaving } = useLeaveGroup();

  const handleLeaveGroup = async () => {
    try {
      await leaveGroup({ id: group.id, userId: loggedInUser?.userId });
      const Message = {
        type: "user_left",
        content: {
          userId: loggedInUser?.userId,
          userName: loggedInUser?.userName,
        },
      };
      sendGroupMessage(
        groupDetails?.id,
        loggedInUser?.userId,
        Message,
        selectedWorkspace?.workSpaceId,
        selectedSubWorkspace?.subWorkSpaceId
      );
      navigate("/feed-public");
      setLeavePopup(false);
    } catch (error) {
      toast.error("Failed to leave group");
    }
  };

  const handleUpdateGroup = async () => {
    try {
      await updateGroup(group);
      setGroupDetails(group);
      const Message = {
        type: "group_updated",
        content: {
          updatedByUserId: loggedInUser?.userId,
          updatedByUserName: loggedInUser?.userName,
        },
      };
      sendGroupMessage(
        groupDetails?.id,
        loggedInUser?.userId,
        Message,
        selectedWorkspace?.workSpaceId,
        selectedSubWorkspace?.subWorkSpaceId
      );
    } catch (error) {
      console.error(error);
      toast.error("Error updating group details");
    }
  };

  return (
    <div className="flex flex-col w-full gap-5">
      <InfoPopup
        open={leavePopup}
        title="Group Leave"
        description="Are you sure you want to leave this group?"
        btn1={"Leave"}
        btn2={"Cancel"}
        onClickBtn1={handleLeaveGroup}
        onClickBtn2={() => setLeavePopup(false)}
        loading1={isLeaving}
        handleClose={() => setLeavePopup(false)}
      />
      <div className="flex flex-col items-start gap-2 flex-1">
        <label className="text-medium text-debatePalette-title font-semibold">
          Group Name:
        </label>
        <EditableInput
          value={group?.name}
          onChange={(value) => setGroup({ ...group, name: value })}
        />
      </div>
      <div className="flex flex-col items-start gap-2 flex-1">
        <label className="text-medium text-debatePalette-title font-semibold">
          Group Description:
        </label>
        <EditableTextArea
          value={group?.description}
          onChange={(value) => setGroup({ ...group, description: value })}
        />
      </div>
      <div className="flex flex-col gap-2 items-start">
        <label className="text-medium text-debatePalette-title font-semibold">
          Group Member:
        </label>
        <div className="flex gap-3 items-center">
        <div className="flex items-center -space-x-4">
          {groupMembers?.map((member) => (
            <ImageOrInitials
              variant="circular"
              size="h-10 w-10"
              alt={member?.username}
              imageSrc={
                member?.avatar
                  ? getUserImageUrl(member?.id, member?.avatar)
                  : ""
              }
              initials={member?.username}
            />
          ))}

        </div>
        <span className="text-debatePalette-title">
            {group?.users?.length} members
          </span>
        </div>
      </div>

      <div className="flex justify-center gap-3 mt-3">
        <Button
          variant="filled"
          className="bg-primary text-base px-6 py-1.5 normal-case font-normal cursor-pointer text-debatePalette-title"
          onClick={() => handleUpdateGroup()}
          loading={isUpdating}
        >
          Save
        </Button>
        <Button
          variant="outlined"
          color="red"
          className="text-base px-6 py-1.5 normal-case font-normal cursor-pointer"
          onClick={() => setLeavePopup(true)}
          loading={isLeaving}
        >
          Leave
        </Button>
      </div>
    </div>
  );
};

export default GroupDetailsPopup;
