export const CHAT_HISTORY = "/chat/messages";
export const CHAT_UNREAD_COUNT = "/chat/total-unread-count";
export const GET_RECENT_USERS = "/user/get-all-users";
export const GET_USER_DATA = "/user/get-user-by-id";
export const GET_WORKSPACE_USER = "/user/get-workspace-user";
export const DELETE_MESSAGE = "/chat/delete-message";
export const CLEAR_CHAT = "/chat/clear-chat";
export const GET_USERS = "/user/get-group-users";

// Group Chats Routes
export const CREATE_GROUP_CHAT = "/group/create-group";
export const UPDATE_GROUP = "/group/update-group";
export const ADD_GROUP_CHAT_USER = "/group/add-member";
export const GET_USER_GROUP_LIST = "/group/get-groups"
export const GET_GROUP_CHAT = "/group/get-group-messages-by-group-id"
export const LEAVE_GROUP = "/group/leave-group"
export const REMOVE_GROUP = "/group/remove-group"

