import React, { useEffect } from "react";
import DebateHeader from "../../../Components/Functionality/DebateHeader";
import RadioCardBlock from "../../../Components/Functionality/RadioCardBlock";
// import { useOutletContext } from "react-router-dom";
// import { useDebate } from "./CreateDebate";
import { useLocation, useSearchParams } from "react-router-dom";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import { useGetDebateTypeDetails } from "../../../Web-Hooks/Debate/use-debate";
import DebateFooter from "../../../Components/Functionality/DebateFooter";

const SelectType = ({ handleNext, handleBack }) => {
  const { debateInfo, handleStateUpdate } = useCreateDebateContext();
  const location = useLocation();
  const { state } = location;
  const [query] = useSearchParams();
  const selectedDebate = state?.selectedDebate;
  const from = query?.get("from");
  const { data: DebateTypeDetails } = useGetDebateTypeDetails();
  useEffect(() => {
    if (from && selectedDebate) {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
        Fields: selectedDebate?.Fields?.split(";").map((field) => field.trim()),
      });
    } else {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
      });
    } // eslint-disable-next-line
  }, [from]);

  // const TooltipContent = {
  //   eonian: {
  //     tootTipContent: (
  //       <div className="flex flex-col gap-2 justify-center">
  //         <span>
  //           Is the <span className="font-semibold">Eonian Debate</span> the best
  //           way to reach a final decision?{" "}
  //         </span>
  //         <span>
  //           Probably not, but it <span className="font-semibold">IS</span> a
  //           great way to explore the depths of human creativity, inventiveness,
  //           pain, joy, and more!
  //         </span>
  //         <span>
  //           Some of the most exciting debates evolve as arguments are refined
  //           and new evidence is uncovered, presented, or discovered.
  //         </span>
  //         <span>People even occasionally change their mind!</span>
  //       </div>
  //     ),

  //     contentStack: [
  //       "Is the Eonian Debate the best way to reach a final decision?",
  //       "Probably not, but it IS a great way to explore the depths of human creativity, inventiveness, pain, joy, and more!",
  //       "Some of the most exciting debates evolve as arguments are refined and new evidence is uncovered, presented, or discovered.",
  //       "People even occasionally change their mind!",
  //     ],
  //   },
  //   blitz: {
  //     tootTipContent: (
  //       <div className="flex flex-col gap-2 justify-center">
  //         <span>The premier arena for quick thinking and sharp wit!</span>
  //         <span>
  //           In just one round, participants unleash their most compelling points
  //           with no time to waste.
  //         </span>
  //         <span>
  //           The clock is ticking, the stakes are high, and in 24 hours, a clear
  //           winner emerges. It's all the thrill without the wait—are you ready
  //           to Blitz?
  //         </span>
  //       </div>
  //     ),
  //     contentStack: [
  //       "The premier arena for quick thinking and sharp wit!",
  //       "In just one round, participants unleash their most compelling points with no time to waste.",
  //       "The clock is ticking, the stakes are high, and in 24 hours, a clear winner emerges. It's all the thrill without the wait—are you ready to Blitz?",
  //     ],
  //   },
  //   standard: {
  //     tootTipContent: (
  //       <div className="flex flex-col gap-2 justify-center">
  //         <span>
  //           The <span className="font-semibold"> Standard Debate </span> is the
  //           ideal platform for thoughtful discourse, strategic maneuvering, and
  //           captivating exchanges!
  //         </span>
  //         <span>
  //           With three rounds and no time constraints, participants have the
  //           freedom to delve deep into their arguments, refine their positions,
  //           and deliver researched rebuttals.
  //         </span>
  //         <span>
  //           Plus, with the option for an open debate, anyone in your forum or
  //           DebateBase can jump in, adding fresh perspectives and sparking
  //           dynamic discussions.
  //         </span>
  //       </div>
  //     ),
  //     contentStack: [
  //       "The Standard Debate is the ideal platform for thoughtful discourse, strategic maneuvering, and captivating exchanges!",
  //       "With three rounds and no time constraints, participants have the freedom to delve deep into their arguments, refine their positions, and deliver researched rebuttals.",
  //       "Plus, with the option for an open debate, anyone in your forum or DebateBase can jump in, adding fresh perspectives and sparking dynamic discussions. ",
  //     ],
  //   },
  //   custom: {
  //     tootTipContent: (
  //       <div className="flex flex-col gap-2 justify-center">
  //         <span>
  //           The <span className="font-semibold"> Custom debate </span> is the
  //           ultimate sandbox for seasoned DebateBase enthusiasts craving full
  //           control!
  //         </span>
  //         <span>
  //           Whether you want an intense marathon of ideas or a swift exchange of
  //           wits, the Custom Debate lets you set the stage. It's your debate,
  //           your rules—ready to design your own epic showdown?
  //         </span>
  //       </div>
  //     ),
  //     contentStack: [
  //       "The Custom debate is the ultimate sandbox for seasoned DebateBase enthusiasts craving full control!",
  //       "Whether you want an intense marathon of ideas or a swift exchange of wits, the Custom Debate lets you set the stage. It's your debate, your rules—ready to design your own epic showdown?",
  //     ],
  //   },
  // };
  const TooltipContent = {
    eonian: {
      tootTipContent: (
        <div className="flex flex-col gap-2 justify-center">
          <span>
            Is the <span className="font-semibold">Eonian Debate</span> the best
            way to reach a final decision?{" "}
          </span>
          <span>
            Probably not, but it <span className="font-semibold">IS</span> a
            great way to explore the depths of human creativity, inventiveness,
            pain, joy, and more!
          </span>
          <span>
            Some of the most exciting debates evolve as arguments are refined
            and new evidence is uncovered, presented, or discovered.
          </span>
          <span>People even occasionally change their mind!</span>
        </div>
      ),

      contentStack: [
        "Is the Eonian Debate the best way to reach a final decision?",
        "Probably not, but it IS a great way to explore the depths of human creativity, inventiveness, pain, joy, and more!",
        "Some of the most exciting debates evolve as arguments are refined and new evidence is uncovered, presented, or discovered.",
        "People even occasionally change their mind!",
      ],
    },
    blitz: {
      tootTipContent: (
        <div className="flex flex-col gap-2 justify-center">
          <span>One round</span>
          <span>First to 25 votes wins the debate.</span>
          <span>Most votes after 24 hours wins</span>
          <span>
            Clock starts when invited debater clicks submit on their argument
          </span>
          <span>Arguments up to 500 words</span>
          <span>Reference up to two citations to back up your argument</span>
        </div>
      ),
      contentStack: [
        "One round",
        "First to 25 votes wins the debate.",
        "Most votes after 24 hours wins",
        "Clock starts when invited debater clicks submit on their argument",
        "Arguments up to 500 words",
        "Reference up to two citations to back up your argument",
      ],
    },
    standard: {
      tootTipContent: (
        <div className="flex flex-col gap-2 justify-center">
          <span>Three rounds</span>
          <span>First to 25 votes wins the round</span>
          <span>Debater with the most votes after 24 hours wins the round</span>
          <span>
            Clock starts when invited debater clicks submit on their argument
          </span>
          <span>Arguments up to 500 words</span>
          <span>Reference up to two citations for each argument</span>
          <span>Debater can earn one upvote for each argument</span>
          <span>
            Debater with the most total votes after three rounds wins the
            debate!
          </span>
        </div>
      ),
      contentStack: [
        "Three rounds",
        "First to 25 votes wins the round .",
        "Debater with the most votes after 24 hours wins the round. ",
        "Clock starts when invited debater clicks submit on their argument",
        "Arguments up to 500 words",
        "Reference up to two citations for each argument",
        "Debater can earn one upvote for each argument",
        "Debater with the most total votes after three rounds wins the debate!",
      ],
    },
    custom: {
      tootTipContent: (
        <div className="flex flex-col gap-2 justify-center">
          {/* <span>
          Open to DebateBase Premium users
          </span> */}
          <span>You choose the number of rounds</span>
          <span>You choose how many votes it takes to win a round</span>
          <span>You decide the length of each round</span>
          <span>
            Get more space to get your point across with up to 500 words per
            argument!
          </span>
          <span>
            Opportunity to earn more points per round by referencing up to two
            citations for each round
          </span>
          <span>Debater can earn one upvote for each argument</span>
          <span>
            Debater with the most total votes after all rounds wins the debate!
          </span>
        </div>
      ),
      contentStack: [
        "You choose the number of rounds",
        "You choose how many votes it takes to win a round",
        "You decide the length of each round",
        "Get more space to get your point across with up to 500 words per argument!",
        "Opportunity to earn more points per round by referencing up to two citations for each round",
        "Debater can earn one upvote for each argument",
        "Debater with the most total votes after all rounds wins the debate!",
      ],
    },
  };

  const extraOptions = DebateTypeDetails?.map((item) => {
    return {
      opt: item?.type + " Debate",
      subOpt: "",
      tooltipContent:
        item?.type === "Eonian"
          ? TooltipContent.eonian.tootTipContent
          : item?.type === "Blitz"
          ? TooltipContent.blitz.tootTipContent
          : item?.type === "Standard"
          ? TooltipContent.standard.tootTipContent
          : TooltipContent.custom.tootTipContent,
      value: item?.type?.toLowerCase(),
      typeDetails:
      item?.type === "Eonian"
      ? TooltipContent.eonian.contentStack
      : item?.type === "Blitz"
      ? TooltipContent.blitz.contentStack
      : item?.type === "Standard"
      ? TooltipContent.standard.contentStack
      : TooltipContent.custom.contentStack,
    };
  });

  const content = {
    headLine: "Debate type",
    baseLine:
      "Groups are a way to organize your conversations. Below are some suggestions to get you started.",
    extraDetail: extraOptions,
    page: 1,
    pageName: "DebateType",
  };

  const handleState = (value) => {
    handleStateUpdate({
      ...debateInfo,
      Type: value,
    });
  };

  return (
    <div className="flex flex-col gap-4">
      {" "}
      <DebateHeader content={content} />
      <RadioCardBlock
        content={content}
        status={debateInfo?.Type}
        setStatus={handleState}
        isTooltip={true}
      />
      <DebateFooter
        isNext={debateInfo?.Type ? false : true}
        handleBack={handleBack}
        handleNext={handleNext}
      />{" "}
    </div>
  );
};

export default SelectType;
