import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AddFollowing, getFollowingList } from "../../api/profile/follwing";

export const useGetFollowingList = (userId,  workSpaceId, search) =>
  useQuery({
    queryKey: ["followings", userId,  workSpaceId, search],
    queryFn: () => getFollowingList(userId,  workSpaceId, search),
    select: (data) => data.followingLists,
    enabled: !!userId || !!workSpaceId || !!search,
  });

export const useAddFollowing = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (followingBody) => {
      return await AddFollowing(followingBody);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["followings"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["followers"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["other-user"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["user"],
        }),
      ]);
    },
    onError: (error) => {
      console.log(error);
    },
  });
};
