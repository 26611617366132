import { Card, CardBody, Spinner } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import moment from "moment";
import React, { Fragment, useDeferredValue, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import InfoPopup from "../../Components/Popup/InfoPopup";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import InputField from "../../Layout/InputField";
import { getInitials } from "../../Utils/Helper";
import { useValidateSpaceName } from "../../Web-Hooks/use-validation";
import {
  useDeleteUserFromSubWorkspace,
  useUpdateSubWorkspace,
} from "../../Web-Hooks/Workspace/use-subworkspace";
import {
  useDeleteUserFromWorkspace,
  useUpdateWorkspace,
} from "../../Web-Hooks/Workspace/use-workspace";
import { CheckIcon } from "@heroicons/react/24/outline";

const AboutDebate = ({ popupFor }) => {
  const navigate = useNavigate();
  const { selectedWorkspace, selectWorkspace } = useWorkspace();
  const { selectedSubWorkspace, selectSubWorkspace, subWorkspaceList } =
    useSubWorkspace();
  const { user } = useAuth();
  const Details =
    popupFor === "subworkspace" ? selectedSubWorkspace : selectedWorkspace;
  const defaultName =
    popupFor === "subworkspace"
      ? selectedSubWorkspace?.subWorkSpaceName
      : selectedWorkspace?.workSpaceName;
  const [name, setName] = useState(defaultName || "");
  const [edit, setEdit] = useState(false);
  const [leavePopup, setLeavePopup] = useState(false);
  const deferredName = useDeferredValue(name);
  const queryClient = useQueryClient();
  const type = popupFor === "subworkspace" ? "SubWork" : "Work";
  const id = popupFor === "subworkspace" ? selectedWorkspace?.workSpaceId : "";
  const { data: isValidWorkspaceName, isLoading: isValidatingWorkspaceName } =
    useValidateSpaceName(type, deferredName || null, id);
  const { mutateAsync: updateWorkspace } = useUpdateWorkspace();
  const { mutateAsync: updateSubWorkspace } = useUpdateSubWorkspace();
  const {
    mutateAsync: leaveFromSubworkspace,
    isPending: isLeavingFromSubworkspace,
  } = useDeleteUserFromSubWorkspace();
  const { mutateAsync: leaveFromWorkspace, isPending: isLeavingFromWorkspace } =
    useDeleteUserFromWorkspace();

  const isLoggedInUserIsAdmin = [Details?.createdBy]?.find(
    (creator) => creator?.userId === user?.userId
  )
    ? true
    : false;

  const message = isLoggedInUserIsAdmin ? "delete" : "leave";

  const setWorkspaceName = (e) => {
    const { name, value } = e.target;
    // Replace spaces with dashes only if the input name is "WorkSpaceName"
    const processedValue =
      name === "WorkSpaceName" ? value.replace(/\s+/g, "-") : value;
    setName(processedValue);
  };

  const handleChange = (workspace, value) => {
    if (value === "") return;
    if (popupFor === "subworkspace") {
      const updatedSubWorkspace = {
        WorkSpaceId: workspace.workSpaceId,
        SubWorkSpaceId: workspace.subWorkSpaceId,
        InvitedBy: user?.userId,
        SubWorkSpaceName: value,
      };
      value !== "" &&
        value !== selectedSubWorkspace?.subWorkSpaceName &&
        updateSubWorkspace(updatedSubWorkspace)
          .then((res) => {
            selectSubWorkspace({ ...selectedSubWorkspace, subWorkSpaceName: value });
            // UpdateSelectedSubWorkspace(subworkSpace);
          })
          .finally(() => {
            queryClient.invalidateQueries({
              queryKey: ["sub-workspaces", selectedWorkspace?.workSpaceId],
            });
          });
    } else {
      const updatedWorkspace = {
        WorkSpaceId: workspace.workSpaceId,
        WorkSpaceName: value,
        WorkSpaceInitials: getInitials(value),
        UserId: user.userId,
      };
      value !== "" &&
        value !== selectedWorkspace?.workSpaceName &&
        updateWorkspace(updatedWorkspace)
          .then((res) => {
            selectWorkspace({ ...selectedWorkspace, workSpaceName: value });

            // UpdateSelectedSubWorkspace(workSpace);
          })
          .finally(() => {
            queryClient.invalidateQueries({
              queryKey: ["workspaces", user?.userId],
            });
          });
    }

    setEdit(false);
  };

  const handleLeave = async () => {
    const deleteUser = {
      [popupFor === "subworkspace" ? "SubWorkSpaceId" : "WorkSpaceId"]:
        popupFor === "subworkspace"
          ? selectedSubWorkspace?.subWorkSpaceId
          : selectedWorkspace?.workSpaceId,
      UserId: user?.userId,
    };
    try {
      await (popupFor === "subworkspace"
        ? leaveFromSubworkspace
        : leaveFromWorkspace)(deleteUser);
      toast.success(
        `You have successfully ${
          isLoggedInUserIsAdmin ? "deleted" : "left"
        } ${defaultName}`
      );
      setLeavePopup(false);
      if (popupFor === "subworkspace") {
        const anotherSubworkspace = subWorkspaceList.find(
          (subWorkspace) =>
            subWorkspace.subWorkSpaceId !== selectedSubWorkspace?.subWorkSpaceId
        );
        if (anotherSubworkspace) {
          selectSubWorkspace(anotherSubworkspace);
        } else {
          selectSubWorkspace(null);
        }
        navigate("/feed-public", { replace: true });
      } else {
        navigate("/createworkspace");
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Fragment>
      {leavePopup && (
        <InfoPopup
          btn1={"Cancel"}
          btn2={message}
          loading2={isLeavingFromSubworkspace || isLeavingFromWorkspace}
          onClickBtn2={handleLeave}
          open={leavePopup}
          handleClose={() => setLeavePopup(false)}
          description2={`Are you sure you want to ${message} ${defaultName}?`}
        />
      )}
      <Card shadow={false} className="">
        <CardBody className="p-0 border border-debatePalette-buttonBorder rounded-lg ">
          <div className="flex min-w-full items-center gap-2 border-b px-2 border-debatePalette-buttonBorder py-4">
            <div className="flex flex-col flex-1">
              {edit ? (
                <InputField
                  isControlled={true}
                  inputName="WorkSpaceName"
                  value={name}
                  onChange={setWorkspaceName}
                  icon={
                    isValidatingWorkspaceName ? (
                      <Spinner />
                    ) : (
                      isValidWorkspaceName && (
                        <CheckIcon className="font-bold" />
                      )
                    )
                  }
                  isError={
                    isValidatingWorkspaceName
                      ? false
                      : name !== defaultName &&
                        !isValidWorkspaceName &&
                        name !== ""
                  }
                  // errorMessage={
                  //   isValidatingWorkspaceName
                  //     ? ""
                  //     : !isValidWorkspaceName &&
                  //       name !== "" &&
                  //       "Debatebase name already exists"
                  // }
                />
              ) : (
                <p className="text-sm font-semibold xs:line-clamp-1 text-debatePalette-title capitalize">
                  {defaultName}
                </p>
              )}
            </div>
            {(
              popupFor === "subworkspace"
                ? user?.role === "Admin" || user?.role === "Manager"
                : user?.role === "Admin"
            ) ? (
              <p
                className="text-sm font-medium text-debatePalette-shadow capitalize cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  !edit
                    ? setEdit(true)
                    : handleChange(selectedSubWorkspace, deferredName);
                }}
              >
                {edit
                  ? !isValidWorkspaceName && deferredName !== defaultName
                    ? ""
                    : "Save"
                  : "Edit"}
              </p>
            ) : null}
          </div>

          {popupFor === "subworkspace" ? (
            <div className="flex justify-between px-2 border-b w-full border-debatePalette-buttonBorder py-4">
              <div className="flex flex-col w-full">
                <p className="text-sm font-bold text-debatePalette-title capitalize">
                  Managed By
                </p>

                <div className="text-sm font-medium text-debatePalette-shadow max-h-16 w-full capitalize overflow-y-auto">
                  {Details?.managedBy?.map((user) => {
                    return (
                      <div className="flex gap-2" key={user?.userName}>
                        <p className="text-sm text-debatePalette-bodyText capitalize">
                          {user?.userName}
                        </p>
                        <span className="text-sm text-debatePalette-shadow capitalize">
                          {user?.role}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}

          <div className="flex justify-between px-2  border-debatePalette-buttonBorder py-4">
            <div className="flex flex-col">
              <p className="text-sm font-bold text-debatePalette-title capitalize">
                Created By
              </p>

              <div className="text-xs font-medium text-debatePalette-bodyText capitalize">
                {[Details?.createdBy]?.map((user) => {
                  return (
                    <div className="flex gap-2" key={user?.userName}>
                      <p className="text-sm text-debatePalette-bodyText">
                        {user?.userName}{" "}
                      </p>
                      <span className="text-sm text-debatePalette-bodyText ">
                        on {moment(user?.createdDate).format("MMM DD, YYYY")}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {/* <div className="flex px-2 justify-between py-4">
            <div className="flex flex-col">
              <p
                className="text-sm font-bold text-debatePalette-timer capitalize cursor-pointer xs:line-clamp-1"
                onClick={() => setLeavePopup(true)}
              >
                {isLoggedInUserIsAdmin ? "Delete" : "Leave"} {defaultName}
              </p>
            </div>
          </div> */}
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default AboutDebate;
