import { Button } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import NoData from "../../../Components/Functionality/NoData";
import { useAuth } from "../../../Context/AuthContext";
import { SocketEvents, useSocket } from "../../../Context/SocketContext";
import { isTodayOrYesterday } from "../../../Utils/Helper";
import { useGetChatHistory } from "../../../Web-Hooks/Chat/chat";
import MessageCard from "./MessageCard";
import TitleDivider from "../TitleDivider";
import LoadingSpinner from "../../../Components/UI/LoadingSpinner";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";

const MessageScreen = ({ user }) => {
  const messagesEndRef = useRef(null);
  const { user: loggedInUser } = useAuth();
  const [chat, setChat] = useState([]);
  const [typeState, setTypeState] = useState(false);
  const { socket, sendMessage } = useSocket();
  const [size] = useState(100);
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true"

  const { data: chatHistory, isLoading: isChatLoading } = useGetChatHistory(
    loggedInUser?.userId,
    user?.userId,
    selectedWorkspace?.workSpaceId,
    1,
    size,
    isForumOnly
      ? selectedSubWorkspace?.subWorkSpaceId
      : ""
  );

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [chat, typeState]);

  useEffect(() => {
    const history = chatHistory;
    setChat(history || []);

    return () => {
      setChat([]);
    };
  }, [chatHistory, loggedInUser, user]);

  useEffect(() => {
    if (!socket) return;
    // to check user is typing or not
    socket.on("userTyping", (data) => {
      setTypeState(data);
    });

    // to check if receiver has read the message and update chat from sender side
    socket.on(
      SocketEvents.MARKED_AS_READ,
      ({ senderId, receiverId, workspaceId, subworkspaceId }) => {
        if (
          workspaceId !== selectedWorkspace?.workSpaceId ||
          subworkspaceId !==
            (isForumOnly
              ? selectedSubWorkspace?.subWorkSpaceId
              : "")
        )
          return;

        if (loggedInUser?.userId === receiverId) {
          setChat((prevChat) =>
            prevChat.map((msgGroup) => ({
              ...msgGroup,
              messages: msgGroup.messages.map((msg) => {
                if (msg.senderId === receiverId) {
                  return { ...msg, isRead: true };
                }
                return msg;
              }),
            }))
          );
        }
      }
    );

    // to remove deleted message
    socket.on("messageDeleted", ({ messageId }) => {
      setChat((prevChat) =>
        prevChat.map((msgGroup) => ({
          ...msgGroup,
          messages: msgGroup.messages.filter((msg) => msg.id !== messageId),
        }))
      );
    });
  }, [socket, loggedInUser, selectedWorkspace, selectedSubWorkspace, isForumOnly]);

  useEffect(() => {
    if (!socket) return;
    const handleReceiveMessage = (message) => {
      if (
        message.messages[0].workspaceId !== selectedWorkspace?.workSpaceId ||
        message.messages[0].subworkspaceId !==
          (isForumOnly
            ? selectedSubWorkspace?.subWorkSpaceId
            : "")
      )
        return;

      setChat((prevChat) => [...prevChat, message]);
      if (message.messages[0].receiverId === loggedInUser?.userId) {
        socket.emit(SocketEvents.MARK_ALL_AS_READ, {
          senderId: loggedInUser?.userId,
          receiverId: user?.userId,
          workspaceId: selectedWorkspace?.workSpaceId,
          subworkspaceId:
            isForumOnly
              ? selectedSubWorkspace?.subWorkSpaceId
              : "",
        });
      }
    };

    // to receive message
    socket.on("receiveMessage", handleReceiveMessage);
    return () => {
      socket.off("receiveMessage", handleReceiveMessage);
    };
  }, [
    socket,
    chat,
    loggedInUser,
    user,
    selectedWorkspace,
    selectedSubWorkspace,
    isForumOnly
  ]);

  const handleSend = () => {
    const Message = {
      type: "text",
      content: {
        text: "Hey there",
      },
    };

    sendMessage(
      loggedInUser?.userId,
      user?.userId,
      Message,
      selectedWorkspace?.workSpaceId,
      isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : ""
    );
  };

  if (isChatLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="h-full w-full overflow-y-auto  pb-3 px-6">
      <section
        className={`${
          chat?.length > 0 ? "min-h-full" : "h-full"
        } w-full flex flex-col justify-end`}
      >
        {chat?.length > 0 ? (
          chat?.map((message, index) => (
            <React.Fragment key={index}>
              {message?.messageGroup && (
                <TitleDivider
                  title={isTodayOrYesterday(message?.messageGroup)}
                />
              )}

              {message?.messages?.map((message, index) => (
                <MessageCard
                  receiver={user}
                  message={message}
                  key={message.id}
                />
              ))}
            </React.Fragment>
          ))
        ) : (
          <React.Fragment key="hello">
            <div className="my-8 h-full">
              <NoData
                size={"h-[80%] w-full"}
                children={
                  <Button
                    className="bg-secondary shrink-0"
                    onClick={handleSend}
                  >
                    Say Hello
                  </Button>
                }
                message="No messages yet"
              />{" "}
            </div>
          </React.Fragment>
        )}

        {typeState.isTyping && typeState.userId === user?.userId && (
          <div className="flex items-end justify-start gap-4 my-2">
            <span className="text-md font-semibold text-secondary">
              <span className="capitalize">{user?.username}</span> is typing
            </span>
            <ThreeDots
              visible={true}
              height={"20px"}
              width={"20px"}
              color={"#153C58"}
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
      </section>
      <div ref={messagesEndRef} />
    </div>
  );
};

export default MessageScreen;
