import { Chip } from '@material-tailwind/react'
import React from 'react'

const Version = () => {
    // console.log(process.env.REACT_APP_VERSION)
  return (
    <Chip size='sm' variant='ghost' color='green' className='absolute bottom-2 right-2 w-11 z-100' value={process.env.REACT_APP_VERSION}/>
  )
}

export default Version