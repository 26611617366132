import React, { useEffect } from "react";
import { InputFocusBlue } from "../../Utils/Constant";
import { useGetCitationDescription } from "../../Web-Hooks/LibraryAPI/use-citation";
import LoadingSpinner from "../UI/LoadingSpinner";
import EditableBodyPopup from "./EditableBodyPopup";
import { createRegexRenderer, RichTextarea } from "rich-textarea";
import { isValidUrl } from "../../Utils/Helper";

const CitationPopup = ({
  popupOpen,
  setPopupOpen,
  selectedCitation = {
    citationUrl: "",
    citationNote: "",
    citationType: "",
    citationTitle: "",
  },
  setSelectedCitation,
  handleSubmit,
  isFromExternalCitation = false,
  isUploadCitationPending,
  isEdit,
  heading
}) => {
  const handleClose = () => {
    setSelectedCitation({
      citationUrl: "",
      citationNote: "",
      citationType: "",
      citationTitle: "",
    });
    setPopupOpen(false);
  };

  const isValid = isValidUrl(selectedCitation?.citationUrl);

  const {
    data: urlData,
    isLoading: isCitationLoading,
    status,
  } = useGetCitationDescription(isValid ? selectedCitation?.citationUrl : "");

  useEffect(() => {
    if (status === "success" && isFromExternalCitation) {
      setSelectedCitation({
        ...selectedCitation,
        citationNote: `Title: ${urlData?.title}\nDescription: ${urlData?.description}`,
        citationType: "url",
      });
    } // eslint-disable-next-line
  }, [status, isFromExternalCitation]);

  const renderer = createRegexRenderer([
    [/Title:/g, { fontWeight: "bold", color: "black" }],
    [/Description:/g, { fontWeight: "bold", color: "black" }],
    [/./g, { fontWeight: "200", color: "black" }],
  ]);
  // const renderer = createRegexRenderer([
  //   [/[A-Z][A-x][a-z]+/g, {fontWeight: "200", color: "black" }],
  //   [/Title:/g, {fontWeight: "bold", color: "black"}],
  //   [/Description:/g, {fontWeight: "bold", color: "black"}],
  //   [/[undefined]/g, { fontWeight: "200", color: "black"}],
  // ]);

  // Function to render the styled text dynamically
  // const createStyledText = (text) => {
  //   const parts = text.split(/(Title:|Description:)/g); // Split by the keywords
  //   return parts.map((part, index) => {
  //     if (part === "Title:" || part === "Description:") {
  //       return (
  //         <span key={index} style={{ fontWeight: "bold", color: "black" }}>
  //           {part}
  //         </span>
  //       );
  //     }
  //     return (
  //       <span key={index} style={{ fontWeight: "200", color: "black" }}>
  //         {part}
  //       </span>
  //     );
  //   });
  // }


  const isFromExternalValidateUrl = isFromExternalCitation ? !isValidUrl(selectedCitation?.citationUrl) : false

  return (
    <div>
      <EditableBodyPopup
        size="sm"
        btn1={"Close"}
        title={heading === "Add Reference" ? heading : "Add Citation"}
        handleClose={handleClose}
        open={popupOpen}
        btn2={isEdit ? "Update" : "Attach"}
        onClickBtn1={handleClose}
        onClickBtn2={handleSubmit}
        loading={isUploadCitationPending}
        disableBtn={
          !selectedCitation?.citationUrl || !selectedCitation?.citationNote || isFromExternalValidateUrl
        }
      >
        <div className="flex flex-col gap-4 w-full justify-center items-center">
          <div className="flex flex-col gap-2 w-full">
            <input
              id="citation-heading"
              type="text"
              className={`border-2 rounded-md p-2 mt-1 block w-full text-debatePalette-title !border-debatePalette-shadow focus:outline-none ${InputFocusBlue}`}
              placeholder="Enter your url here."
              value={
                selectedCitation?.citationTitle
                  ? selectedCitation?.citationTitle
                  : selectedCitation?.citationUrl
                  ? selectedCitation?.citationUrl?.split("_")[1]
                    ? selectedCitation?.citationUrl?.split("_")[1]
                    : selectedCitation?.citationUrl
                  : ""
              }
              readOnly={isEdit}
              onChange={(e) =>
                setSelectedCitation({
                  ...selectedCitation,
                  citationUrl: e.target.value,
                })
              }
            />
          </div>
          <div className="flex flex-col min-w-full">
            {isCitationLoading ? (
              <LoadingSpinner type={"ThreeDots"} color={"#1F455D"} />
            ) : (
              // <textarea
              //   type="text"
              //   rows={3}
              //   className={`border-2 text-debatePalette-title font-medium rounded-md !border-debatePalette-shadow p-2 mt-1 focus:outline-none block w-full ${InputFocusBlue}`}
              //   placeholder="Write a note to attach to your debate, this note will
              //         not be displayed"
              //   value={selectedCitation?.citationNote}
              //   onChange={(e) =>
              //     setSelectedCitation({
              //       ...selectedCitation,
              //       citationNote: e.target.value,
              //     })
              //   }
              // />
              <RichTextarea
                id="citation-text"
                type="text"
                rows={3}
                className={`border-2  rounded-md !border-debatePalette-shadow p-2 mt-1 focus:outline-none block min-w-full ${InputFocusBlue}`}
                placeholder="Write a note to attach to your debate, this note will 
                    not be displayed"
                style={{ width: "100%" }}
                value={selectedCitation?.citationNote}
                onChange={(e) =>
                  setSelectedCitation({
                    ...selectedCitation,
                    citationNote: e.target.value,
                  })
                }
              >
                {/* {(v) => {
          return v.split("").map((t, i) => (
            <span key={i} style={{ fontWeight:(t ==="Title:" || " Description:")? "bold" : "200",color:"black" }}>
              {t}
            </span>
          ));
          
        }} */}
                {renderer}
              </RichTextarea>
            )}
          </div>
        </div>
      </EditableBodyPopup>
    </div>
  );
};

export default CitationPopup;
