import React from "react";

export default function TitleDivider(props) {
  return (
    <div className="relative mr-2 my-2">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t border-gray-400"></div>
      </div>
      {props.title && (
        <div className="relative flex justify-center">
          <span className="px-8 bg-white text-md text-gray-700 border-gray-400 text-primary-primary  border rounded-full py-2">
            {props.title}
          </span>
        </div>
      )}
    </div>
  );
}
