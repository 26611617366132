import { Avatar, Card, CardBody, CardHeader } from "@material-tailwind/react";
import React from "react";
import image from "../../Assets/Images/Cambridge.png";

const NewsDetailCard = ({ selectedNews }) => {
  return (
    <Card shadow={false} className="w-full flex-col lg:flex-row">
      <CardHeader
        shadow={false}
        floated={true}
        className="m-0 lg:w-1/4 shrink-0 h-fit rounded-r-none"
      >
        <Avatar
          variant="square"
          className="h-full w-full object-cover"
          src={image}
          alt="alt"
        />
      </CardHeader>
      <CardBody className=" flex lg:flex-row flex-col gap-2 h-full">
        <div className="flex flex-col gap-2">
          <p className="text-4xl text-debatePalette-title font-semibold">
            {selectedNews?.title}
          </p>
          {selectedNews?.body?.map((body, i) => (
            <p key={i}>{body}</p>
          ))}
        </div>
      </CardBody>
    </Card>
  );
};

export default NewsDetailCard;
