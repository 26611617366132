import axios from "axios";
import { CHAT_HISTORY, CLEAR_CHAT, DELETE_MESSAGE, GET_RECENT_USERS, GET_USERS, GET_WORKSPACE_USER } from "../chatApiRoutes";

const baseUrl = process.env.REACT_APP_CHAT_URL

export const getUserList = async (userId) => {
    const data  = await axios.get(`${baseUrl}${GET_RECENT_USERS}/${userId}`, "Failed to fetch chat user list");
    return data;

}
export const getUsersList = async (groupId) => {
    const data  = await axios.get(`${baseUrl}${GET_USERS}/${groupId}`,);
    return data;

}
export const getUserHistory = async (senderId, receiverId,workId,page,limit,subId) => {
    const data  = await axios.get(`${baseUrl}${CHAT_HISTORY}/${senderId}/${receiverId}/${workId}?page=${page || 1}&limit=${limit || 10}&subworkspaceId=${subId}`, "Failed to fetch chat history");
    return data;

}
export const getUserExternalData = async (userId,workId, subId, query) => {
    const data  = await axios.get(`${baseUrl}${GET_WORKSPACE_USER}/${userId}/${workId}?subWorkspaceId=${subId}&searchQuery=${query}`, "Failed to fetch public debate list");
    return data;
}
export const deleteMessage = async (userId, messageId) => {
    const data  = await axios.delete(`${baseUrl}${DELETE_MESSAGE}/${userId}/${messageId}`, "Failed to fetch public debate list");
    return data;
}
export const clearChat = async (senderId, receiverId, workId, subId) => {
    const data  = await axios.delete(`${baseUrl}${CLEAR_CHAT}/${senderId}/${receiverId}/${workId}?subworkspaceId=${subId}`, "Failed to fetch public debate list");
    return data;
}

