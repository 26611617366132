import React, { useState } from "react";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import { useSocket } from "../../Context/SocketContext";
import { useAuth } from "../../Context/AuthContext";
import { useGetExternalData } from "../../Web-Hooks/Chat/chat";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { Checkbox } from "@material-tailwind/react";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { getUserImageUrl } from "../../Utils/Helper";

const ShareDebate = ({
  shareDebatePopup,
  setShareDebatePopup,
  debate,
  setDebate,
  type = "debate",
  enabled
}) => {
  const { sendMessage } = useSocket();
  const { user } = useAuth();
  const [contact, setContact] = useState([]);
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { data: chatUserList, isLoading: chatUserListLoading } =
    useGetExternalData(
      user?.userId,
      selectedWorkspace?.workSpaceId,
      selectedSubWorkspace?.subWorkSpaceId,
      enabled
    );

  const handleSendOject = async (receivers = []) => {
    if (!receivers.length) return;

    const Message = {
      type: type === "debate" ? "debate" : "profile",
      content: debate,
    };

    receivers.forEach((receiver) => {
      sendMessage(user?.userId, receiver.id, Message, selectedWorkspace?.workSpaceId, selectedSubWorkspace?.subWorkSpaceId);
    });
  };

  const isDebate = type === "debate" ? true : false;

  const handleChange = (member) => {
    if (contact.some((c) => c.id === member.id)) {
      setContact(contact.filter((c) => c.id !== member.id));
    } else {
      setContact([...contact, member]);
    }
  };

  return (
    <div>
      <EditableBodyPopup
        open={shareDebatePopup}
        handleClose={() => {
          setShareDebatePopup(false);
          setDebate(null);
          setContact([]);
        }}
        disableBtn={!contact}
        btn1={"Cancel"}
        btn2={"Send"}
        title={`Share ${isDebate ? "Debate" : "Profile"}`}
        onClickBtn1={() => {
          setShareDebatePopup(false);
          setDebate(null);
          setContact([]);
        }}
        onClickBtn2={async () => {
          await handleSendOject(contact);
          setShareDebatePopup(false);
          setDebate(null);
          setContact([]);
        }}
      >
        {chatUserListLoading ? (
          <LoadingSpinner />
        ) : (
          <form className="flex flex-col overflow-y-scroll member-list h-72    items-start gap-2">
            {chatUserList?.map((member) => (
              <Checkbox
                key={member?.id}
                containerProps={{
                  className: "!flex items-center gap-4 shrink-0",
                }}
                checked={contact.some((c) => c.id === member.id)}
                onChange={() => handleChange(member)}
                id={member?.id}
                color="blue"
                ripple={false}
                className="w-4 h-4 rounded-none before:content-none"
                label={
                  <div className="flex items-center gap-2 w-full truncate">
                    {
                      <ImageOrInitials
                        imageSrc={getUserImageUrl(member?.id, member?.avatar)}
                        initials={member?.username || member?.email}
                      />
                    }
                    <p className="w-full text-md">
                      {member?.username || member?.email?.split("@")[0]}
                    </p>
                  </div>
                }
                labelProps={{
                  className:
                    "text-debatePalette-title w-full text-md font-semibold capitalize",
                }}
              />
            ))}
          </form>
        )}
        <div className="flex flex-col gap-2 items-center">
          <p className="text-lg text-debatePalette-bodyText">
            Do you want to share the {`${isDebate ? "Debate" : "Profile"}`}?
          </p>
          <p>
            {isDebate
              ? `/debate/${debate?.debateId}?round=${debate?.currentRound}`
              : `/profile/${debate?.userId}`}
          </p>
        </div>
      </EditableBodyPopup>
    </div>
  );
};

export default ShareDebate;
