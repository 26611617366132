import { Switch } from "@material-tailwind/react";
import React from "react";

const CustomizedSwitch = ({
  mainClassName,
  circleClass,
  containerClass,
  textClass,
  text,
  className,
  value,
  onChange,
}) => {
  return (
    <>
      <div
        className={`flex w-full relative z-10 items-center rounded-md border border-blue-600 item-center gap-3  p-2 ${mainClassName}`}
      >
        <Switch
          checked={value}
          id="custom-switch-component"
          ripple={false}
          className={`h-full w-full bg-white border border-iconPalette-base checked:border-debatePalette-shadow checked:bg-debatePalette-shadow ${className}`}
          containerProps={{
            className: `w-11 h-6 ${containerClass}`,
          }}
          onChange={onChange}
          circleProps={{
            className: `before:hidden left-0.5 border-none bg-iconPalette-base ${circleClass}`,
          }}
        />
        <p className={`text-md ${textClass}`}>{text}</p>
        {/* <span className="text-xs flex items-center gap-2 absolute z-100 -top-2 bg-white px-1 text-blue-600">
          {" "}
          <EyeIcon className="h-3 w-3" /> Only admins can see this setting
        </span> */}
      </div>
    </>
  );
};

export default CustomizedSwitch;


// shadow-[0px_2px_3px_-1px_rgba(0,0,0,0.1),0px_1px_0px_0px_rgba(25,28,33,0.02),0px_0px_0px_1px_rgba(25,28,33,0.08)]