import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CategoriesBlock from "../../Components/Functionality/CategoriesBlock";
import BasicButton from "../../Components/UI/Buttons/BasicButton";
import Container from "../../Components/UI/Container";
import DebateTextArea from "../../Components/UI/Inputs/DebateTextArea";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { getUTCDate } from "../../Utils/Helper";
import { useAddMotion } from "../../Web-Hooks/Motion/use-motion";
import useWordLimitedInput from "../../hooks/useContentLimit";

const SuggestMotion = () => {
  const [selectedTags, setSelectedTags] = useState([]);
  const { user } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { mutateAsync: addMotion, isPending: isAddMotionPending } =
    useAddMotion();
  const navigate = useNavigate();
  const {content, handleContentChange, handlePaste} = useWordLimitedInput("",150)

  const handleSubmit = () => {
    const motionObj = {
      userId: user?.userId,
      Category: selectedTags.join(";"),
      MotionOrClaim: content,
      CreatedAt: getUTCDate(),
      SubworkspaceId: selectedSubWorkspace?.subWorkSpaceId,
    };
    if (Object.values(motionObj).some((value) => !value)) {
      toast.error("Failed to add motion, please try again later.");
      return;
    }
    addMotion(motionObj)
      .then((res) => {
        if (res) {
          toast.success("Your motion has been successfully added.");
          handleContentChange("");
          setSelectedTags([]);
          navigate("/find-motion");
        }
      })
      .catch((error) => console.log(error));
  };


  return (
    <Container>
      <CategoriesBlock
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <div className="w-full lg:w-3/4 mt-5">
        <DebateTextArea
          id={"motion-textarea"}
          label={"Write the motion or claim want to suggest!"}
          maxLength={150}
          value={content}
          handleChange={(e) => handleContentChange(e.target.value)}
          placeholder={"Messi is the greatest athlete of all time"}
          handlePaste={handlePaste}
        />
      </div>
      <BasicButton
        id="submitMotion"
        size={"md"}
        isDisable={!content || selectedTags.length < 1}
        loading={isAddMotionPending}
        color={"primary"}
        children={"Publish"}
        className={
          "capitalize !overflow-visible py-2 px-4 text-md self-center mt-5"
        }
        onClick={handleSubmit}
        istooltip={false}
      />
    </Container>
  );
};

export default SuggestMotion;
