import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React from "react";
import RoundedButton from "../UI/Buttons/RoundedButton";
import InputField from "../../Layout/InputField";
import { DebateCategories } from "../../Utils/Constant";

const AddCategoriesPopup = ({
  handleClose,
  open,
  title,
  setTitle,
  handleSubmit,
  selectedCategory,
  setSelectedCategory,
  loading,
}) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className=""
    >
      <DialogHeader id="alert-dialog-title" className="w-full">
        Create Other Category
      </DialogHeader>
      <DialogBody className="w-full">
        <div
          id="alert-dialog-description"
          className="flex flex-col gap-4 w-4/5"
        >
          <div className="flex flex-col gap-2">
            <label
              htmlFor="CategoryName"
              className="block text-lg font-semibold text-debatePalette-title"
            >
              Category Name
            </label>
            <InputField
              inputName={"CategoryName"}
              type="text"
              className="border-2 border-gray-500 p-2 mt-1 block w-full"
              placeholder="Enter Category Name"
              value={title}
              onChange={(e) => {
                e.preventDefault();
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="flex flex-col items-start w-full">
            <p className="block text-lg font-semibold text-debatePalette-title">
              Select Icon
            </p>
            <div className="flex flex-wrap gap-2 mt-1 justify-start w-full">
              {DebateCategories.map((category, i) => (
                <div key={i} className="flex-shrink-0">
                  <RoundedButton
                    className={`bg-secondary-base text-white hover:border hover:border-secondary-base ${
                      category.Title === selectedCategory &&
                      "bg-secondary text-secondary-base"
                    }`}
                    SVG={category.SVG}
                    BtnWidth="w-12"
                    BtnHeight="h-12 "
                    svgClass={"h-6 w-6"}
                    onClick={() => setSelectedCategory(category.Title)}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-start items-center gap-3 mt-4 w-full">
            <p className="block text-debatePalette-title text-lg font-semibold">
              Preview
            </p>
            <div
              className={`flex justify-start items-center w-full min-h-12  p-2 rounded-md shadow-shadowDebate shadow-debatePalette-shadow`}
            >
              {selectedCategory && (
                <RoundedButton
                  className="bg-secondary text-black text-center"
                  isLoading={selectedCategory === null}
                  SVG={
                    DebateCategories.find(
                      (category) => category.Title === selectedCategory
                    ).SVG
                  }
                  BtnWidth="w-10"
                  BtnHeight="h-10 "
                  svgClass={"h-6 w-6"}
                />
              )}
              <p className="text-center font-semibold mx-2">{title}</p>
            </div>
          </div>
        </div>
      </DialogBody>
      <DialogFooter className="gap-2 justify-center">
        <Button
          variant="outlined"
          onClick={handleClose}
          className="rounded-none border-debatePalette-buttonBorder"
        >
          Cancel
        </Button>
        <Button
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="bg-primary text-debatePalette-title rounded-none"
          type="button"
        >
          Submit
        </Button>
      </DialogFooter>

      <XMarkIcon
        className="w-6 h-6 absolute top-4 right-4 hover:text-debatePalette-title cursor-pointer"
        onClick={handleClose}
      />
    </Dialog>
  );
};

export default AddCategoriesPopup;
