import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/outline";
import React, { useRef, useState } from "react";
import BasicSelect from "../../Components/UI/Select/BasicSelect";

const SortByMotion = ({
  Options,
  setOption,
  defaultValue,
  title = "Sort By:",
  ...props
}) => {
  const [openMenus, setOpenMenus] = useState({});
  const touchStartY = useRef(null);

  // const customStyles = {
  //   control: (provided) => ({
  //     ...provided,
  //     minHeight: "unset",
  //   }),
  //   indicatorSeparator: () => ({
  //     display: "none",
  //   }),
  //   dropdownIndicator: (provided) => ({
  //     ...provided,
  //     padding: 0,
  //   }),
  //   menu: (provided) => ({
  //     ...provided,
  //     zIndex: 10, // Ensure the dropdown appears above other elements
  //   }),
  // };

  const handleToggleMenu = (label) => {
    setOpenMenus((prev) => {
      const newMenus = { ...prev };

      // Close all menus except the clicked one
      Object.keys(newMenus).forEach((key) => {
        if (key !== label) {
          newMenus[key] = false;
        }
      });

      // Toggle the clicked menu
      newMenus[label] = !newMenus[label];

      return newMenus;
    });
  };

  // Updated: Track touch start position
  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY;
  };

  // Updated: Handle selection based on touch move distance
  const handleTouchEnd = (e, option, selectOption) => {
    const touchEndY = e.changedTouches[0].clientY;
    const touchDiff = Math.abs(touchStartY.current - touchEndY);

    // Only select option if the touch difference is small (no scroll)
    if (touchDiff < 10) {
      e.preventDefault();
      e.stopPropagation();
      selectOption(option);
    }
  };

  const renderOption = (option, selectOption, getValue, index) => {
    return (
      <div
        key={option?.value + index} // Unique key using value and index
        className={`relative group py-1 px-6 ${
          option?.value === getValue()[0]?.value
            ? "bg-debatePalette-buttonBorder"
            : ""
        } hover:bg-debatePalette-buttonBorder hover:bg-opacity-50 text-inherit`}
        onMouseDown={(e) => {
          e.preventDefault();
          e.stopPropagation();
          selectOption(option);
        }} // Use onMouseDown for desktop
        onTouchStart={handleTouchStart} // Track touch start
        onTouchEnd={(e) => handleTouchEnd(e, option, selectOption)} // Handle touch end
      >
        <div className="flex gap-2 items-center px-2 py-1 cursor-pointer text-inherit">
          {option?.label}{" "}
          {option?.count ? (
            <span className="text-primary">({option?.count})</span>
          ) : null}
        </div>
      </div>
    );
  };

  const renderMenuList = (props) => {
    const { options, innerProps, selectOption, getValue } = props;

    return (
      <div className="flex flex-col justify-center z-10 py-1.5" key={props?.key}>
        {options.map((option, index) =>
          option?.options ? (
            <div
              key={option?.label + index} // Ensuring unique key for nested options
              {...innerProps}
              className="relative"
            >
              <div
                className={`flex gap-2 items-center px-8 py-2 cursor-pointer hover:bg-debatePalette-buttonBorder`}
                onMouseDown={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleToggleMenu(option?.label);
                }}
                onTouchStart={handleTouchStart} // Track touch start for menu
                onTouchEnd={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleToggleMenu(option?.label);
                }} // Handle touch end for menu toggle
              >
                {option?.label}{" "}
                {openMenus[option?.label] ? (
                  <ChevronUpIcon className="h-4 w-4 text-debatePalette-main stroke-2" />
                ) : (
                  <ChevronDownIcon className="h-4 w-4 text-debatePalette-main stroke-2" />
                )}
              </div>
              {openMenus[option.label] && (
                <div className="max-h-40 overflow-y-auto z-20 relative py-1 text-sm">
                  {option?.options.map((opt, optIndex) =>
                    renderOption(opt, selectOption, getValue, optIndex)
                  )}
                </div>
              )}
            </div>
          ) : (
            renderOption(option, selectOption, getValue, index)
          )
        )}
      </div>
    );
  };

  return (
    <div className="flex gap-2 items-center justify-end relative z-30">
      <p className="text-lg text-debatePalette-bodyText font-medium hidden lg:block">
        {title}
      </p>
      <div className="min-w-[10rem]">
        <BasicSelect
          Options={Options}
          onChange={setOption}
          value={defaultValue}
          renderMenuList={renderMenuList}
        />
      </div>
    </div>
  );
};

export default SortByMotion;
