import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React from "react";

const EditableBodyPopup = ({
  title,
  open,
  children,
  handleClose,
  onClickBtn1,
  onClickBtn2,
  btn1,
  btn2,
  isBtn1Outlined,
  isBtn2Outlined,
  size,
  loading,
  disableBtn,
}) => {
  const isBtnOutline =
    "border border-debatePalette-shadow bg-transparent text-debatePalette-shadow text-base capitalize px-4 py-2";
  const isBtnFilled =
    "bg-primary text-debatePalette-title text-base capitalize px-4 py-2";
  return (
    <Dialog size={size} open={open} onClose={handleClose} className="max-h-[95%] overflow-y-auto" onClick={(e)=> e.stopPropagation()}>
      <DialogHeader id="alert-dialog-title" className="w-full text-center">
        {title}
      </DialogHeader>
      <DialogBody>{children}</DialogBody>
      <DialogFooter className="gap-2 justify-center">
        {btn1 && (
          <Button
            id={`${title}-${btn1}`}
            onClick={onClickBtn1}
            className={`${isBtn1Outlined ? isBtnOutline : isBtnFilled}`}
          >
            {btn1}
          </Button>
        )}

        {btn2 && (
          <Button
            id={`${title}-${btn2}`}
            onClick={onClickBtn2}
            className={` ${isBtn2Outlined ? isBtnOutline : isBtnFilled}`}
            autoFocus
            loading={loading}
            disabled={disableBtn}
          >
            {btn2}
          </Button>
        )}
      </DialogFooter>
      <XMarkIcon
        id={`${title?.replace(/\s/g, "-")}-close`}
        className="w-6 h-6 absolute top-4 right-4 hover:text-debatePalette-title cursor-pointer"
        onClick={handleClose}
      />
    </Dialog>
  );
};

export default EditableBodyPopup;
