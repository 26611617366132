import { Card, CardBody } from "@material-tailwind/react";
import React, { Fragment } from "react";
import BasicButton from "../Buttons/BasicButton";
import { shadowBlue } from "../../../Utils/Constant";

const NewsSubscribeCard = ({ Plan }) => {
  return (
    <Fragment>
      {Plan.map((plan, index) => (
        <Card
          className={`p-0 mt-5 relative z-40 border-debatePalette-shadow border ${shadowBlue} max-w-[250px] min-h-[250px] w-full h-full `}
          key={index}
        >
          {plan?.isRecommended && (
            <div className="absolute -top-[29px] z-0 left-12 px-2 py-1 rounded-t-lg bg-debatePalette-highlight text-debatePalette-title text-sm">
              Most Recommended
            </div>
          )}
          <CardBody className="p-0 flex flex-col min-h-full items-center justify-between">
            <div className="flex w-full justify-end mt-3">
              <div className="rounded-l-full flex text-lg text-debatePalette-background font-semibold justify-center item-center py-2 bg-pink-600 w-[50%]">
                {plan?.title}
              </div>
            </div>
            <div className="flex flex-col gap-10 p-5">
              <p className="text-5xl text-debatePalette-title font-bold">
                ${plan?.price}{" "}
                <span className="text-lg text-debatePalette-title font-semibold">
                  /{plan?.duration}
                </span>
              </p>
              <BasicButton color={"primary"} className={"self-center"}>Buy Now</BasicButton>
            </div>
          </CardBody>
        </Card>
      ))}
    </Fragment>
  );
};

export default NewsSubscribeCard;
