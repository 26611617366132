import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";
import { ReactComponent as EditPencil } from "../../Assets/Svg/EditPencil.svg";
import { ReactComponent as Trash } from "../../Assets/Svg/Trash.svg";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import SearchFilter from "../../Pages/Profile/SearchFilter";
import { useGetMembersList } from "../../Web-Hooks/RoleManagement/use-rolemanagement";
import { useWorkspacesByUserId } from "../../Web-Hooks/Workspace/use-workspace";
import AddEditRole from "../Popup/AddEditRole";
import InfoPopup from "../Popup/InfoPopup";
import ActionButton from "../UI/Buttons/ActionButton";
import BasicButton from "../UI/Buttons/BasicButton";
import TableWithPagination from "./TableWithPagination";
import useDebounce from "../../Services/useDebounce";
import { useDeleteUserFromSubWorkspace } from "../../Web-Hooks/Workspace/use-subworkspace";
import toast from "react-hot-toast";
import { getWorkspaceRole } from "../../Utils/Helper";

function RoleManagementTable() {
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(false);

  const [profile, setProfile] = useState({});
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const { user: loggedInUser } = useAuth();
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 600);
  const [filter, setFilters] = useState(null);

  const subId = filter
    ? filter?.forum?.label !== "All forum"
      ? filter?.forum?.value
      : ""
    : selectedSubWorkspace?.subWorkSpaceId;

  const workId = filter
    ? filter?.forum?.label === "All forum"
      ? filter?.forum?.value
      : filter?.workspace?.value
    : selectedWorkspace?.workSpaceId;

  const { data, isLoading } = useGetMembersList(
    pagination?.pageIndex + 1,
    pagination?.pageSize,
    loggedInUser?.userId,
    workId,
    subId || "",
    debouncedSearchTerm
  );

  useEffect(() => {
    setFilters({
      forum: {
        label: selectedSubWorkspace?.subWorkSpaceName,
        value: selectedSubWorkspace?.subWorkSpaceId,
        role: selectedSubWorkspace?.loginUserRole,
      },
      workspace: {
        label: selectedWorkspace?.workSpaceName,
        value: selectedWorkspace?.workSpaceId,
        role: getWorkspaceRole(selectedWorkspace, loggedInUser?.userId),
      },
    });
  }, [selectedWorkspace, selectedSubWorkspace, loggedInUser]);

  const { data: workSpaces } = useWorkspacesByUserId(loggedInUser?.userId);

  const { data: subWorkSpaces } = useQuery({
    queryKey: [workId, loggedInUser?.userId],
    queryFn: async () => {
      const res = await getSubWorkspaceByWorkId(workId, loggedInUser?.userId);
      return res;
    },
    enabled: !!workId && !!loggedInUser?.userId,
    select: (data) => {
      return data.listOfSubworkspaces;
    },
  });

  const WorkspaceOptions =
    workSpaces?.length > 0
      ? workSpaces?.map((work) => ({
          label: work?.workSpaceName,
          value: work?.workSpaceId,
          role: getWorkspaceRole(work, loggedInUser?.userId),
        }))
      : [];

  const forumOptions =
    subWorkSpaces?.map((subWorkspace) => {
      return {
        label: subWorkspace.subWorkSpaceName,
        value: subWorkspace.subWorkSpaceId,
        role: subWorkspace?.loginUserRole,
      };
    }) || [];

  const {
    mutateAsync: removeUserFromSubWorkspace,
    isPending: isDeleteUserSucceeded,
  } = useDeleteUserFromSubWorkspace();
  const handleDelete = async () => {
    const deleteUser = {
      LoginUserId: loggedInUser?.userId,
      SubWorkSpaceId: subId,
      UserId: deleteId,
    };
    try {
      await removeUserFromSubWorkspace(deleteUser);
      setDeleteId("");
      toast.success("User deleted successfully", "success");
    } catch (err) {
      console.error(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        id: "Username",
        header: "Username",
        // accessorKey: "userName",
        filterFn: "includesString",
        cell: (row) => (
          <div className="p-2">
            <p className="w-full  text-center">
              {row.row.original?.userName || row.row.original?.userEmail}
            </p>
          </div>
        ), //note: normal non-fuzzy filter column - case insensitive
      },
      {
        id: "Forum",
        header: "Forum name",
        accessorKey: "subworkSpaceName",
        filterFn: "includesString", //note: normal non-fuzzy filter column - case insensitive
      },
      {
        id: "Email",
        header: "Email",
        accessorKey: "userEmail",
        filterFn: "includesString",
        cell: (row) => (
          <div className="p-2">
            <p className="w-full  text-center">{row.getValue("userEmail")}</p>
          </div>
        ), //note: normal non-fuzzy filter column - case insensitive
      },
      {
        id: "Role",
        header: "",
        accessorKey: "roleName",
        meta: {
          filterVariant: "select",
        },
        cell: (row) => (
          <div className="p-2">
            <p className="w-full  text-center">
              {`${row.row.original?.roleName} `}
              {row.row.original?.invitationStatus === "pending" ? (
                <span className="text-debatePalette-link">(Invited)</span>
              ) : (
                ""
              )}
            </p>
          </div>
        ),
      },
      {
        id: "Assigned",
        header: "Assigned",
        accessorKey: "assignedDate",
        accessorFn: (row) => moment(row.assignedDate).format("DD MMM, YYYY"),
        filterFn: "equalsString", //note: normal non-fuzzy filter column - case insensitive
      },
      {
        id: "Action",
        header: "Action",
        cell: (row) => {
          const isLoggedInUser =
            loggedInUser?.userId === row.row.original?.userId;
          const listData = row.table.options.data;
          const findLoggedInUser = listData.find(
            (item) => item?.userId === loggedInUser?.userId
          );
          const loggedInUserAdmin = findLoggedInUser?.roleName === "Admin";
          const loggedInUserManager = findLoggedInUser?.roleName === "Manager";

          return (
            <div className="flex gap-2 justify-center">
              {!isLoggedInUser &&
              (loggedInUserAdmin
                ? (row.row.original?.roleName === "Member" ||
                    row.row.original?.roleName === "Manager") &&
                  row.row.original?.invitationStatus !== "pending"
                : loggedInUserManager
                ? row.row.original?.roleName === "Member" &&
                  row.row.original?.invitationStatus !== "pending"
                : false) ? (
                <>
                  <ActionButton
                    title={"Edit"}
                    SVG={EditPencil}
                    isText={false}
                    onClick={() => {
                      const member = row.row.original;
                      setOpen("Edit");
                      const selectedMember = {
                        userId: member?.userId,
                        workSpaceId: selectedSubWorkspace?.workSpaceId,
                        subWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
                        userName: member?.userName || member?.userEmail,
                        email: member?.userEmail,
                        subworkspaceList: [
                          {
                            subworkspaceName: member?.subworkSpaceName,
                            subworkspaceId: member?.subworkspaceId,
                            role: member?.roleName,
                            isAccept: true,
                          },
                        ],
                        assigned: new Date(),
                      };
                      setProfile(selectedMember);
                    }}
                  />
                  <ActionButton
                    title={"Delete"}
                    SVG={Trash}
                    isText={false}
                    onClick={() => {
                      setDeleteId(row.row.original?.userId);
                    }}
                  />
                </>
              ) : null}
            </div>
          );
        },
      },
    ], // eslint-disable-next-line
    [data]
  );

  return (
    <div className="w-full">
      <div className="flex w-full items-center justify-between gap-3 flex-wrap">
        <div className="lg:w-auto">
          <SearchFilter
            showFilters={["search", "workspace", "forum"]}
            filters={
              filter || {
                forum: {
                  label: selectedSubWorkspace?.subWorkSpaceName,
                  value: selectedSubWorkspace?.subWorkSpaceId,
                  role: selectedSubWorkspace?.loginUserRole,
                },
                workspace: {
                  label: selectedWorkspace?.workSpaceName,
                  value: selectedWorkspace?.workSpaceId,
                  role: getWorkspaceRole(
                    selectedWorkspace,
                    loggedInUser?.userId
                  ),
                },
              }
            }
            search={search}
            setSearch={setSearch}
            setFilters={setFilters}
            forumOptions={forumOptions}
            workspaceOptions={WorkspaceOptions}
          />
        </div>
        <div className="flex max-w-20 lg:w-full item-center justify-end">
          {(filter?.workspace?.role === "Admin" ||
            filter?.forum?.role === "Manager") && (
            <BasicButton
              id={"add-new-member"}
              color="primary"
              className={"px-4 py-2"}
              onClick={() => {
                setOpen("add");
                setProfile({
                  workSpaceId: selectedSubWorkspace?.workSpaceId,
                  subWorkSpaceId: selectedSubWorkspace?.subWorkSpaceId,
                  subworkspaceList: [
                    filter?.forum
                      ? {
                          subworkspaceId: filter?.forum?.value,
                          subworkspaceName: filter?.forum?.label,
                          role: filter?.forum?.role,
                        }
                      : {
                          subworkspaceId: selectedSubWorkspace?.subWorkSpaceId,
                          subworkspaceName:
                            selectedSubWorkspace?.subWorkSpaceName,
                          role: selectedSubWorkspace?.loginUserRole,
                        },
                  ],
                });
              }}
            >
              + Add
            </BasicButton>
          )}
        </div>
      </div>

      <TableWithPagination
        columns={columns || []}
        data={data?.usersWithRole || []}
        rowCount={data?.totalRowCount}
        pagination={pagination}
        setPagination={setPagination}
        isLoading={isLoading}
      />

      <AddEditRole
        open={!!open}
        handleClose={() => setOpen("")}
        isAddNewMember={open === "add" ? true : false}
        profile={profile}
        popupFor={"management"}
        setProfile={setProfile}
        role={filter?.workspace?.role || filter?.forum?.role}
      />
      <InfoPopup
        open={deleteId ? true : false}
        handleClose={() => setDeleteId("")}
        onClickBtn1={() => setDeleteId("")}
        onClickBtn2={handleDelete}
        loading2={isDeleteUserSucceeded}
        btn1={"cancel"}
        btn1Variant={"outline"}
        btn2={"Delete"}
        title={"Debate base"}
        description={`Are you sure you want to \n remove this user?`}
      />
    </div>
  );
}

export default RoleManagementTable;
