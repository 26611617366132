import { Typography } from "@material-tailwind/react";
import React from "react";

const DebateHeader = ({ content }) => {
  // console.log(content)
  return (
    <div className="">
      <Typography variant="h3">{content?.headLine}</Typography>
      {/* <Typography
        variant="small"
        className={`${
          content.page === 1 && "bg-primary p-2 py-4 rounded-md"
        } text-xs w-full md:w-[60%] xl:w-[40%]`}
      >
        {content?.baseLine}
      </Typography> */}
    </div>
  );
};

export default DebateHeader;
