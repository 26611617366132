import React from "react";
import Select, {components} from "react-select";

const BasicSelect = ({ Options, defaultValue, value, placeholder, loading = false, onChange, disabled = false,optionForDisable, renderMenuList }) => {

  // const CustomOption = (props) => {
  //   const { data, innerRef, innerProps } = props;
  //   return (
  //     <div
  //       id={`option-${data.id}`} // Add ID to the menu option
  //       ref={innerRef}
  //       {...innerProps}
  //       className="p-2 flex items-center gap-2"
  //     >
  //       {data.label}
  //     </div>
  //   );
  // };
  return (
    <div className="flex gap-2 items-center w-full">
      { <Select
        components={{
          IndicatorSeparator: () => null,
          Menu: (props) => <components.Menu className="!z-10"  {...props} />,
          MenuList: renderMenuList ? renderMenuList : (props) => <components.MenuList  {...props} />,
          // Option: CustomOption
        }}
        onChange={onChange}
        className="basic-single w-full"
        placeholder={placeholder}
        classNamePrefix="select"
        value={value}
        isLoading={loading}
        isClearable={false}
        isRtl={false}
        isSearchable={false}
        name="sortBy"
        options={Options}
        // noOptionsMessage={"No options available"}
        isDisabled={disabled}
        isOptionDisabled={(option) => option[optionForDisable] === false}
      />}
    </div>
  );
};

export default BasicSelect;
