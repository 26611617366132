// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkCitationAgent, suggestCitationAgent } from "../../api/agents/agentsApi";
import { useAuth } from "../../Context/AuthContext";

export const useCitationCheckAgent = (url,  debate_motion) => {
  const { user: loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["citation-validate", debate_motion,url],
    queryFn: async () => {
      return await checkCitationAgent({ debate_motion, url, user_id: loggedInUser?.userId, sessionid: loggedInUser?.sessionId });
    },
    select: (data) => data.data,
    enabled:!!url && !!debate_motion && !!loggedInUser,
  });
};
export const useCitationSuggestAgent = (debate_argument) => {
  const { user: loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["citation-suggest", debate_argument],
    queryFn: async () => {
      return await suggestCitationAgent({ debate_argument, user_id: loggedInUser?.userId, sessionid: loggedInUser?.sessionId });
    },
    select: (data) => data.data,
    enabled:!!debate_argument && !!loggedInUser,
  });
};
