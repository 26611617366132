import React from 'react'
import RoleManagementTable from '../../Components/DataTable/RoleManagementTable'
import Container from '../../Components/UI/Container'

const RoleManagment = () => {
  return (
    <Container>
        <RoleManagementTable/>
    </Container>
  )
}

export default RoleManagment