import { extractPathname } from "./Helper";

export const formatTitle = (title) => {
    return title
      .split('-') // Split the string by dashes
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with spaces
  };

export const handleApiTitle = (apiType) => {
  const [type, value] = extractPathname(apiType);
  const titleMap = {
    "debated-public": value,
    debated: value,
    private: value,
    message: value,
    "debate-results": "Debate Result",
    "debate": "Debate Details",
    "createdebated": "debated",
    notification: "notification",
    "add-colleague": "",
    "create-debate": "Create Debate",
    "suggest-motion": "Suggest a Motion",
    "find-motion": "Find a Motion",
    "profile": "Profile",
  };

  return titleMap[type] || formatTitle(apiType.slice(1));
};
  