import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import React from "react";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { SpaceDetailsTab } from "../UI/Tabs/SpaceDetailsTab";

const EditSubDebatePopup = ({
  handleClose,
  open,
  popupFor = "subworkspace",
}) => {
  const { selectedSubWorkspace, UpdateSubWorkSpace } = useSubWorkspace();
  const { selectedWorkspace, UpdateWorkspace } = useWorkspace();
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className="z-0 rounded-lg"
      size="xs"
    >
      <DialogHeader id="alert-dialog-title" className="w-[80%]">
        <span className="xs:line-clamp-1">
          {" "}
          {popupFor === "subworkspace"
            ? selectedSubWorkspace?.subWorkSpaceName
            : selectedWorkspace?.workSpaceName}
        </span>
      </DialogHeader>
      <DialogBody className="w-full p-0  rounded-lg">
        <SpaceDetailsTab
          popupFor={popupFor}
          selectedSubWorkspace={
            popupFor === "subworkspace"
              ? selectedSubWorkspace
              : selectedWorkspace
          }
          UpdateSelectedSubWorkspace={
            popupFor === "subworkspace" ? UpdateSubWorkSpace : UpdateWorkspace
          }
        />
      </DialogBody>
      <DialogFooter className="bg-gray-100 rounded-b-lg p-1">{""}</DialogFooter>
      <XMarkIcon
        className="w-6 h-6 absolute top-4 right-4 hover:text-debatePalette-title cursor-pointer"
        onClick={handleClose}
      />
    </Dialog>
  );
};

export default EditSubDebatePopup;
