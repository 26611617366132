import React, { useEffect } from "react";

const VoteScaler = ({ setScalerPoint, scalerPoint, userSideForDebate}) => {
  const getColor = () => {
    if (scalerPoint > 0) return "bg-green-500";
    if (scalerPoint === 0) return "bg-yellow-500";
    return "bg-red-500";
  };

  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    setScalerPoint(newValue);
  };

  // Adjust the scalerPoint if it falls outside the allowed range
  useEffect(() => {
    if (userSideForDebate === "agree" && scalerPoint < 1) {
      setScalerPoint(1);
    } else if (userSideForDebate === "disagree" && scalerPoint > -1) {
      setScalerPoint(-1);
    }
  }, [userSideForDebate, scalerPoint, setScalerPoint]);

  // Define the slider appearance and range based on the userSideForDebate
  const getSliderAppearance = () => {
    if (userSideForDebate === "agree") {
      return {
        min: 1,
        max: 10,
        background: "bg-gradient-to-r from-green-100 to-green-600",
        labelLeft: null,
        labelRight: "10 Agree",
      };
    } else if (userSideForDebate === "disagree") {
      return {
        min: -10,
        max: -1,
        background: "bg-gradient-to-r from-red-600 to-red-100",
        labelLeft: "10 Disagree",
        labelRight: null,
      };
    }
    return {
      min: -10,
      max: 10,
      background: "bg-gradient-to-r from-red-500 via-yellow-500 to-green-500",
      labelLeft: "10 Disagree",
      labelRight: "10 Agree",
    };
  };

  const { min, max, background, labelLeft, labelRight } = getSliderAppearance();

  return (
    <div className="flex flex-col items-center w-full max-w-xl mx-auto mt-10">
      <div className="relative w-full">
        {/* Slider */}
        <input
          type="range"
          min={min}
          max={max}
          value={scalerPoint}
          onChange={handleSliderChange}
          className={`w-full h-2 ${background} rounded-lg appearance-none cursor-pointer thumb-custom`}
        />

        <div
          className="absolute bottom-5"
          style={{
            left: `${((scalerPoint - min) / (max - min)) * 100}%`,
            transform: "translateX(-50%)",
          }}
        >
          <div
            className={`${getColor()} text-white px-2 py-1 rounded-md shadow-lg`}
          >
            {Math.abs(scalerPoint)}
          </div>
        </div>
      </div>

      {/* Labels */}
      <div className="flex justify-between w-full text-sm mt-4">
        {labelLeft && <span className="text-red-500">{labelLeft}</span>}
        {labelRight && <span className="text-green-500">{labelRight}</span>}
      </div>
    </div>
  );
};

export default VoteScaler;
