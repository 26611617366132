import { Typography } from "@material-tailwind/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { JOIN_WORKSPACE, USER_CHECK } from "../../api/apiRoutes";
import AXIOS_INSTANCE from "../../api/axiosInstance";
import { getDataINSTANCE } from "../../api/controller";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";
import { getWorkspaceByUserId } from "../../api/workspace/workspace";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { getUTCDate } from "../../Utils/Helper";

const Join = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const { selectWorkspace } = useWorkspace();
  const { selectSubWorkspace, selectWorkspaceId } = useSubWorkspace();
  const location = useLocation();
  const { search } = location;
  const [loader, setLoader] = useState(true);
  const filterDate = search?.split("&")[3]?.split("=")[1];
  const Email = search?.split("&")[2]?.split("=")[1];
  const WorkspaceId = search?.split("&")[0]?.split("=")[1];
  const SubWorkspaceId = search?.split("&")[1]?.split("=")[1];
  const loggedInUser = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : user;

  const queryClient = useQueryClient();

  const joinUser = async (body) => {
    try {
      const {
        data: { message: data },
      } = await AXIOS_INSTANCE.post(JOIN_WORKSPACE, body);
      return data;
    } catch (error) {
      throw new Error("Failed to join user");
    }
  };

  const { mutateAsync: handleJoin } = useMutation({
    mutationFn: async (body) => {
      return await joinUser(body);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["workspaces"],
        }),
      ]);
    },
  });

  useEffect(() => {
    if (filterDate) {
      const joinDate = new Date(filterDate);
      const joinDateFormat = new Date().toISOString();
      const currentDate = new Date(joinDateFormat);
      const dateDifference = currentDate - joinDate; // difference in hours
      const timeDifference = dateDifference / (1000 * 60 * 60);
      // difference in hours
      if (timeDifference > 48) {
        navigate("/invitation-expired", { replace: true });
      } else {
        handleProceed();
      }
    } // eslint-disable-next-line
  }, [filterDate]);

  const handleProceed = async () => {
    const data = await checkUser();

    if (data?.isUserExist) {
      await handleJoin({
        Email: Email,
        WorkspaceId: WorkspaceId,
        SubworkspaceId: SubWorkspaceId,
        CreatedAt: getUTCDate(),
      }).then((data) => {
        return data;
      });

      if (loggedInUser?.userId !== data?.userId) {
        logout();
        navigate(
          `/?Email=${Email}&WorkspaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`,
          { replace: true }
        );
        return;
      }

      const workspace = await getWorkspaceByUserId(data?.userId);
      const findOne = workspace?.listWorkspaces.find(
        (workspace) => workspace.workSpaceId === WorkspaceId
      );

      const subworkspace = await getSubWorkspaceByWorkId(
        WorkspaceId,
        data?.userId
      );
      const subFindOne = subworkspace?.listOfSubworkspaces.find(
        (subworkspace) => subworkspace.subWorkSpaceId === SubWorkspaceId
      );

      if (findOne && subFindOne) {
        selectWorkspace(findOne);
        selectWorkspaceId(WorkspaceId);
        selectSubWorkspace(subFindOne);
        navigate("/feed", { replace: true });
      } else {
        navigate("/createworkspace", {
          state: {
            userId: data?.userId,
            workspaceId: WorkspaceId,
            subworkspaceId: SubWorkspaceId,
          },
        });
      }
    } else {
      navigate(
        `/signup?Email=${Email}&WorkspaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`,
        { state: { Email, WorkspaceId, SubWorkspaceId } }
      );
    }

    setLoader(false);
  };

  const checkUser = async () => {
    const data = await getDataINSTANCE(
      `${USER_CHECK}?Email=${Email}&WorkSpaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`,
      "Failed to fetch Sub Workspaces Users"
    );
    return data;
  };

  return (
    <section className="h-screen flex flex-col md:flex-row items-center justify-between p-8">
      <div className="flex flex-col justify-center items-center gap-4  w-full">
        <Typography variant="h1">Hello, Welcome to Debatebase</Typography>
        {loader ? <LoadingSpinner /> : null}
      </div>
    </section>
  );
};

export default Join;
