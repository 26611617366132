import React, { Fragment, useState } from "react";
import NoData from "../../Components/Functionality/NoData";
import ActiveCard from "../../Components/UI/Cards/ActiveCard";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import useDebounce from "../../Services/useDebounce";
import { useGetLostDebates } from "../../Web-Hooks/Profile/use-profile";
import SearchFilter from "./SearchFilter";

const DebateLostList = () => {
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 1000);
  const { selectedSubWorkspace, subWorkspaceList } = useSubWorkspace();
  const [filter, setFilters] = useState(null);
  const { user: loggedInUser } = useAuth();
  const subId = filter
    ? filter?.forum?.label !== "All forum"
      ? filter?.forum?.value
      : ""
    : selectedSubWorkspace?.subWorkSpaceId;
  const { data: LostList, isLoading: isLostListLoading } = useGetLostDebates(loggedInUser?.userId, subId, debouncedSearchTerm);
  const forumOptions =
    subWorkspaceList?.map((subWorkspace) => {
      return {
        label: subWorkspace.subWorkSpaceName,
        value: subWorkspace.subWorkSpaceId,
      };
    }) || [];

  return (
    <Fragment>
      <div className="h-full flex flex-col gap-5">
        <div className="h-auto flex gap-4 justify-between w-full">
          {" "}
          <SearchFilter
            search={search}
            setSearch={setSearch}
            filters={
              filter || {
                forum: {
                  label: selectedSubWorkspace?.subWorkSpaceName,
                  value: selectedSubWorkspace?.subWorkSpaceId,
                },
              }
            }
            setFilters={setFilters}
            showFilters={["search", "forum"]}
            forumOptions={[...forumOptions]}
          />
        </div>
        {isLostListLoading ? (
          RenderLoaderSkeleton(3, "motion")
        ) : LostList?.length === 0 ? (
          <NoData
            isNoData={true}
            message={"Your have not lost any debate yet"}
            size={"w-full h-[80%]"}
          />
        ) : (
          <div className="flex flex-col gap-4 h-full overflow-y-auto">
            {LostList?.map((lost) => (
              <ActiveCard post={lost} key={lost?.debateId}  />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default DebateLostList;
