import { Button } from "@material-tailwind/react";
import React from "react";

const SaveDebateFunctionality = ({
  loading,
  handlePublish,
}) => {

  return (
    <Button
      variant="filled"
      className="bg-debatePalette-shadow text-base px-6 py-1.5 normal-case font-normal cursor-pointer text-debatePalette-background"
      onClick={handlePublish}
      loading={loading}
    >
      {"Save"}
      {/* Save */}
    </Button>
  );
};

export default SaveDebateFunctionality;
