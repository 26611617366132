import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Typography,
} from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import InputField from "../../Layout/InputField";
import { getInitials } from "../../Utils/Helper";
import { useUpdateUser } from "../../Web-Hooks/Profile/use-profile";
import UserAvtarUpload from "../UI/AvatarStack/UserAvtarUpload";

const AddUserDetailsPopup = ({ open, user, isFetching, handleClose }) => {
  const [picture, setPicture] = useState(null);
  const [profileDetail, setProfileDetails] = useState(() => {
    return {
      userId: user?.userId,
      website: user?.website,
      email: user?.email,
      about: user?.about,
      userName: user?.userName,
      userImage: user?.userImage,
      initials: getInitials(user?.userName || ""),
    };
  });
  useEffect(() => {
    setProfileDetails({
      userId: user?.userId,
      website: user?.website,
      email: user?.email,
      about: user?.about,
      userName: user?.userName,
      userImage:  user?.userImage,
      initials: getInitials(user?.userName || ""),
    });
  }, [user]);

  const { mutateAsync: updateUser, isPending: isUpdatePending } =
    useUpdateUser();

  const handleClick = async () => {
    const formData = new FormData();
    formData.append("userId", profileDetail.userId);
    formData.append("website", profileDetail.website);
    formData.append("email", profileDetail.email);
    formData.append("about", profileDetail.about);
    formData.append("userName", profileDetail.userName);
    formData.append("UserImage", picture);
    const res = await updateUser(formData);
    handleClose();
    return res;
  };


  const handleChange = (event) => {
    const { name, value } = event.target;
    setProfileDetails((prev) => ({ ...prev, [name]: value }));
  };

  const updateUserImage = (file, url) => {
    setProfileDetails((prevValues) => ({
      ...prevValues,
      userImage: url,
    }));
    setPicture(file);
  };

  return (
    <Dialog open={open}>
      <DialogHeader className="flex flex-col items-start gap-2">
        <Typography className="text-debatePalette-title" variant="h2">
          Add Profile Details
        </Typography>
        <Typography className="text-debatePalette-timer" variant="h6">
          Please fill required fields and save to proceed further
        </Typography>
      </DialogHeader>
      <DialogBody>
          <form className="grid md:grid-cols-2 gap-4">
            <div className=" px-4 md:col-span-2 border-debatePalette-bodyText flex flex-col py-4 gap-2 items-center ">
              <UserAvtarUpload setPicture={updateUserImage} picture={profileDetail?.userImage} userName={profileDetail?.userName} />
              {/* <ImageCropper
                open={resizePopup}
                setOpen={setResizePopup}
                setSrc={handleSrc}
                src={profileDetail?.userImage}
                email={profileDetail?.email}
                setPicture={setPicture}
                file={picture}
              />{" "}
              <p
                onClick={() => imageRef.current.click()}
                className="text-debatePalette-shadow cursor-pointer"
              >
                Change Profile Photo
              </p>
              <input
                className="hidden"
                id="userImage"
                type="file"
                accept="image/*"
                ref={imageRef}
                onClick={(e) => (e.target.value = null)}
                onChange={(e) => handlePicture(e)}
              /> */}
            </div>
            <InputField
              required={true}
              FieldName={"Username"}
              inputName="userName"
              value={profileDetail?.userName}
              onChange={handleChange}
              placeholder="Username"
            />
            <InputField
              required={true}
              FieldName={"Email"}
              disable={true}
              inputName="email"
              placeholder="Email"
              value={profileDetail?.email}
              onChange={handleChange}
            />
          </form>
      </DialogBody>
      <DialogFooter className="flex justify-center">
        <Button
          className="bg-primary hover:opacity-75 hover:bg-primary text-debatePalette-title"
          variant="text"
          disabled={!profileDetail?.userName}
          loading={isUpdatePending || isFetching}
          onClick={() => handleClick(profileDetail)}
        >
          Save
        </Button>
      </DialogFooter>
    </Dialog>
  );
};

export default AddUserDetailsPopup;
