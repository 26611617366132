import {
  Tab,
  TabPanel,
  Tabs,
  TabsBody,
  TabsHeader,
} from "@material-tailwind/react";
import React, { useState } from "react";
import AboutDebate from "../../../Pages/Public/AboutDebate";
import MemberList from "../../Listing/MemberList";
import { useGetWorkspaceUserByWorkId } from "../../../Web-Hooks/Workspace/use-workspace";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";

export function SpaceDetailsTab({ popupFor }) {
  const [activeTab, setActiveTab] = useState("members");
  const { SubWorkspaceUser } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { data: workspaceUsers } = useGetWorkspaceUserByWorkId(
    selectedWorkspace.workSpaceId
  );
  const USERS = popupFor === "subworkspace" ? SubWorkspaceUser : workspaceUsers;
  const memberLength = USERS?.length;

  const data = [
    {
      label: "About",
      value: "about",
    },
    {
      label: "Members",
      value: "members",
      length: memberLength,
    },
  ];

  return (
    <Tabs value={activeTab}>
      <TabsHeader
        className="rounded-none border-b px-2 py-0 md:w-2/4 w-full border-blue-gray-50 bg-transparent"
        indicatorProps={{
          className:
            "bg-transparent border-b-2 border-gray-900 shadow-none rounded-none",
        }}
      >
        {data.map(({ label, value, length }) => (
          <Tab
            key={value}
            value={value}
            onClick={() => setActiveTab(value)}
            className={activeTab === value ? "text-debatePalette-title" : "text-debatePalette-bodyText"}
          >
            <div className="flex gap-2 text-sm font-semibold ">
              <span>{label}</span>
              {length && <span>{length}</span>}
            </div>
          </Tab>
        ))}
      </TabsHeader>
      <TabsBody className="p-0 h-80">
        <TabPanel key={"about"} value={"about"} className="bg-[#FAFAFA] h-full">
          <AboutDebate popupFor={popupFor} />
        </TabPanel>
        <TabPanel
          key={"members"}
          value={"members"}
          className="bg-gray-100 h-full"
        >
          <MemberList popupFor={popupFor} />
        </TabPanel>
      </TabsBody>
    </Tabs>
  );
}
