import { Button } from "@material-tailwind/react";
import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import NoData from "../../../Components/Functionality/NoData";
import LoadingSpinner from "../../../Components/UI/LoadingSpinner";
import { useAuth } from "../../../Context/AuthContext";
import { useSocket } from "../../../Context/SocketContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { isTodayOrYesterday } from "../../../Utils/Helper";
import { useGetGroupChatHistory } from "../../../Web-Hooks/Chat/use-group-chat";
import TitleDivider from "../TitleDivider";
import GroupMessageCard from "./GroupMessageCard";
import { useGetGroupUsersList } from "../../../Web-Hooks/Chat/chat";

const GroupMessageScreen = ({ group }) => {
  const messagesEndRef = useRef(null);

  const { user: loggedInUser } = useAuth();
  const [chat, setChat] = useState([]);
  const [typeState, setTypeState] = useState(false);
  const { socket, sendMessage } = useSocket();
  const [size] = useState(10);
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();

  const { data: chatHistory, isLoading: isChatLoading } =
    useGetGroupChatHistory(group?.id, 1, size);

    const { data: groupMembers } = useGetGroupUsersList(
      group?.id,
      true,
      (data) => data.data
    );

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: "auto" });
  }, [chat, typeState]);

  useEffect(() => {
    const history = chatHistory;
    setChat(history || []);

    return () => {
      setChat([]);
    };
  }, [chatHistory, loggedInUser]);

  useEffect(() => {
    if (!socket) return;

    const handleNewGroupMessage = (data) => {
      if (data.messages[0].groupId === group?.id) {
        setChat((prevChat) => [...prevChat, data]);
      }
    };

    socket.on("gettingNewGroupMessage", handleNewGroupMessage);

    return () => {
      socket.off("gettingNewGroupMessage", handleNewGroupMessage);
    };
  }, [socket, group]);

  console.log(chat)

  useEffect(() => {
    if (!socket) return;
    const handleReadAllMessages = (groupId) => {
      
      if (groupId === group?.id) {
        socket.emit("readAllMessages", {
          groupId: group?.id,
          userId: loggedInUser?.userId,
        });
      }
    };
    socket.on("readNewMessage", handleReadAllMessages);

    return () => {
      socket.off("readNewMessage", handleReadAllMessages);
    };
  }, [socket, group, loggedInUser]);

  useEffect(() => {
    if (!socket) return;
  
    const handleReadUIUpdate = ({ readByUsers, groupId }) => {
      if (groupId === group?.id) {
        setChat((prevChat) =>
          prevChat.map((message, index) => {
            // Check if all group users are in the readByUsers array
            const allUsersRead = group.users.every((userId) => readByUsers.includes(userId));
            
            if (allUsersRead) {
              // Update readBy for all messages in the current group
              return {
                messageGroup: message.messageGroup,
                messages: message.messages.map((msg) => ({
                  ...msg,
                  readBy: readByUsers,
                })),
              };
            } else if (index === prevChat.length - 1) {
              // Update only the last message if not all users have read
              return {
                messageGroup: message.messageGroup,
                messages: message.messages.map((msg, msgIndex) => {
                  if (msgIndex === message.messages.length - 1) {
                    return {
                      ...msg,
                      readBy: readByUsers,
                    };
                  }
                  return msg;
                }),
              };
            }
            return message;
          })
        );
      }
    };
  
    socket.on("readByUsers", handleReadUIUpdate);
  
    return () => {
      socket.off("readByUsers", handleReadUIUpdate);
    };
  }, [socket, group, loggedInUser]);
  


  const handleSend = () => {
    const Message = {
      type: "text",
      content: {
        text: "Hey there",
      },
    };

    sendMessage(
      loggedInUser?.userId,
      group?.id,
      Message,
      selectedWorkspace?.workSpaceId,
      selectedSubWorkspace?.subWorkSpaceId
    );
  };

  if (isChatLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="h-full w-full overflow-y-auto  pb-3 px-6">
      <section
        className={`${
          chat?.length > 0 ? "min-h-full" : "h-full"
        } w-full flex flex-col justify-end`}
      >
        {chat?.length > 0 ? (
          chat?.map((message, index) => (
            <React.Fragment key={index}>
              {message?.messageGroup && (
                <TitleDivider
                  title={isTodayOrYesterday(message?.messageGroup)}
                />
              )}

              {message?.messages?.map((message, index) => (
                <GroupMessageCard
                  group={group}
                  members={groupMembers}
                  message={message}
                  key={message.id}
                />
              ))}
            </React.Fragment>
          ))
        ) : (
          <React.Fragment key="hello">
            <div className="my-8 h-full">
              <NoData
                size={"h-[80%] w-full"}
                children={
                  <Button
                    className="bg-secondary shrink-0"
                    onClick={handleSend}
                  >
                    Say Hello
                  </Button>
                }
                message="No messages yet"
              />{" "}
            </div>
          </React.Fragment>
        )}

        {typeState.isTyping && typeState.userId === group?.userId && (
          <div className="flex items-end justify-start gap-4 my-2">
            {/* <span className="text-md font-semibold text-secondary">
              <span className="capitalize">{user?.username}</span> is typing
            </span> */}
            <ThreeDots
              visible={true}
              height={"20px"}
              width={"20px"}
              color={"#153C58"}
              radius="9"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        )}
      </section>
      <div ref={messagesEndRef} />
    </div>
  );
};

export default GroupMessageScreen;
