import {
  AI_SESSION_LOGIN,
  AI_SESSION_LOGOUT,
  ARGUMENT_ASSISTANCE,
  CATEGORIES_ASSISTANCE,
  CATEGORIES_SUGGESTION_AGENT,
  CITATION_SUGGESTION_AGENT,
  CITATION_VALIDATOR_AGENT,
  DEBATE_SUMMARIZATION,
  GLOBAL_TOPIC_SUGGESTIONS,
  MOTION_ASSISTANCE,
} from "../apiRoutes";
import AXIOS_INSTANCE_AGENT from "../axiosInstanceAgent";

export const topicSuggestionAI = async (debate_topic) => {
  try {
    const { data } = await AXIOS_INSTANCE_AGENT.post(
      `${GLOBAL_TOPIC_SUGGESTIONS}`,
      debate_topic
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch hot topics");
  }
};
// Add category for user
export const checkArgumentAgent = async (requestBody) => {
  try {
    const data = await AXIOS_INSTANCE_AGENT.post(
      ARGUMENT_ASSISTANCE,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
};
export const checkCategoryAgent = async (requestBody) => {
  try {
    const data = await AXIOS_INSTANCE_AGENT.post(
      CATEGORIES_ASSISTANCE,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
};

export const checkMotionAgent = async (requestBody) => {
  try {
    const data = await AXIOS_INSTANCE_AGENT.post(
      MOTION_ASSISTANCE,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
};
export const checkCitationAgent = async (requestBody) => {
  try {
    const data = await AXIOS_INSTANCE_AGENT.post(
      CITATION_VALIDATOR_AGENT,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
};
export const suggestCitationAgent = async (requestBody) => {
  try {

    const data = await AXIOS_INSTANCE_AGENT.post(
      CITATION_SUGGESTION_AGENT,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch private debate list");
  }
};
export const suggestCategoryAgent = async (debate_topic) => {
  try {
    const data = await AXIOS_INSTANCE_AGENT.post(
      CATEGORIES_SUGGESTION_AGENT,
      debate_topic
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch debate category");
  }
};
export const debateSummary = async (debateId) => {
  try {
    const requestBody = debateId;

    const data = await AXIOS_INSTANCE_AGENT.post(
      DEBATE_SUMMARIZATION,
      requestBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch debate category");
  }
};

export const agentSessionLogin = async (session) => {
  try {
    const data = await AXIOS_INSTANCE_AGENT.post(AI_SESSION_LOGIN, session);
    return data;
  } catch (error) {
    throw new Error("Failed to start session");
  }
};
export const agentSessionLogout = async (session) => {
  try {
    const data = await AXIOS_INSTANCE_AGENT.post(AI_SESSION_LOGOUT, session);
    return data;
  } catch (error) {
    throw new Error("Failed to logout start session");
  }
};
