import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { ReactComponent as CitationDevice } from "../../Assets/Svg/CitationDevice.svg";
import { ReactComponent as CitationExternal } from "../../Assets/Svg/CitationExternal.svg";
import { ReactComponent as CitationLibrary } from "../../Assets/Svg/CitationLibrary.svg";
import CitationPopup from "../Popup/CitationPopup";
import ImageCropperSRP from "./ImageCropperSRP";
import SelectFromLibraryPopup from "../Popup/SelectFromLibraryPopup";
import SelectCitationFromMotionList from "../Popup/SelectCitationFromMotionList";

const CitationComponent = ({
  MenuHandlerComponent,
  heading = "Add citation",
  handleCitationSubmit = async () => {
    // console.log("Citation Submitted");
  },
  isLoadingFromHandleSubmit,
  from = ["gallary", "device", "external"],
  query,
  menuRef,
}) => {
  const [selectedCitation, setSelectedCitation] = useState({
    citationUrl: "",
    citationNote: "",
    citationType: "",
    citationFile: null,
  });
  const inputRef = useRef(null);
  const [searchQuery] = useSearchParams();
  const [citationPopup, setCitationPopup] = useState(false);
  const [isFromExternal, setIsFromExternal] = useState(false);
  const [imageCropperPopup, setImageCropperPopup] = useState(false);
  const [selectFromLibraryPopup, setSelectFromLibraryPopup] = useState(false);
  const [selectFromMotionList, setSelectFromMotionList] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const Options = [
    {
      id: 1,
      SVG: CitationLibrary,
      label: "Add From Motion List",
      value: "addFromMotionList",
      tag: "motion",
      handleClick: () => {
        // localStorage.setItem("Citation", JSON.stringify(citation));
        // localStorage.setItem("Debate", JSON.stringify(debateDetails));
        // navigate(`/library?${query}&prevPathname=${pathname}`);
        // return;
        setSelectFromMotionList(true);
      },
    },
    {
      id: 2,
      SVG: CitationLibrary,
      label: "Add From Your Library",
      value: "addFromLibrary",
      tag: "library",
      handleClick: () => {
        // localStorage.setItem("Citation", JSON.stringify(citation));
        // localStorage.setItem("Debate", JSON.stringify(debateDetails));
        // navigate(`/library?${query}&prevPathname=${pathname}`);
        // return;
        navigate(`${pathname}?${searchQuery}&${query}`);
        setSelectFromLibraryPopup(true);
      },
    },
    {
      id: 3,
      SVG: CitationLibrary,
      label: `${heading === "Add Photo" ? "Add From Your Device" : "Add From Your Gallary"}`,
      value: "addFromGallary",
      tag: "gallary",

      handleClick: async () => {
        inputRef.current.click();
        // const input = document.createElement("input");
        // input.type = "file";
        // input.accept = "image/*";
        // input.click();
        // await new Promise((resolve) => {
        //   input.onchange = (e) => {
        //     resolve(e.target.files[0]);
        //     if (e.target.files[0]) {
        //       const url = URL.createObjectURL(e.target.files[0]);
        //       setSelectedCitation((prev) => ({
        //         ...prev,
        //         citationUrl: url,
        //         citationType: "image",
        //         citationFile: e.target.files[0],
        //         citationFrom: "gallary",
        //       }));
        //       setImageCropperPopup(true);
        //     }
        //   };
        // });
      },
    },
    {
      id: 4,
      SVG: CitationDevice,
      label: "Add From Your Device Source",
      value: "addFromDevice",
      tag: "device",

      handleClick: async () => {
        const input = document.createElement("input");
        input.type = "file";
        input.accept = `video/mp4,.doc,.docx,.pdf`; // Added image/* for all image types
        input.click();
        await new Promise((resolve) => {
          input.onchange = (e) => {
            resolve(e.target.files[0]);
            if (e.target.files[0]) {
              setSelectedCitation((prev) => ({
                ...prev,
                citationTitle: e.target.files[0].name,
                citationUrl: e.target.files[0].name,
                citationType: e.target.files[0].name.split(".")?.pop(),
                citationFile: e.target.files[0],
                citationFrom: "device",
              }));
              setCitationPopup(true);
            }
          };
        });
      },
    },
    {
      id: 5,
      SVG: CitationExternal,
      label: "Add From External Source",
      value: "addFromExternal",
      tag: "external",

      handleClick: () => {
        setSelectedCitation((prev) => ({
          ...prev,
          citationUrl: "",
          citationType: "",
          citationFile: null,
          citationFrom: "external",
        }));
        setCitationPopup(true);
        setIsFromExternal(true);
      },
    },
  ];

  const FilteredOptions = Options.filter((option) => from.includes(option.tag));

  return (
    <>
      <SelectCitationFromMotionList
        setCitationPopup={setCitationPopup}
        setSelectedCitation={setSelectedCitation}
        motionPopup={selectFromMotionList}
        setMotionPopup={setSelectFromMotionList}
      />
      <SelectFromLibraryPopup
        setCitationPopup={setCitationPopup}
        setSelectedCitation={setSelectedCitation}
        libraryPopup={selectFromLibraryPopup}
        setLibraryPopup={setSelectFromLibraryPopup}
        fromTO={heading}
        handleSubmit={(citation) =>
          handleCitationSubmit({
            ...selectedCitation,
            ...citation
          })
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              setCitationPopup(false);
              setIsFromExternal(false);
              setSelectedCitation({
                citationUrl: "",
                citationNote: "",
                citationType: "",
                citationFile: null,
              });
            })
        }
      />
      {citationPopup && (
        <CitationPopup
          heading={heading}
          setSelectedCitation={setSelectedCitation}
          popupOpen={citationPopup}
          selectedCitation={selectedCitation}
          setPopupOpen={(pop) => {
            setCitationPopup(pop);
            setIsFromExternal(pop);
          }}
          handleSubmit={() => {
            handleCitationSubmit(selectedCitation)
              .then((res) => {
                // console.log(res);
              })
              .catch((err) => {
                console.log(err);
              })
              .finally(() => {
                setCitationPopup(false);
                setIsFromExternal(false);
                setSelectedCitation({
                  citationUrl: "",
                  citationNote: "",
                  citationType: "",
                  citationFile: null,
                });
              });
          }}
          isFromExternalCitation={isFromExternal}
          isUploadCitationPending={isLoadingFromHandleSubmit}
        />
      )}
      <ImageCropperSRP
        inputRef={inputRef}
        setModalOpen={setImageCropperPopup}
        modalOpen={imageCropperPopup}
        maxCropSize={500}
        type={`${heading === "Add Photo" || heading === "Add citation" ? "debate" : "avatar"}`}
        updateAvatar={(file, url) => {
          setSelectedCitation({
            ...selectedCitation,
            citationTitle: file.name,
            citationUrl: url,
            citationType: "image",
            citationFile: file,
            citationFrom: "gallary",
            citationNote: "image",
          });
          heading !== "Add Photo"
            ? setCitationPopup(true)
            : handleCitationSubmit({
                ...selectedCitation,
                citationTitle: file.name,
                citationUrl: url,
                citationType: "image",
                citationFile: file,
                citationFrom: "gallary",
                citationNote: "image",
              })
                .then((res) => {
                  // console.log(res);
                })
                .catch((err) => {
                  console.log(err);
                })
                .finally(() => {
                  setCitationPopup(false);
                  setIsFromExternal(false);
                  setSelectedCitation({
                    citationUrl: "",
                    citationNote: "",
                    citationType: "",
                    citationFile: null,
                  });
                });
        }}
        closeModal={() => setImageCropperPopup(false)}
        resetState={() => {
          setSelectedCitation({
            citationUrl: "",
            citationNote: "",
            citationType: "",
            citationFile: null,
          });
          setCitationPopup(false);
          setIsFromExternal(false);
        }}

        // handleCropClick={() => {
        //   setSelectedCitation({
        //     ...selectedCitation,
        //     citationUrl: selectedCitation.citationFile.name,
        //   });
        //   if (heading !== "Add Photo") {
        //     setCitationPopup(true);
        //     return;
        //   }
        //   handleCitationSubmit(selectedCitation);
        //   setCitationPopup(false);
        //   setIsFromExternal(false);
        //   setSelectedCitation({
        //     citationUrl: "",
        //     citationNote: "",
        //     citationType: "",
        //     citationFile: null,
        //   });
        // }}
      />
      <Menu ref={menuRef}>
        <MenuHandler>{MenuHandlerComponent}</MenuHandler>
        <MenuList className="min-w-40 bg-secondary text-debatePalette-main !z-10000000">
          {heading && (
            <div className="flex items-center p-1">
              <p className="text-sm w-full text-center text-debatePalette-background">
                {heading}
              </p>
            </div>
          )}
          {FilteredOptions?.length > 0 ? (
            FilteredOptions.map((option) => (
              <MenuItem
                id={`${option.id}-${option.label}`}
                key={option.id}
                className="flex items-center p-1 justify-start gap-3 min-w-40 z-0"
                onClick={option.handleClick}
              >
                {option.SVG && <option.SVG className="h-4 w-4" />}
                <p className="text-md font-semibold text-inherit py-2">
                  {option.label}
                </p>
              </MenuItem>
            ))
          ) : (
            <MenuItem>No Options</MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};

export default CitationComponent;
