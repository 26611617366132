import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  SaveDebate,
  UpdateLikeToDebate,
  UpdateVoteToDebate,
} from "../../api/debate/debate";
import { useLocation } from "react-router-dom";

export const useAddVoteToDebate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await UpdateVoteToDebate(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-voted-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-voted-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["voted-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useAddLikeToDebate = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (Debate) => {
      return await UpdateLikeToDebate(Debate);
    },
    onSuccess: () =>
      Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["sub-workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["workspace-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debate-details-page"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["recent-semi-debated-users"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["public-debated-workspace"],
        }),
        queryClient.invalidateQueries({
          queryKey: ["debated-subworkspace"],
        }),
      ]),
    onError: (error) => {
      console.log(error);
    },
  });
};
export const useSaveDebate = () => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const invalidateOnSave = [
    {
      queryKey: "search-debates",
      path: "search",
    },
    {
      queryKey: "other-user-debates",
      path: "profile",
    },
    {
      queryKey: "debate-details-page",
      path: "/debate/",
    },
    {
      queryKey: "public-feed-workspace",
      path: "feed-public",
    },
    {
      queryKey: "public-debated-workspace",
      path: "debated-public",
    },
    {
      queryKey: "public-voted-workspace",
      path: "voted-public",
    },
    {
      queryKey: "feed-subworkspace",
      path: "feed",
    },
    {
      queryKey: "debated-subworkspace",
      path: "debated",
    },
    {
      queryKey: "voted-subworkspace",
      path: "voted",
    },
    {
      queryKey: "private-subworkspace",
      path: "private",
    },
  ];

  const handleApiUpdate = () => {
    const isPathIncluded = invalidateOnSave.find((item) =>
      pathname.includes(item.path)
    );
    if (isPathIncluded) {
      queryClient.invalidateQueries({
        queryKey: [isPathIncluded.queryKey],
      });
    }
  };
  return useMutation({
    mutationFn: async (Debate) => {
      return await SaveDebate(Debate);
    },
    onSuccess: async() => handleApiUpdate(),
    onError: (error) => {
      console.log(error);
    },
  });
};
