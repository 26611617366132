import { Checkbox } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DebateHeader from "../../../Components/Functionality/DebateHeader";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import SelectFutureDebateOptionPopup from "../../../Components/Popup/SelectFutureDebateOptionPopup";
import BasicButton from "../../../Components/UI/Buttons/BasicButton";
import SearchField from "../../../Components/UI/Inputs/SearchField";
import LoadingSpinner from "../../../Components/UI/LoadingSpinner";
import { useAuth } from "../../../Context/AuthContext";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import useDebounce from "../../../Services/useDebounce";
import { prepareFormData } from "../../../Utils/Helper";

const SelectViewer = ({ handleBack}) => {
  const { debateInfo, viewers, setViewers, handleDebateAndCitationAPI,isAddCitationPending,isAddDebatePending,isUpdateRedebateCountPending } =
    useCreateDebateContext();
  const { selectedSubWorkspace, SubWorkspaceUser, SubWorkspaceUserLoading } =
    useSubWorkspace();
  const [contacts, setContacts] = useState([]);
  const [Publish, setPublish] = useState(false);
  const { user } = useAuth();
  const [selectedTerms, setSelectedTerms] = useState(null);
  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 300);
  const [Users, setUsers] = useState(() => SubWorkspaceUser);
  const [futureAction, setFutureAction] = useState(null);
  const location = useLocation();
  const { search: searchParams } = location;
  const query = new URLSearchParams(searchParams);
  const from = query?.get("from");
  const debateId = query?.get("debateId");
  const content = {
    headLine: "Invite Viewers",
    baseLine: "",
    pageName: "InviteViewers",
  };

  const handlePublish = async () => {
    const viewersIds = viewers.map((member) => member.userId).join(";");

    let defaultData = prepareFormData(
      debateInfo,
      selectedSubWorkspace,
      user,
      false,
      futureAction
    );

    const formData = {
      ...defaultData,
      InvitedUserId: viewersIds,
      IsPrivateNotOpen: selectedTerms === "notOpenDebate" ? true : false,
    };

    handleDebateAndCitationAPI(formData, false, storeId, () => handleSubmit());
  };

  const storeId = from === "Redebate" ? user?.userId : debateId ? debateId : user?.userId 

  const handleSubmit = () => {
    setPublish(false);
      setSelectedTerms(null);
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
  }

  const handleChangeContacts = (value) => {
    const index = contacts.findIndex(
      (contact) => contact.label === value.email
    );
    const newContact = {
      label: value.email,
      value: value.userId,
    };
    if (index === -1) {
      setContacts([...contacts, newContact]);
      setViewers([...viewers, value]);
    } else {
      const updatedContacts = [...contacts];
      updatedContacts.splice(index, 1);
      setContacts(updatedContacts);
      setViewers(updatedContacts);
    }
  };

  useEffect(() => {
    if (!debouncedSearchTerm) {
      setUsers(() => SubWorkspaceUser);
      const newContacts = viewers?.map((user) => ({
        label: user.email,
        value: user.userId,
      }));
      setContacts(newContacts);
      return;
    }
    const search = debouncedSearchTerm.toLowerCase();
    Users?.length &&
      setUsers(() =>
        SubWorkspaceUser?.filter((user) => user?.email.includes(search))
      ); // eslint-disable-next-line
  }, [debouncedSearchTerm]);

  return (
    <div>
      <DebateHeader content={content} />

          <div className="w-full my-2">
            <SearchField
              id="search-invite-viewer"
              name="search-invite-viewer"
              search={search}
              setSearch={setSearch}
              containerClass={"!w-full"}
            />
          </div>
          <div className="w-full border p-3 mt-2 border-debatePalette-buttonBorder rounded-md">
            <div className="flex flex-col h-96">
              {SubWorkspaceUserLoading ? (
                <LoadingSpinner color={"blue"} />
              ) : (
                <form className="flex flex-col overflow-y-auto member-list  items-start gap-2">
                  {Users?.map(
                    (member) =>
                      member.isAccept &&
                      !member?.isUserBlock &&
                      member?.userId !== debateInfo?.NominateUserId && (
                        <Checkbox
                          key={member?.email}
                          containerProps={{
                            className: "!flex items-center gap-4",
                          }}
                          checked={contacts
                            ?.map((contact) => contact?.label)
                            .includes(member?.email)}
                          onChange={() => handleChangeContacts(member)}
                          id={member?.email}
                          color="blue"
                          ripple={false}
                          className="w-4 h-4 rounded-none before:content-none"
                          label={
                            <div className="flex items-center gap-2 flex-1 w-full truncate">
                              {
                                <ImageOrInitials
                                  imageSrc={member?.userImage}
                                  initials={member?.userName || member?.email}
                                  size={"h-10 w-10"}
                                  classNameInitials={"shadow-none"}
                                />
                              }
                              <p className="text-md first-letter:capitalize lowercase">
                                {member?.userName ||
                                  member?.email?.replace(/\s+/g, "")}
                              </p>
                            </div>
                          }
                          labelProps={{
                            className:
                              "text-debatePalette-title w-full text-md font-semibold capitalize",
                          }}
                        />
                      )
                  )}
                </form>
              )}
            </div>
          </div>

          <div className="flex flex-col mt-5 sm:flex-row items-center justify-between w-full ">
            <BasicButton
              color={"primary"}
              className={`w-24 flex justify-center rounded-md capitalize text-sm px-4 py-2`}
              onClick={() => handleBack()}
            >
              {"Back"}
            </BasicButton>
            <BasicButton
              loading={isAddCitationPending || isAddDebatePending}
              color={"primary"}
              // isDisable={viewers?.length ? false : true}
              className={`w-24 flex justify-center rounded-md capitalize text-sm px-4 py-2`}
              onClick={() => setPublish(true)}
            >
              {"Invite"}
            </BasicButton>
          </div>

      {/* <EditableBodyPopup
        open={Publish}
        handleClose={() => {
          setSelectedTerms(null);
          setPublish(false);
        }}
        title={
          <div className="flex items-center gap-3">
            <Alert className="h-8 w-8" />
            <span className="text-2xl font-semibold text-debatePalette-timer">
              Alert!!!
            </span>
          </div>
        }
        loading={isAddCitationPending || isAddDebatePending}
        btn1={"No"}
        btn2={"Yes"}
        onClickBtn1={() => {
          setSelectedTerms(null);
          setPublish(false);
        }}
        onClickBtn2={handlePublish}
        disableBtn={selectedTerms ? false : true}
        children={
          <div
            id="info-popup-description"
            className={`flex flex-col gap-4 p-4 border text-wrap text-lg font-semibold text-debatePalette-title rounded-md border-debatePalette-shadow w-auto ${shadowBlue}`}
          >
            <Radio
              color="blue"
              name="openDebate"
              label="If your debate will not be accepted by opponent within 24 hours, it should be open debate"
              labelProps={{
                className: "text-wrap w-full",
              }}
              onChange={() => {
                setSelectedTerms("openDebate");
              }}
            />
            <Radio
              color="blue"
              name="openDebate"
              label="Do not make it open debate"
              labelProps={{
                className: "text-wrap w-full",
              }}
              onChange={() => {
                setSelectedTerms("notOpenDebate");
              }}
            />
          </div>
        }
      /> */}
      <SelectFutureDebateOptionPopup
        setFutureAction={setFutureAction}
        futureAction={futureAction}
        futureActionPopup={Publish}
        setFutureActionPopup={setPublish}
        handleClose={() => {
          setPublish(false);
          setFutureAction(null);
        }}
        loading={
          isAddCitationPending ||
          isAddDebatePending ||
          isUpdateRedebateCountPending
        }
        handleSubmission={handlePublish}
      />
    </div>
  );
};

export default SelectViewer;
