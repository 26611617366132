import { HeartIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "@material-tailwind/react";
import React, { Fragment } from "react";
import toast from "react-hot-toast";
import { ReactComponent as FilledHeart } from "../../../Assets/Svg/FilledHeart.svg";
import { ReactComponent as FilledLike } from "../../../Assets/Svg/FilledLike.svg";
import { ReactComponent as Like } from "../../../Assets/Svg/Like.svg";
import { ReactComponent as Share } from "../../../Assets/Svg/share.svg";
import { useAuth } from "../../../Context/AuthContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { getUTCDate } from "../../../Utils/Helper";
import {
  useAddLikeToDebate,
  useAddVoteToDebate,
} from "../../../Web-Hooks/Debate/use-debate-actions";

const DebateActions = ({
  debate,
  className,
  isLoading,
  toolcontent,
  istooltip,
}) => {
  const { user: loggedInUser } = useAuth();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { mutateAsync: addVote, isPending: isVotePending } = useAddVoteToDebate();
  const { mutateAsync: addLike, isPending: isLikePending } = useAddLikeToDebate();

  const handleVoteClick = async () => {

    const VoteObj = {
      DebateId: debate?.debateId,
      VoteToUserId: debate?.userId,
      VotedBy: loggedInUser?.userId,
      Round: debate?.debateType === "eonian" ? 0 : debate?.isOtherUserAllow ? 1 : debate?.selectedRound,
      SubWorkSpaceId:
        debate?.subworkspaceId || selectedSubWorkspace?.subWorkSpaceId,
      CreatedAt: getUTCDate(),
      isVote: !debate?.isLoginUserVoted,
      ...(debate?.invitationId && { InvitationId: debate.invitationId }), // Only include if InvitationId is defined
      ...(debate?.argumentId && { ArgumentId: debate.argumentId }) 
    };

    if (Object.values(VoteObj).some((value) => value === null)) {
      toast.error("Failed to vote. Please try again later.");
      return;
    }

    try {
      const res = await addVote(VoteObj);
      if (res?.errorMessage) {
        toast.error("Failed to vote. Please try again later.");
      }
    } catch (error) {
      toast.error("Failed to vote. Please try again later.");
    }
  };

  const handleLikeClick = async () => {

    const LikeObj = {
      DebateId: debate?.debateId,
      LikedToUserId: debate?.userId,
      LikedBy: loggedInUser?.userId,
      SubWorkSpaceId:
        debate?.subworkspaceId || selectedSubWorkspace?.subWorkSpaceId,
      // Side: "creator",
      Round: debate?.debateType === "eonian" ? 0 : debate?.isOtherUserAllow ? 1 : debate?.selectedRound,
      CreatedAt: getUTCDate(),
      IsLike: !debate?.isLoginUserLiked,
      ...(debate?.invitationId && { InvitationId: debate.invitationId }), // Only include if InvitationId is defined
      ...(debate?.argumentId && { ArgumentId: debate.argumentId }) 
    };

    if (Object.values(LikeObj).some((value) => value === null)) {
      toast.error("Invalid like action");
      return;
    }


    try {
      const res = await addLike(LikeObj);
      if (res?.errorMessage) {
        toast.error("Failed to add like");
      }
    } catch (error) {
      toast.error("Failed to add like: " + error.message);
    }
  };

  const Actions = [
    {
      SVG: Like,
      SVGFilled: FilledLike,
      Liked: debate?.isLoginUserVoted,
      count: debate?.totalVote,
      isFilled: debate?.isLoginUserVoted,
      handleClick: handleVoteClick,
    },
    {
      SVG: HeartIcon,
      SVGFilled: FilledHeart,
      Liked: debate?.isLoginUserLiked,
      count: debate?.totalLike,
      isFilled: debate?.isLoginUserLiked,
      handleClick: handleLikeClick,
    },
    {
      SVG: Share,
      count: null,
    },
  ];

  return (
    <>
      {Actions.map((action, index) => (
        <Fragment key={index}>
          <Tooltip
            content={toolcontent}
            className={`bg-white text-black border border-black ${
              !istooltip ? "hidden" : ""
            }`}
          >
            <button
              className={`bg-transparent text-secondary rounded-sm p-2 ${
                isLoading ? "cursor-not-allowed" : "cursor-pointer"
              } flex justify-center gap-2 items-center ${className}`}
              onClick={action.handleClick}
              disabled={isLoading || isVotePending || isLikePending}
            >
              {action?.isFilled ? (
                <action.SVGFilled className="text-secondary h-6 w-6 hover:text-secondary-solid hover:scale-125" />
              ) : (
                <action.SVG className="text-secondary h-6 w-6 hover:text-secondary-solid hover:scale-125" />
              )}
              <span className="text-debatePalette-shadow font-semibold">
                {action?.count !== null ? `(${action?.count})` : ""}
              </span>
            </button>
          </Tooltip>
        </Fragment>
      ))}
    </>
  );
};

export default DebateActions;
