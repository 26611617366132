import { Button, Tooltip } from "@material-tailwind/react";
import React from "react";

const ActionButton = ({
  SVG,
  className,
  isLoading = false,
  onClick,
  BtnHeight,
  BtnWidth,
  title,
  count = 0,
  toolcontent,
  istooltip = false,
  isText = true,
  isDisable
}) => {
  return (
    <Tooltip content={toolcontent} className={`bg-white text-black border border-black ${!istooltip ? 'hidden' : ""}`}>
    <Button
      id={title}
      className={` bg-transparent text-secondary rounded-sm  p-2  ${
        isLoading ? "cursor-not-allowed" : "cursor-pointer"
      } flex justify-center items-center ${className} `}
      onClick={onClick}
      disabled={isDisable}
      variant="text"
    >
      <SVG className="text-secondary h-6 w-6 hover:text-secondary hover:text-opacity-55" />
      {isText ? <span className=" text-secondary font-semibold hover:underline">{count ?? count}</span> : null}
    </Button>
    </Tooltip>
  );
};

export default ActionButton;
