import axios from "axios";
import { refreshToken } from "./auth/authApi";
import toast from "react-hot-toast";

export const AXIOS_INSTANCE = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    Accept: "application/json",
  },
});

AXIOS_INSTANCE.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem("user"));
    let token = user?.jwtToken;
    if (token !== null) config.headers.Authorization = "Bearer " + token;

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Add response interceptor for handling 401 and refreshing token
AXIOS_INSTANCE.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {

    const originalRequest = error.config;
    if (
      (error.response?.data?.error?.message === "Access is restricted for you" ||
        error.response.status === 401) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      try {
        let user = JSON.parse(localStorage.getItem("user"));
        let token = user?.jwtToken;
        // Call token refresh endpoint
        const data = await refreshToken(token);

        const newTokenUser = {
          ...user,
          jwtToken: data.jwtToken,
        };

        // Update the token in localStorage

        localStorage.setItem("user", JSON.stringify(newTokenUser));

        // Set the new token in the request headers
        AXIOS_INSTANCE.defaults.headers.common["Authorization"] =
          "Bearer " + data.newToken;
        originalRequest.headers["Authorization"] = "Bearer " + data.newToken;

        // Retry the original request with the new token
        return AXIOS_INSTANCE(originalRequest);
      } catch (err) {
        if (
          error.response.status === 400 ||
          error.response.status === 401 ||
          err.response.data.error.message === "Authentication token not found"
        ) {
          toast.error("Session expired, please login again");
          localStorage.clear();
          window.location.reload();
        }
        // Handle token refresh failure
        // console.error("Token refresh failed", err);
        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default AXIOS_INSTANCE;
