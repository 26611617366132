import {
  ADD_USER_CATEGORY,
  GET_ALL_FILTER_CATEGORY_COUNT,
  GET_CATEGORY_BY_SUBWORKID,
} from "../apiRoutes";
import AXIOS_DEBATE_INSTANCE from "../axiosDebateInstance";
import { getDataDEBATE_INSTANCE, getDataINSTANCE } from "../controller";

// Get category by subworkspace id
export const getCategoryByWorkId = async (Id) => {
  const data = await getDataDEBATE_INSTANCE(
    `${GET_CATEGORY_BY_SUBWORKID}?workspaceId=${Id}`,
    "Failed to fetch categories by subworkspace id"
  );
  return data;
};

// Add category for user
export const addUserCategory = async (categoryBody) => {
  try {
    const data = await AXIOS_DEBATE_INSTANCE.post(
      ADD_USER_CATEGORY,
      categoryBody
    );
    return data;
  } catch (error) {
    throw new Error("Failed to add category for user");
  }
};

export const getFilterCategoryCounts = async (userId, workId, subId, type) => {
  const data = await getDataINSTANCE(
    `${GET_ALL_FILTER_CATEGORY_COUNT}?userId=${userId}&workspaceId=${workId}&subworkspaceId=${subId}&type=${type}`,
    "Failed to fetch category count"
  );
  return data;
};
