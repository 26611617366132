import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AddMotion, GetMotionCategoryListCount, getMotionCitationList, GetMotionList } from "../../api/motion/motion";


export const useGetMotionList = (filter) => {
    return useQuery({
      queryKey: ["motionList", filter],
      queryFn: async () => {
        return await GetMotionList(filter);
      },
      select: (data) => data.motionList,
      enabled: !!filter.loggedInUserId,
    });
  };
export const useGetCategoryList = (workSpaceId) => {
    return useQuery({
      queryKey: ["categoryCountList", workSpaceId],
      queryFn: async () => {
        return await GetMotionCategoryListCount(workSpaceId);
      },
      select: (data) => data.motionCategories,
      enabled: !!workSpaceId,
    });
  };

  export const useAddMotion = () => {
    const queryClient = useQueryClient();
    return useMutation({
      mutationFn: async (Motion) => {
        return await AddMotion(Motion);
      },
      onSuccess: () => Promise.all([
        queryClient.invalidateQueries({
          queryKey: ["motionList"],
        })
      ]),
      onError: (error) => {
        console.log(error);
      },
    });
  };

  export const useGetMotionCitationList = (motionId, userId) => {
    return useQuery({
      queryKey: ["motion-citations", motionId, userId],
      queryFn: async () => {
        return await getMotionCitationList(motionId, userId);
      },
      select: (data) => data.listOfCitation,
      enabled: !!motionId && !!userId,
    });
  }