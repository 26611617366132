import React, { useState } from "react";
import Container from "../../Components/UI/Container";
import ReusableTabStructure from "../../Components/UI/Tabs/ReusableTabStructure";
import FollowerList from "./FollowerList";
import FollowingList from "./FollowingList";

const FollowerTabs = () => {
  const [selected, setSelected] = useState("followers");


  const Options = [
    {
      label: "Followers",
      value: "followers",
      Component: <FollowerList />,
    },
    {
      label: "Followings",
      value: "followings",
      Component: <FollowingList />,
    },
  ];
  return (
    <Container>
      <div className="h-full w-full flex flex-col gap-5">
      <ReusableTabStructure
          Options={Options}
          selected={selected}
          setSelected={setSelected}
          size={"w-full sm:w-80"}
        />
      </div>
    </Container>
  );
};

export default FollowerTabs;
