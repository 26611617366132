import { useCallback, useEffect } from "react";
import { useAuth } from "./AuthContext";
import { SocketEvents, useSocket } from "./SocketContext";
import { useSubWorkspace } from "./SubWorkspaceContext";
import { useWorkspace } from "./WorkspaceContext";
import { useQueryClient } from "@tanstack/react-query";

const useChatSocketHandlers = ({ chatUserList, setChattedUserList }) => {
  const { socket } = useSocket();
  const { user: loggedInUser } = useAuth();
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const queryClient = useQueryClient();
  const isForumOnly = process.env.REACT_APP_FORUM_WISE === "true"


  // Helper function to update user in the chat list
  const updateUserInList = useCallback(
    (workspaceId, subWorkspaceId, userId, updates) => {
      setChattedUserList((prevList) => {
        if (!prevList) return prevList;

        const index = prevList.findIndex(
          (user) =>
            user.id === userId &&
            user.workspaceId === workspaceId &&
            (user.subWorkspaceId === subWorkspaceId || user.subworkspaceId === subWorkspaceId)
        );
        if (index === -1) {
          console.warn(`User with id ${userId} not found`);
          return prevList;
        }

        const updatedUser = { ...prevList[index], ...updates };
        const newList = [...prevList];
        newList[index] = updatedUser;
        return newList;
      });
    },
    [setChattedUserList]
  );

  // Sync initial user list on mount or when chatUserList changes
  useEffect(() => {
    setChattedUserList(chatUserList);
  }, [chatUserList, setChattedUserList]);

  // Handler for receiving messages and invalidating queries
  useEffect(() => {
    if (!socket) return;

    const handleReceiveMessage = async (data) => {
      await queryClient.invalidateQueries({
        queryKey: [
          "user-workspace-list"
        ],
      });
    };

    socket.on("update-receiver-data", handleReceiveMessage);
    return () => {
      socket.off("update-receiver-data", handleReceiveMessage);
    };
  }, [socket, queryClient]);

  // Handler for sender data updates
  useEffect(() => {
    if (!socket) return;

    const handleUpdateSenderData = async (data) => {
      await queryClient.invalidateQueries({
        queryKey: [
          "user-workspace-list"
        ],
      });
    };

    socket.on("update-sender-data", handleUpdateSenderData);
    return () => {
      socket.off("update-sender-data", handleUpdateSenderData);
    };
  }, [socket, queryClient]);

  // Handler for user status updates (e.g., online/offline)
  useEffect(() => {
    if (!socket) return;

    const handleUpdateUserStatus = ({ userId, online }) => {
      updateUserInList(
        selectedWorkspace?.workSpaceId,
        isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
        userId,
        { online }
      );
    };

    socket.on(SocketEvents.UPDATE_USER_STATUS, handleUpdateUserStatus);
    return () => {
      socket.off(SocketEvents.UPDATE_USER_STATUS, handleUpdateUserStatus);
    };
  }, [socket, updateUserInList, loggedInUser, selectedWorkspace, selectedSubWorkspace, isForumOnly]);

  // Handler for updates to receiver data (e.g., read/unread state)
  useEffect(() => {
    if (!socket) return;

    const handleReceiverDataUpdate = (data) => {
      if (data.workspaceId !== selectedWorkspace?.workSpaceId || data.subworkspaceId !== isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "") return;

      const isReceiver = loggedInUser?.userId === data.receiverId;
      const isSender = loggedInUser?.userId === data.senderId;
      if (isReceiver || isSender) {
        const userIdToUpdate = isReceiver ? data.senderId : data.receiverId;
        updateUserInList(
          data.workspaceId,
          isForumOnly ? selectedSubWorkspace?.subWorkSpaceId : "",
          userIdToUpdate,
          data
        );
      }
    };

    socket.on(SocketEvents.UPDATE_RECEIVER_DATA, handleReceiverDataUpdate);
    return () => {
      socket.off(SocketEvents.UPDATE_RECEIVER_DATA, handleReceiverDataUpdate);
    };
  }, [socket, loggedInUser, updateUserInList, selectedWorkspace, selectedSubWorkspace, isForumOnly]);

  // Handler for marking messages as read
  useEffect(() => {
    if (!socket) return;

    const handleMarkedAsRead = ({
      receiverId,
      unreadCount,
      workspaceId,
      subWorkspaceId,
    }) => {
      updateUserInList(workspaceId, subWorkspaceId, receiverId, {
        unreadCount,
      });
    };

    socket.on(SocketEvents?.RECEIVER_MARKED_AS_READ, handleMarkedAsRead);
    return () => {
      socket.off(SocketEvents?.RECEIVER_MARKED_AS_READ, handleMarkedAsRead);
    };
  }, [socket, updateUserInList, loggedInUser, selectedWorkspace, selectedSubWorkspace]);
};

export default useChatSocketHandlers;
