import { createContext, useContext, useEffect, useState } from "react";
import { useWorkspacesByUserId } from "../Web-Hooks/Workspace/use-workspace";

const WorkspaceContext = createContext({
  workspaceList: [],
  selectedWorkspace: null,
  UpdateWorkspace: () => {},
  selectWorkspace: () => {},
  findWorkspaceFromUserWorkspaceList: () => {},
});

export const useWorkspace = () => {
  return useContext(WorkspaceContext);
};

export const WorkspaceProvider = ({ children }) => {
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const localValue = window.localStorage.getItem("user");
  const userId = localValue && localValue !== "undefined" ? JSON.parse(localValue).userId : null
    
  const { data: workspaceList } = useWorkspacesByUserId(userId);

  const findWorkspaceFromUserWorkspaceList = (workspaceId) => {
    return workspaceList.find((workspace) => workspace.workSpaceId === workspaceId);
  };

  useEffect(() => {
    const localStorageValue = window.localStorage.getItem("selectedWorkspace");
    if (localStorageValue && localStorageValue !== "undefined") {
      setSelectedWorkspace(JSON.parse(localStorageValue));
    } else {
      setSelectedWorkspace(null);
    }
  }, []);

  const UpdateWorkspace = (workspace) => {
    selectWorkspace(workspace);
  };

  const selectWorkspace = (workspace) => {
    setSelectedWorkspace(workspace);
    localStorage.setItem("selectedWorkspace", JSON.stringify(workspace));
  };

  return (
    <WorkspaceContext.Provider
      value={{
        workspaceList,
        selectedWorkspace,
        selectWorkspace,
        UpdateWorkspace,
        findWorkspaceFromUserWorkspaceList,
      }}
    >
      {children}
    </WorkspaceContext.Provider>
  );
};
