import {
  ChevronLeftIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { Button, Drawer, Switch, Typography } from "@material-tailwind/react";
import React, { useState } from "react";
import { useAuth } from "../../Context/AuthContext";
import ForumWiseNotification from "./ForumWiseNotification";
import InvitationNotification from "./InvitationNotification";

const TEMP_DATA = {
  PauseAllNotification: false,
  NotificationOptions: {
    Invitation: {
      PauseAllNotification: false,
      debatebaseNotificationOptions: [
        {
          workspaceName: "debatebase1",
          workspaceId: "id1",
          notificationStatus: true,
        },
        {
          workspaceName: "debatebase2",
          workspaceId: "id1",
          notificationStatus: true,
        },
        {
          workspaceName: "debatebase3",
          workspaceId: "id1",
          notificationStatus: false,
        },
      ],
    },
    LikeVote: [
      {
        workspaceName: "debatebase1",
        workspaceId: "id1",
        allNotificationOn: true,
        workspaceOptions: [
          {
            forumName: "forum1",
            forumId: "f1",
            notificationStatus: false,
          },
          {
            forumName: "forum--33",
            forumId: "f1",
            notificationStatus: true,
          },
        ],
      },

      {
        workspaceName: "debatebase2",
        workspaceId: "id1",
        allNotificationOn: false,
        workspaceOptions: [
          {
            forumName: "forum1",
            forumId: "f1",
            notificationStatus: false,
          },
          {
            forumName: "forum1",
            forumId: "f1",
            notificationStatus: true,
          },
          {
            forumName: "forum1",
            forumId: "f1",
            notificationStatus: false,
          },
        ],
      },
    ],
    Draft: [
      {
        workspaceName: "debatebase1",
        workspaceId: "id1",
        allNotificationOn: true,
        workspaceOptions: [
          {
            forumName: "forum1",
            forumId: "f1",
            notificationStatus: true,
          },
        ],
      },
    ],
    FollowUnfollow: true,
    Message: false,
    BlockUnblock: false,
  },
};

const NotificationDrawer = ({ openNotification, closeOpenNotification }) => {
  const [openFolder, setOpenFolder] = useState("All");
  const handleCloseDrawer = () => {
    closeOpenNotification();
  };
  const { user } = useAuth();

  // const { data: workspaceStatusList } = useGetNotificationStatusWorkspaceWise(
  //   user?.userId
  // );
  // const { mutateAsync: updateStatus } = useUpdateNotificationList();

  // const filteredToggledOff = workspaceStatusList?.find(
  //   (workspace) => workspace?.isToogleOn === false
  // );

  const handleChangeNotification = (type, value) => {
    const notifyObj = {
      loggedInUserId: user?.userId,
      notificationStatus: value,
      Type: type,
    };

    console.log(notifyObj)
  };

  // const handleUpdateStatus = (workspace) => {
  //   const workspaceObj = {
  //     WorkSpaceInvitationId: workspace?.workSpaceInvitationId,
  //     IsToogleOn: workspace?.isToogleOn ? false : true,
  //   };

  //   updateStatus(workspaceObj)
  //     .then((res) => {
  //       console.log(res);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // };
  return (
    <Drawer
      placement="right"
      open={openNotification}
      onClose={handleCloseDrawer}
      className={`sm:w-[500px] ${
        openNotification ? "shadow-xl shadow-debatePalette-base" : ""
      }`}
      overlay={false}
      size={350}
    >
      <div className="grid grid-cols-1 grid-rows-[auto_auto_auto_auto_1fr] h-screen p-3 overflow-y-auto">
        <div className="flex justify-between items-center w-full h-20 ">
          {openFolder !== "All" && (
            <Button
              variant="text"
              onClick={() => setOpenFolder("All")}
              className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg"
            >
              <ChevronLeftIcon className="h-5 w-5" />
            </Button>
          )}
          <Typography
            variant="h4"
            className="text-debatePalette-title font-semibold"
          >
            Notification
          </Typography>
          <Button
            variant="text"
            onClick={handleCloseDrawer}
            className="bg-gray-200 text-gray-700 py-2 px-4 rounded-lg"
          >
            <XMarkIcon className="h-5 w-5" />
          </Button>
        </div>
        <hr className="border-gray-300" />
        {openFolder === "All" && (
          <>
            <Typography
              variant="h6"
              className="text-debatePalette-title font-semibold py-3"
            >
              Push Notification
            </Typography>
            <hr className="border-gray-300" />
            <form className="flex flex-col gap-4 overflow-y-auto py-3">
              <div className="flex w-full items-center  justify-between">
                <label htmlFor="all" color="blue-gray" className="font-medium">
                  Pause All
                </label>
                <Switch
                  id="all"
                  name="all"
                  color="blue"
                  checked={TEMP_DATA.PauseAllNotification}
                  onClick={() =>
                    handleChangeNotification(
                      "All",
                      !TEMP_DATA.PauseAllNotification
                    )
                  }
                  onChange={() =>
                    handleChangeNotification(
                      "All",
                      !TEMP_DATA.PauseAllNotification
                    )
                  }
                  containerProps={{
                    className: "flex-shrink-0 p-0 w-16",
                  }}
                />
              </div>
              <div
                className="flex w-full items-center justify-between hover:cursor-pointer"
                onClick={() => setOpenFolder("Invitation")}
              >
                <p color="blue-gray" className="font-medium">
                  Invitation
                </p>
                <ChevronRightIcon
                  strokeWidth={2.5}
                  className="h-5 flex-shrink-0 p-0 w-16"
                />
              </div>
              <div
                className="flex w-full items-center justify-between hover:cursor-pointer"
                onClick={() => setOpenFolder("Like/Vote")}
              >
                <p color="blue-gray" className="font-medium">
                  Like/Vote
                </p>
                <ChevronRightIcon
                  strokeWidth={2.5}
                  className="h-5 flex-shrink-0 p-0 w-16"
                />
              </div>
              <div
                className="flex w-full items-center justify-between hover:cursor-pointer"
                onClick={() => setOpenFolder("Draft")}
              >
                <p color="blue-gray" className="font-medium">
                  Draft
                </p>
                <ChevronRightIcon
                  strokeWidth={2.5}
                  className="h-5 flex-shrink-0 p-0 w-16"
                />
              </div>

              <div className="flex w-full items-center justify-between">
                <label
                  htmlFor="follow"
                  color="blue-gray"
                  className="font-medium"
                >
                  Followers and Following
                </label>
                <Switch
                  id="follow"
                  name="follow"
                  color="blue"
                  checked={TEMP_DATA.NotificationOptions.FollowUnfollow}
                  onClick={() =>
                    handleChangeNotification(
                      "Follow",
                      !TEMP_DATA.NotificationOptions.FollowUnfollow
                    )
                  }
                  onChange={() =>
                    handleChangeNotification(
                      "Follow",
                      !TEMP_DATA.NotificationOptions.FollowUnfollow
                    )
                  }
                  containerProps={{
                    className: "flex-shrink-0 p-0 w-16",
                  }}
                />
              </div>
              <div className="flex w-full items-center justify-between">
                <label
                  htmlFor="message"
                  color="blue-gray"
                  className="font-medium"
                >
                  Message
                </label>
                <Switch
                  name="message"
                  id="message"
                  color="blue"
                  checked={TEMP_DATA.NotificationOptions.Message}
                  onClick={() =>
                    handleChangeNotification(
                      "Message",
                      !TEMP_DATA.NotificationOptions.Message
                    )
                  }
                  onChange={() =>
                    handleChangeNotification(
                      "Message",
                      !TEMP_DATA.NotificationOptions.Message
                    )
                  }
                  containerProps={{
                    className: "flex-shrink-0 p-0 w-16",
                  }}
                />
              </div>
              <div className="flex w-full items-center justify-between">
                <label
                  htmlFor="block"
                  color="blue-gray"
                  className="font-medium"
                >
                  Block and Unblock
                </label>
                <Switch
                  name="block"
                  id="block"
                  color="blue"
                  checked={TEMP_DATA.NotificationOptions.BlockUnblock}
                  onClick={() =>
                    handleChangeNotification(
                      "Block",
                      !TEMP_DATA.NotificationOptions.BlockUnblock
                    )
                  }
                  onChange={() =>
                    handleChangeNotification(
                      "Block",
                      !TEMP_DATA.NotificationOptions.BlockUnblock
                    )
                  }
                  containerProps={{
                    className: "flex-shrink-0 p-0 w-16",
                  }}
                />
              </div>
            </form>
          </>
        )}
        {openFolder === "Invitation" && (
          <InvitationNotification
            type={"Invitation"}
            settingInfo={TEMP_DATA.NotificationOptions.Invitation}
          />
        )}
        {openFolder === "Like/Vote" && (
          <ForumWiseNotification
            type={"VoteLike"}
            settingInfo={TEMP_DATA.NotificationOptions.LikeVote}
          />
        )}
        {openFolder === "Draft" && (
          <ForumWiseNotification
            type={"Draft"}
            settingInfo={TEMP_DATA.NotificationOptions.Draft}
          />
        )}
      </div>
    </Drawer>
  );
};

export default NotificationDrawer;
