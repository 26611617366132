import { Checkbox } from "@material-tailwind/react";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import EditableBodyPopup from "../../Components/Popup/EditableBodyPopup";
import DebateInput from "../../Components/UI/Inputs/DebateInput";
import SearchField from "../../Components/UI/Inputs/SearchField";
import { useAuth } from "../../Context/AuthContext";
import { useSocket } from "../../Context/SocketContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { getUserImageUrl } from "../../Utils/Helper";
import DebateTextArea from "../../Components/UI/Inputs/DebateTextArea";

const NewGroupPopup = ({
  groupPopup,
  setGroupPopup,
  searchValue,
  setSearchValue,
  USERS,
}) => {
  const [groupName, setGroupName] = useState("");
  const [groupDescription, setGroupDescription] = useState("");
  const navigate = useNavigate();
  const [contacts, setContacts] = useState([]);
  const { selectedWorkspace } = useWorkspace();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { user: loggedInUser } = useAuth();
  const { socket } = useSocket();
  const [loader, setLoader] = useState(false);
  const queryClient = useQueryClient();
  const wordLimit = 50;
  const handleChangeContacts = (member) => {
    const index = contacts.findIndex((contact) => contact.value === member.id);

    const newContact = {
      label: member.username,
      value: member.id,
    };
    if (index === -1) {
      setContacts([...contacts, newContact]);
    } else {
      const updatedContacts = [...contacts];
      updatedContacts.splice(index, 1);
      setContacts(updatedContacts);
    }
  };

  const handleCreateGroup = async () => {
    setLoader(true);
    if (!groupName || contacts.length === 0) {
      toast.error(
        "Please provide a group name and select at least one contact."
      );
      return;
    }

    const users = contacts.map((contact) => contact.value);

    const group = {
      name: groupName,
      description: groupDescription,
      created_by: loggedInUser?.userId,
      workspaceId: selectedWorkspace?.workSpaceId,
      subworkspaceId: selectedSubWorkspace?.subWorkSpaceId,
      users: [loggedInUser?.userId, ...users],
    };

    try {
      socket?.emit("createGroup", group);
    } catch (error) {
      toast.error("Failed to create group");
    } finally {
      socket?.on("newGroupCreated", async ({ groupId, group }) => {
        navigate(`/group-message/${groupId}`, {
          state: { group },
        });
        await queryClient?.invalidateQueries(["group-chat-list"]);
      });
      setGroupPopup(false);
      setGroupName("");
      setContacts([]);
      toast.success("Group created successfully");
      setLoader(false);
    }
  };

  const handleContentChange = (value) => {
    const inputText = value;
    const words = inputText.trim().split(/\s+/);
    if (words.length <= wordLimit) {
      setGroupDescription(inputText);
      return;
    } else {
      const limitedWords = words.slice(0, wordLimit).join(" ") + " ";
      setGroupDescription(limitedWords);
    }
  };

  return (
    <EditableBodyPopup
      open={groupPopup}
      handleClose={() => {
        setGroupPopup(false);
      }}
      title={"Create Group"}
      size={"md"}
      children={
        <div className="flex flex-col text-debatePalette-title overflow-y-scroll">
          <DebateInput
            label={"Group Name"}
            placeholder={"Group Name"}
            maxLength={20}
            value={groupName}
            className={"text-debatePalette-title"}
            handleChange={(e) => setGroupName(e.target.value)}
          />
          <DebateTextArea
            id={"motion-textarea"}
            label={"Group Description"}
            maxLength={50}
            value={groupDescription}
            className={"text-debatePalette-title"}
            handleChange={(e) => handleContentChange(e.target.value)}
            placeholder={"Group description"}
          />
          <div className="flex flex-col gap-3">
            <p className="text-lg font-semibold">Invite member</p>
            <div className="w-[80%]">
              <SearchField
                id="search-user-message"
                name="search-user-message"
                search={searchValue}
                setSearch={setSearchValue}
              />
            </div>

            <form className="flex flex-col overflow-y-scroll member-list h-52  items-start gap-2">
              {USERS?.map((member) => (
                <Checkbox
                  key={member?.id}
                  containerProps={{
                    className: "!flex items-center gap-4 shrink-0",
                  }}
                  checked={contacts
                    ?.map((contact) => contact?.value)
                    .includes(member?.id.toString())}
                  onChange={() => handleChangeContacts(member)}
                  id={member?.id}
                  color="blue"
                  ripple={false}
                  className="w-4 h-4 rounded-none before:content-none"
                  label={
                    <div className="flex items-center gap-2 w-full truncate">
                      {
                        <ImageOrInitials
                          imageSrc={getUserImageUrl(member?.id, member?.avatar)}
                          initials={member?.username || member?.email}
                        />
                      }
                      <p className="w-full text-md capitalize">
                        {member?.userName || member?.email?.split("@")[0]}
                      </p>
                    </div>
                  }
                  labelProps={{
                    className:
                      "text-debatePalette-title w-full text-md font-semibold capitalize",
                  }}
                />
              ))}
            </form>
          </div>
        </div>
      }
      loading={loader}
      onClickBtn1={() => {
        setGroupPopup(false);
      }}
      onClickBtn2={() => {
        handleCreateGroup();
      }}
      disableBtn={!groupName || contacts.length === 0 || !groupDescription}
      btn1={"Cancel"}
      btn2={"Create"}
    />
  );
};

export default NewGroupPopup;
