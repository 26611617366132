import { Button, Dialog, Spinner } from "@material-tailwind/react";
import React, { useEffect } from "react";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import Logo from "../../Assets/Images/DB_AI.png";
import IconRoundedButton from "../../Components/UI/Buttons/ImageRoundedButton";
import DebateChip from "../../Components/UI/Chip/DebateChip";
import Confetti from "../../Components/UI/Confetti";
import SearchField from "../../Components/UI/Inputs/SearchField";
import { shadowBlue } from "../../Utils/Constant";
import {
  useCategorySuggestionAI,
  useTopicSuggestionAI,
} from "../../Web-Hooks/Agents/use-global-agents";
import { useMotionAgent } from "../../Web-Hooks/Agents/use-motion-agents";
import { useGetCategoriesByWorkspaceId } from "../../Web-Hooks/Categories/use-categories";

export default function GlobalAIAgent01() {
  const { data: categoryList } = useGetCategoriesByWorkspaceId(
    JSON.parse(localStorage.getItem("selectedWorkspace")).workSpaceId
  );
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState(null);
  const [categoryThumbnail, setCategoryThumbnail] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [topics, setTopics] = React.useState([]);
  const [side, setSide] = React.useState("");
  const [motion, setMotion] = React.useState("");
  const [step, setStep] = React.useState(1);

  const { data: motionAssistance, isLoading: motionAssistanceLoading } =
    useMotionAgent(motion ? `Is ${motion}` : "");
  const { data: categorySuggestion } = useCategorySuggestionAI(
    motionAssistance?.output?.Debate_motion === false
      ? motionAssistance?.output?.Corrected_Debate_motion[0]
      : motion
  );

  const { mutateAsync: getAITopics, isPending: getAITopicsPending } =
    useTopicSuggestionAI();
  const [isVisible, setIsVisible] = React.useState(false);
  const handleRadioChange = (option) => {
    setSelectedOption(option);
  };
  const categoryThumbnailSearch = () => {
    let thumbnail = categoryList?.find(
      (cat) =>
        cat.categoryName.toLowerCase() ===
        categorySuggestion?.Appropriate_Category.toLowerCase()
    );
    setCategoryThumbnail(thumbnail);
  };
  useEffect(() => {
    categoryThumbnailSearch(); // eslint-disable-next-line
  }, [categorySuggestion]);
  const handleOpen = () => setOpen(!open);

  const onSearch = () => {
    getAITopics(search)
      .then((res) => {
        setTopics(res["Debate Topics"]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleNextStep = () => {
    if (step === 1) {
      setMotion(selectedOption);
      setStep(2); // Move to the second step after fetching topics
    } else if (step === 2) {
      setIsVisible(true);
      toast(
        <p className="font-bold text-green-600">Debate create successfully</p>,
        {
          position: "top-center",
          icon: "🎉",
        }
      );
      navigate("/create-debate?activeState=2", {
        state: {
          value: "DebateDetails",
          selectedDebate: {
            MotionOrClaim: motionAssistance?.output?.Corrected_Debate_motion[0],
            DebateImage: null,
            Fields: [categorySuggestion?.Appropriate_Category],
            Type: "blitz",
            IsOtheruserAllow: false,
            SideForDebate: side,
            IsPublishWithoutOpponent: false,
            IsDebateSemiPublic: true,
            IsDebatePublic: false,
            IsDebatePrivate: false,
            NominateUserId: null,
            InvitedUserId: null,
            FileSize: 0,
            CustomRounds: null,
            CustomTimeFrame: null,
            CustomeMaxVotesToWin: null,
            // IsDebateActive: false,
            // RedebateCount: 0,
            // IsRedebated: false,
          },
        },
      });

      setTimeout(() => {
        setOpen(false);
        setIsVisible(false);
        setStep(1);
        setSearch("");
        setTopics([]);
        setMotion("");
      }, 5000); // Add additional logic for the next step here
    }
  };

  const handleBackStep = () => {
    setStep(step - 1);
  };

  const handleCancel = () => {
    setOpen(false);
    setStep(1); // Reset step to 1 when closing the dialog
    setSearch("");
    setTopics([]);
    setSelectedOption(null);
  };

  const stepConfig = {
    1: {
      title: "",
      content: (
        <>
          <div className="flex flex-col gap-4 min-w-full text-black">
            <p>
              Find the hottest debate topic for any area! Just enter a location
              in the box below 🔥
            </p>
            <div className="flex flex-row gap-2">
              <SearchField
                setSearch={setSearch}
                search={search}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onSearch(); // Call the search function
                  }
                }}
              />
              <Button
                onClick={onSearch}
                className="bg-secondary text-white rounded-md border-black border w-48"
                disabled={getAITopicsPending || search === ""}
              >
                Search
              </Button>
            </div>
          </div>
          {getAITopicsPending ? (
            <div className="flex items-center justify-center min-h-48 ">
              <Spinner className="h-10 w-10 text-gray-900/50" />
            </div>
          ) : (
            <>
              {topics.length > 0 && (
                <p className="mt-3 px-3 pb-2 text-black text-center font-bold">
                  Here are some suggested topics
                </p>
              )}

              <div className="flex flex-col w-full gap-3 md:max-h-[100px] max-h-80 overflow-y-auto min-h-48 mb-8 px-3">
                {topics.map((item, key) => (
                  <div
                    key={key}
                    className={`flex text-black flex-row items-center hover:cursor-pointer cursor-pointer px-3 py-1 gap-3 border-2 ${
                      selectedOption === item
                        ? shadowBlue
                        : "border-gray-400 !shadow-black"
                    } rounded-md shadow-sm`}
                    onClick={() => handleRadioChange(item)}
                  >
                    <span className="mt-2">{item}</span>
                  </div>
                ))}
              </div>
            </>
          )}
        </>
      ),
      buttonLabel: "Suggest Motion",
      buttonDisabled: !selectedOption,
      onButtonClick: handleNextStep,
    },
    2: {
      title: "",
      content: (
        <div className="text-left min-h-52">
          <div className={`text-black  `}>
            <p className="flex flex-row gap-2">
              <p className="min-w-fit"> Debate motion:</p>
              {motionAssistanceLoading ? (
                <ThreeDots color="#F8C800" height={30} width={40} />
              ) : (
                <b className="font-semibold text-black">
                  {motionAssistance?.output?.Debate_motion === false
                    ? motionAssistance?.output?.Corrected_Debate_motion[0]
                    : motion}
                </b>
              )}
            </p>
          </div>
          <p className="text-black mt-3 mx-3">
            Here’s a suggested category for the topic you selected:
          </p>
          <p className="text-black  mx-3">Category :</p>
          {categoryThumbnail?.thumbnail ? (
            <div className="flex flex-col items-start gap-3 max-h-80   mx-3 ">
              <span className="  text-center">
                {/* SVG */}
                <IconRoundedButton
                  className={`bg-primary-base text-black bg-secondary mt-5`}
                  SVG={categoryThumbnail?.thumbnail}
                  BtnWidth="w-14"
                  BtnHeight="h-14 "
                  toolcontent={categorySuggestion?.Appropriate_Category}
                  // istooltip={false}
                  // onClick={() => !disable && handleClick(category)}
                />
                <p className=" flex font-semibold text-black">
                  {/* {category.categoryName} */}
                  {categorySuggestion?.Appropriate_Category}
                </p>
              </span>
            </div>
          ) : (
            <div className="flex items-center justify-center min-h-48 ">
              <Spinner className="h-10 w-10 text-gray-900/50" />
            </div>
          )}
          <p className="text-black mt-3 mx-3">Choose your Side :</p>

          <div className=" mx-3">
            <DebateChip
              option={[{ label: "Agree" }, { label: "Disagree" }]}
              label={side}
              setLabel={(vote) => {
                if(vote === side){
                  setSide("");
                  return;
                }
                setSide(vote);
              }}
            />
          </div>
        </div>
      ),
      buttonLabel: "Create Debate",
      onButtonClick: handleNextStep,
    },
  };

  return (
    <>
      <div
        className="fixed hover:cursor-pointer z-100 bottom-10 2xl:right-10 right-5 flex justify-center items-center bg-transparent"
        onClick={handleOpen}
      >
        {/* from-primary to-debatePalette-focus shadow-2xl shadow-gray-900  */}
        {/* <div className="relative rounded-full bg-gradient-to-br  "> */}
        <div className="rounded-full flex justify-center items-center   ">
          <img src={Logo} alt="Logo" className="3xl:h-28 3xl:w-28  w-20 h-20" />
        </div>
      </div>
      {/* </div> */}
      <Dialog
        open={open}
        // size="lg"
        className="rounded-[50px] max-h-[calc(100vh-3rem)] m-0  border-gray-500 border-4 flex flex-col items-center p-3  "
      >
        {isVisible && <Confetti isVisible={isVisible} />}

        <div className="flex gap-3 justify-between">
          <div className="flex gap-4 flex-row items-center justify-center">
            <img
              src={Logo}
              alt="Logo"
              className="2xl:h-20 2xl:w-20  w-20 h-20  "
            />
            <span className="text-primary font-semibold text-5xl mt-2">
              Hot Topics Suggestion
            </span>
          </div>
        </div>
        <div className="w-full mt-3 px-3 overflow-y-auto">
          <h2 className="text-2xl font-semibold mb-4">
            {stepConfig[step].title}
          </h2>
          {/* <div className="overflow-y-auto max-h-[300px]"> */}
          {stepConfig[step].content}
          {/* </div> */}
          <div className="flex flex-col gap-4 items-center my-4">
            <div className="flex gap-4">
              {step > 1 && step < 5 && (
                <Button
                  className="bg-secondary text-white rounded-full border-black border w-auto"
                  onClick={handleBackStep}
                >
                  Back
                </Button>
              )}
              {step !== 5 && (
                <Button
                  className="bg-primary text-black rounded-full border-black border w-auto"
                  onClick={stepConfig[step].onButtonClick}
                  disabled={stepConfig[step].buttonDisabled}
                >
                  {stepConfig[step].buttonLabel}
                </Button>
              )}
              <Button
                variant="outlined"
                className="rounded-full w-auto"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
}
