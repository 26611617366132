import React from "react";
import { RenderLoaderSkeleton } from "../../Components/UI/SkeletonLoader";
import NoData from "../../Components/Functionality/NoData";
import ReferenceCard from "./ReferenceCard";

const ReferenceList = ({
  referenceList,
  user,
  isReferenceLoading,
  setSelectedReference,
}) => {
  return (
    <div className="flex flex-col gap-5 w-full h-full p-1">
      {isReferenceLoading && RenderLoaderSkeleton(3)}
      {referenceList?.length > 0 ? (
        referenceList?.map((reference) => (
          <ReferenceCard
            setSelectedReference={setSelectedReference}
            key={reference?.referenceId}
            user={user}
            reference={reference}
          />
        ))
      ) : (
        !isReferenceLoading && <NoData message={"No Reference Found"} size={"w-full h-[80%]"} />
      )}
    </div>
  );
};

export default ReferenceList;
