import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useSearchParams } from "react-router-dom";
import { ReactComponent as Alert } from "../../../Assets/Svg/Alert.svg";
import CustomSlider from "../../../Components/Functionality/CustomSlider";
import DebateHeader from "../../../Components/Functionality/DebateHeader";
import InfoPopup from "../../../Components/Popup/InfoPopup";
import BasicButton from "../../../Components/UI/Buttons/BasicButton";
import DebateChip from "../../../Components/UI/Chip/DebateChip";
import { useAuth } from "../../../Context/AuthContext";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import { useSubWorkspace } from "../../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../../Context/WorkspaceContext";
import { prepareFormData, ValidateRequiredFields } from "../../../Utils/Helper";
import AddPhoto from "./../AddPhoto";
import AddArgumentWithAi from "./AddArgumentWithAi";
import AddCategoryWithAI from "./AddCategoryWithAI";
import AddCitationWithAI from "./AddCitationWithAI";
import AddMotionWithAI from "./AddMotionWithAI";
import SaveDebateFunctionality from "./SaveDebateFunctionality";

const SelectDebateSide = ({ handleBack, handleNext }) => {
  const {
    debateInfo,
    handleStateUpdate,
    handleDebateAndCitationAPI,
    isAddCitationPending,
    isAddDebatePending,
    isUpdateRedebateCountPending,
  } = useCreateDebateContext();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { selectedWorkspace } = useWorkspace();
  const { user } = useAuth();
  const [Publish, setPublish] = useState(false);
  const [Invite, setInvite] = useState(false);
  const [error, setError] = useState({
    CreatorOpeningArgument: "",
    MotionOrClaim: "",
    Type: "",
    Fields: "",
    SideForDebate: "",
  });
  const [image] = useState("");

  const [query] = useSearchParams();
  const from = query?.get("from");
  const debateId = query?.get("debateId");

  const fieldsToValidate = [
    {
      name: "SideForDebate",
      displayName: "Argument",
      errorMessage: "Please select side for debate",
    },
    {
      name: "CreatorOpeningArgument",
      displayName: "Argument",
      errorMessage: "Please add a creator opening argument",
    },
    {
      name: "MotionOrClaim",
      displayName: "Debate Type",
      errorMessage: "Please enter a motion or claim",
    },
    {
      name: "Type",
      displayName: "Debate Type",
      errorMessage: "Please select a debate type",
    },
    {
      name: "Fields",
      displayName: "Categories",
      errorMessage: "Please add at least one field",
    },
  ];

  const storeId =
    from === "Redebate" ? user?.userId : debateId ? debateId : user?.userId;

  const handlePublish = async (draft = false) => {
    const draftFieldsToValidate = fieldsToValidate.filter(
      (field) =>
        field.name !== "CreatorOpeningArgument" &&
        field.name !== "SideForDebate"
    );
    try {
      const isFormValidated = ValidateRequiredFields(
        draft ? draftFieldsToValidate : fieldsToValidate,
        debateInfo,
        setError
      );
      if (!isFormValidated) return;
      const formData = prepareFormData(
        from === "draft"
          ? {
              ...debateInfo,
              debateId: debateId,
            }
          : debateInfo,
        selectedSubWorkspace,
        user,
        draft
      );
      await handleDebateAndCitationAPI(formData, draft, storeId, () =>  handleSubmit());
    } catch (error) {
      console.error("Error in handlePublish:", error);
      toast.error(
        "An error occurred while processing your request. Please try again."
      );
    }
  };

  const handleSubmit = () => {
    localStorage.removeItem("Debate");
    localStorage.removeItem("Citation");
    setPublish(false);
  };

  const handleBtnClick = () =>
    debateInfo?.IsDebatePrivate ? handleNext(`invite-member`) : setInvite(true);

  const handleInvite = () => {
    setInvite(false);
    handleNext();
  };

  const isDataAdded =
    debateInfo?.MotionOrClaim &&
    debateInfo?.CreatorOpeningArgument &&
    debateInfo?.SideForDebate &&
    debateInfo?.Fields?.length > 0;

  const title = debateInfo?.IsDebatePublic
    ? selectedWorkspace?.workSpaceName
    : selectedSubWorkspace?.subWorkSpaceName;
  const heading = debateInfo?.IsDebatePublic
    ? "Public"
    : debateInfo?.IsDebateSemiPublic
    ? "Semi-Public"
    : "Private";

  const titleContext = debateInfo?.IsDebatePublic ? "debatebase" : "forum";

  return (
    <div className="w-full">
      <DebateHeader
        content={{
          headLine: "Add Motion & Claims",
          page: debateInfo?.Type === "custom" ? 4 : 3,
        }}
      />
      <div className="w-full flex flex-col gap-4">
        <div className="flex flex-row items-start w-96">
          <AddPhoto
            debateId={debateId}
            debateInfo={debateInfo}
            handleStateUpdate={handleStateUpdate}
            LibraryPhotos={image}
          />
        </div>
        <AddCategoryWithAI />
        <AddMotionWithAI error={error} setError={setError} />
        <AddArgumentWithAi error={error} setError={setError} />
        <AddCitationWithAI />
        <div className="flex flex-col items-center gap-6 py-2">
          <CustomSlider
            debateInfo={debateInfo}
            handleStateUpdate={handleStateUpdate}
          />
          <DebateChip
            option={[{ label: "Disagree" }, { label: "Agree" }]}
            label={
              debateInfo?.SideForDebate
                ? debateInfo?.SideForDebate?.toLowerCase() === "agree"
                  ? "Agree"
                  : "Disagree"
                : ""
            }
            setLabel={() => {}}
            // setLabel={(vote) => {
            //   handleStateUpdate({ ...debateInfo, SideForDebate: vote });
            //   setError((prev) => ({ ...prev, SideForDebate: "" }));
            // }}
          />
          {error?.SideForDebate && (
            <p className="text-red-500 text-sm font-semibold">
              {error.SideForDebate}
            </p>
          )}
          {/* <Button
            variant="filled"
            className="bg-debatePalette-shadow text-base px-6 py-1.5 normal-case font-normal cursor-pointer text-debatePalette-background"
            onClick={() => handlePublish(true)}
            loading={
              isAddDebatePending ||
              isAddCitationPending ||
              isUpdateRedebateCountPending
            }
          >
            Save
          </Button> */}
          <SaveDebateFunctionality
            handlePublish={() => handlePublish(true)}
            loading={
              isAddDebatePending ||
              isAddCitationPending ||
              isUpdateRedebateCountPending
            }
          />
        </div>
        <div className="flex flex-col mt-4 sm:flex-row items-center justify-between w-full">
          <BasicButton
            color={"primary"}
            className={"w-24 justify-center "}
            onClick={handleBack}
          >
            Back
          </BasicButton>
          {!debateInfo?.IsOtheruserAllow && (
            <Button
              className={`w-24 rounded-md justify-center text-debatePalette-title ${
                isDataAdded ? "bg-primary" : "bg-gray-400"
              }`}
              onClick={handleBtnClick}
              disabled={!isDataAdded}
            >
              Invite
            </Button>
          )}
          {debateInfo?.IsOtheruserAllow && (
            <Button
              className={`w-24 rounded-md flex items-center justify-center text-debatePalette-title ${
                isDataAdded ? "bg-primary" : "bg-gray-400"
              }`}
              onClick={() => setPublish(true)}
              disabled={!isDataAdded}
            >
              <span className="text-center">Publish</span>
            </Button>
          )}
        </div>
      </div>
      {Invite && (
        <InfoPopup
          open={Invite}
          handleClose={() => setInvite(false)}
          title={`${heading} Debate`}
          btn1={"Agree to Publish"}
          btn2={"Just Send the Invite"}
          onClickBtn1={() => {
            setInvite(false);
            setPublish(true);
          }}
          btn2Variant={"text"}
          onClickBtn2={handleInvite}
          loading1={
            isAddDebatePending ||
            isAddCitationPending ||
            isUpdateRedebateCountPending
          }
          descriptionStack={[
            "We’ll give your opponent 48 hours to accept the debate .",
            "For some reason invited opponents don’t always answer the call, but don’t worry, you still have options to stay in the game! ",
            `If your invite is not accepted within 48 hours, your debate can be automatically published to the ${title} (${heading}) feed. That means anyone in your ${titleContext} can take up your debate!`,
          ]}
        />
      )}

      {Publish && (
        <InfoPopup
          open={Publish}
          handleClose={() => setPublish(false)}
          title={
            <div className="flex items-center gap-3">
              <Alert className="h-8 w-8" />
              <span className="text-2xl font-semibold text-debatePalette-timer">
                Check this out!!
              </span>
            </div>
          }
          btn1={"Publish"}
          btn2={"Take me back"}
          btn2Variant={"text"}
          onClickBtn1={() => {
            handlePublish(false);
          }}
          onClickBtn2={() => setPublish(false)}
          loading1={
            isAddDebatePending ||
            isAddCitationPending ||
            isUpdateRedebateCountPending
          }
          descriptionStack={[
            `This debate will be published to the ${title} (${heading}) feed.`,
            `That means anyone in your ${titleContext} can take up your debate!`,
          ]}
        />
      )}
    </div>
  );
};

export default SelectDebateSide;
