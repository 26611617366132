import { Card, CardBody, Typography } from "@material-tailwind/react";

export const RenderLoaderSkeleton = (Number, type = "debate") => {
  if (type === "motion") {
    return Array.from({ length: Number }).map((_, index) => (
      <Card
        key={index}
        shadow={false}
        className="w-full py-4 rounded-none border-b-2 border-gray-300 flex-col sm:flex-row items-center sm:items-stretch animate-pulse"
      >
        <CardBody className="flex lg:flex-row flex-col items-start w-full justify-between py-1 px-0 gap-3">
          <div className="flex items-center gap-3">
            {/* MotionChip Skeleton */}
            <div className="bg-gray-300 rounded-md h-16 w-40"></div>

            <div className="flex flex-col">
              {/* Motion Text Skeleton */}
              <div className="flex gap-2">
                <div className="bg-gray-300 h-4 w-24 mt-1 rounded"></div>
                <div className="bg-gray-300 h-4 w-24 mt-1 rounded"></div>
              </div>

              {/* SubworkspaceName Text Skeleton */}

              {/* Hidden MotionActions Skeleton */}
              <div className="lg:flex gap-2 items-center hidden mt-2">
                <div className="bg-gray-300 h-5 w-60 rounded"></div>
              </div>
            </div>
          </div>

          <div className="flex lg:self-end items-center justify-between w-full lg:w-auto">
            <div className="flex gap-0 xs:gap-1 sm:gap-2 items-center lg:hidden">
              {/* MotionActions Skeleton */}
              <div className="bg-gray-300 h-5 w-24 rounded"></div>
            </div>

            <div className="flex gap-4 items-center">
              {/* Time Skeleton */}
              <div className="bg-gray-300 h-4 w-20 rounded"></div>
            </div>
          </div>
        </CardBody>
      </Card>
    ));
  } else if (type === "debate") {
    return Array.from({ length: Number }).map((_, index) => (
      <div
        key={index}
        className="h-[148px] w-full flex rounded-xl gap-6 shadow-lg animate-pulse p-3"
      >
        <div className="h-14 w-14 rounded-full bg-debatePalette-hr opacity-20 animate-pulse"></div>
        <div className="w-full flex flex-col justify-start gap-6">
          <Typography
            as="div"
            variant="h1"
            className=" h-4 w-full rounded-xl bg-gray-300"
          >
            &nbsp;
          </Typography>
          <Typography
            as="div"
            variant="paragraph"
            className=" h-4  w-full rounded-full bg-gray-300"
          >
            &nbsp;
          </Typography>
          <div className="flex w-full gap-12">
            <Typography
              as="div"
              variant="paragraph"
              className=" h-8 w-8  rounded-md bg-gray-300"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className=" h-8 w-8  rounded-md bg-gray-300"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className=" h-8 w-8  rounded-md bg-gray-300"
            >
              &nbsp;
            </Typography>
            <Typography
              as="div"
              variant="paragraph"
              className=" h-8 w-8  rounded-md bg-gray-300"
            >
              &nbsp;
            </Typography>
          </div>
        </div>
      </div>
    ));
  }

  return null;
};
