// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_GOOGLE_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_GOOGLE_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_GOOGLE_FIREBASE_PROJECT_ID}`,
  storageBucket: "debatebase-7f54e.appspot.com",
  messagingSenderId: "444992413005",
  appId: `${process.env.REACT_APP_GOOGLE_FIREBASE_APP_ID}`,
  measurementId: "G-PK7J690ZQQ",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
provider.addScope("https://www.googleapis.com/auth/contacts.other.readonly");
provider.setCustomParameters({
  prompt: 'select_account'
});
export { auth, provider };
