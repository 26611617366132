import { ChevronDownIcon } from "@heroicons/react/24/outline";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  List,
  ListItem,
  Typography,
} from "@material-tailwind/react";
import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { hoverSidebarStyle } from "../../Utils/Constant";

const AccordionNav = ({
  Options,
  icon,
  handleOpen,
  handleClose,
  open,
  pathname,
  hoverTheme = "text-debatePalette-background",
  normalTheme = "text-debatePalette-hr",
}) => {
  const navigate = useNavigate();
  return (
    <List className="min-w-[0px]">
      {Options.map((item, i) =>
        item?.subOption?.length > 0 ? (
          <Accordion key={i} open={open === item?.name} icon={icon || (
            <ChevronDownIcon
              strokeWidth={2.5}
              className={`mx-auto  h-4 w-4  transition-transform ${
                open === item?.name ? `rotate-180 ${hoverTheme}` : `${normalTheme}`
              }`}
            />
          )}>
            <ListItem className={`p-0 `} selected={open === item?.name}>
              <AccordionHeader
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpen(item?.name);
                }}
                className={`mr-auto border-b-0 p-2 text-md rounded-md ${hoverSidebarStyle} hover:${hoverTheme} hover:font-semibold ${
                  open === item?.name || pathname === item?.route
                    ? `font-semibold ${hoverTheme} bg-primary`
                    : `${normalTheme} font-normal`
                }`}
              >
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`${item?.route}`);
                  }}
                  className={`hover:underline`}
                >
                  {item.option}{" "}
                  <span className="text-sm">{item?.optionLabel}</span>
                </span>
              </AccordionHeader>
            </ListItem>
            {item?.subOption?.length ? (
              <AccordionBody
                className={`${
                  open === item?.name
                    ? "py-1 2xl:max-h-44 md:max-h-36 overflow-auto overflow-x-hidden"
                    : "h-0"
                }`}
              >
                <List className="min-w-[0px] p-0">
                  {item?.subOption?.map(
                    (user, i) =>
                       (
                        <NavLink
                          key={
                            user?.userId
                          }
                          to={`${item?.route}/${user?.userId}?userName=${user?.userName}`}
                          exact={`${true}`}
                          state={{ prevPath: pathname}}
                          className={` scrollbar-hide rounded-md ${
                            pathname ===
                            `${item?.route}/${user?.userId}`
                              ? "bg-primary focus:font-semibold"
                              : ""
                          }`}
                        >
                          <ListItem
                            className={` ${hoverSidebarStyle} flex gap-2 py-1.5 focus:bg-primary`}
                          >
                            <ImageOrInitials
                              imageSrc={user?.userImage}
                              initials={user?.userName}
                              classNameInitials={"text-xs"}
                              size={"h-6 w-6"}
                            />
                            <Typography
                              color="white"
                              className={
                                pathname ===
                                `${item?.route}/${user?.userId}`
                                  ? `bg-primary font-semibold ${hoverTheme} w-[60%] truncate`
                                  : `${hoverTheme} truncate`
                              }
                            >
                              {user?.userName}
                            </Typography>
                          </ListItem>
                        </NavLink>
                      )
                  )}
                </List>
              </AccordionBody>
            ) : null}
          </Accordion>
        ) : (
          <NavLink
            onClick={() => {
              handleClose();
            }}
            key={i}
            to={item?.route}
            exact={`${true}`}
            state={{ prevPath: pathname }}
            className={`${hoverSidebarStyle} hover:font-semibold hover:${hoverTheme} w-full py-1 rounded-md px-2 scrollbar-hide   ${
              pathname === item?.route
                ? `bg-primary font-semibold ${hoverTheme}`
                : `${normalTheme}`
            }`}
          >
            {/* <ListItemPrefix> <InboxIcon className="h-5 w-5 text-white" /> </ListItemPrefix> */}
            {item.option} <span className="text-sm ">{item?.optionLabel}</span>
            {/* <ListItemSuffix> <Chip value="14" size="sm" variant="ghost" color="white" className="rounded-full"/> </ListItemSuffix> */}
          </NavLink>
        )
      )}
    </List>
  );
};

export default AccordionNav;
