import {
  ADD_CITATION,
  ADD_DEBATE_SUBWORKSPACE,
  ADD_NEXT_ROUND_ARGUMENT,
  ADD_NOMINATION,
  ADD_OPPONENT_ARGUMENT,
  ADD_OTHER_USER_ARGUMENT,
  ADD_UPDATE_SCALER,
  CONVERT_OPEN_DEBATE,
  COPY_URL_FOLDER,
  DEBATE_RESULT,
  DELETE_BLOB,
  GET_DEBATE_CITATIONS,
  GET_DEBATE_DETAILS,
  GET_DEBATE_DETAILS_PAGE,
  GET_DEBATE_TYPE_DETAILS,
  GET_USER_ARGUMENTS,
  NOMINATE_NEW_USER,
  REMOVE_CITATION,
  REPORT_USER,
  SAVE_DEBATE,
  UPDATE_LIKE_DEBATES,
  UPDATE_RE_DEBATE,
  UPDATE_VOTE_DEBATES,
  UPLOAD_CITATION
} from "../apiRoutes";
import AXIOS_DEBATE_INSTANCE from "../axiosDebateInstance";
import AXIOS_INSTANCE from "../axiosInstance";
import AXIOS_JSON_INSTANCE from "../axiosJsonInstance";
import { getDataJSON_INSTANCE } from "../controller";

export const AddDebateToSubWorkspace = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${ADD_DEBATE_SUBWORKSPACE}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch Sub Workspaces Users");
  }
};
export const UpdateRedebateCount = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${UPDATE_RE_DEBATE}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to update Re-debate Count");
  }
};

export const AddUpdateScalerVote = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${ADD_UPDATE_SCALER}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to update scaler vote");
  }
}

export const ConvertToOpenDebate = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${CONVERT_OPEN_DEBATE}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to change in open debate");
  }
};
export const NominateNewUserToDebate = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${NOMINATE_NEW_USER}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to nominate new user in open debate");
  }
};

export const AddOpponentArgument = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${ADD_OPPONENT_ARGUMENT}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to add opponent argument");
  }
};
export const AddOtherUserArgument = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${ADD_OTHER_USER_ARGUMENT}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to add other user argument");
  }
};
export const AddNextRoundArgument = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${ADD_NEXT_ROUND_ARGUMENT}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch Sub Workspaces Users");
  }
};


export const GetDebateTypeDetails = async () => {
    const data = await getDataJSON_INSTANCE(`${GET_DEBATE_TYPE_DETAILS}`, "Failed to fetch debate type details");
    return data;
};
export const GetCopiedURL = async (url, workId, subId) => {
    const data = await getDataJSON_INSTANCE(`${COPY_URL_FOLDER}?libraryUrl=${url}&workspaceId=${workId}&subworkspaceId=${subId}`, "Failed to fetch debate type details");
    return data;
};

export const AddCitation = async (Citation) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(`${ADD_CITATION}`, Citation);
    return data;
  } catch (error) {
    throw new Error("Failed to add citation in debate");
  }
};
export const RemoveCitation = async (Citation) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(`${REMOVE_CITATION}`, Citation);
    return data;
  } catch (error) {
    throw new Error("Failed to remove citation in debate");
  }
};
export const DeleteBlob = async (Blob) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(`${DELETE_BLOB}`, Blob);
    return data;
  } catch (error) {
    throw new Error("Failed to delete file");
  }
};


export const UploadCitation = async (Citation) => {
  try {
    const data  = await AXIOS_DEBATE_INSTANCE.post(
      `${UPLOAD_CITATION}`,
      Citation
    );
    return data;
  } catch (error) {
    throw new Error("Failed to upload citation");
  }
};

export const getDebateDetails = async(id, userId) => {
  const data = await getDataJSON_INSTANCE(`${GET_DEBATE_DETAILS}${id}&userId=${userId}`, "Failed to fetch debate type details");
  return data;
}
export const getDebateDetailsPage = async(debateId,round,sortBy,userId) => {
    const data = await getDataJSON_INSTANCE(`${GET_DEBATE_DETAILS_PAGE}?debateId=${debateId}&round=${round}&filter=${sortBy}&logInUserId=${userId}`, "Failed to fetch debate type details");
    return data;

}
export const getDebateResult = async(id) => {
    const data = await getDataJSON_INSTANCE(`${DEBATE_RESULT}?debateId=${id}`,"Failed to fetch debate type details");
    return data;
}

export const AddNomination = async (Nomination) => {
  try {
    const { data : { message: data } } = await AXIOS_DEBATE_INSTANCE.post(
      `${ADD_NOMINATION}`,
      Nomination
    );
    return data;
  } catch (error) {
    throw new Error("Failed to fetch Sub Workspaces Users");
  }
};
export const UpdateVoteToDebate = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${UPDATE_VOTE_DEBATES}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to add vote");
  }
};
export const SaveDebate = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${SAVE_DEBATE}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to add to save debate");
  }
};
export const ReportUser = async (User) => {
  try {
    const { data : { message: data } } = await AXIOS_INSTANCE.post(
      `${REPORT_USER}`,
      User
    );
    return data;
  } catch (error) {
    throw new Error("Failed to report user");
  }
};

export const getDebateCitationList = async(id) => {
    const data = await getDataJSON_INSTANCE(`${GET_DEBATE_CITATIONS}?debateId=${id}`,"Failed to fetch debate citations");
    return data;

}
export const getUserArgumentList = async(userId, debateId) => {
    const data = await getDataJSON_INSTANCE(`${GET_USER_ARGUMENTS}?userId=${userId}&debateId=${debateId}`, "Failed to fetch user debate arguments");
    return data;

}
export const UpdateLikeToDebate = async (Debate) => {
  try {
    const { data : { message: data } } = await AXIOS_JSON_INSTANCE.post(
      `${UPDATE_LIKE_DEBATES}`,
      Debate
    );
    return data;
  } catch (error) {
    throw new Error("Failed to add like");
  }
};

