import { Tooltip } from "@material-tailwind/react";
import React from "react";

const RoundedButton = ({
  children,
  SVG,
  className,
  disabled,
  isLoading = false,
  onClick,
  BtnHeight,
  BtnWidth,
  svgClass,
  toolcontent,
  istooltip = false,
}) => {
  return (
    <div>
      <Tooltip content={toolcontent} className={`bg-white bg-opacity-80  text-black border border-black ${!istooltip ? 'hidden' : ''}`}>
      <button
        onClick={onClick}
        className={`rounded-full  shadow-md ${
          isLoading ? "cursor-not-allowed" : "cursor-pointer"
        } hover:shadow-lg flex justify-center items-center ${BtnHeight} ${BtnWidth} ${className} `}
      >
        <SVG className={`text-primary h-10 w-10 ${svgClass}`} />
      </button>
      </Tooltip>
    </div>
  );
};

export default RoundedButton;
