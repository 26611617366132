import React, { useEffect, useState } from "react";
import Container from "../../Components/UI/Container";
import ReusableTabStructure from "../../Components/UI/Tabs/ReusableTabStructure";
import DebateLostList from "./DebateLostList";
import DebateWonList from "./DebateWonList";
import { useSearchParams } from "react-router-dom";

const DebateResultTabs = () => {
  const [query, setQuery] = useSearchParams();
  const defaultSelected = query.get("selected");
  const [selected, setSelected] = useState(defaultSelected);

  useEffect(() => {
    if (defaultSelected) {
      setSelected(defaultSelected);
      setQuery({ selected: defaultSelected });
    }
  }, [defaultSelected, setQuery]);

  const Options = [
    {
      label: "Won",
      value: "won",
      Component: <DebateWonList />,
    },
    {
      label: "Loss",
      value: "loss",
      Component: <DebateLostList />,
    },
  ];

  return (
    <Container>
      <div className="h-full w-full flex flex-col gap-5">
        <ReusableTabStructure
          Options={Options}
          selected={selected}
          setSelected={(newSelected) => {
            setSelected(newSelected);
            setQuery({ selected: newSelected });
          }}
          size={"w-full sm:w-80"}
        />
      </div>
    </Container>
  );
};

export default DebateResultTabs;
