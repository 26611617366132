import { useCallback, useEffect, useRef } from "react";
import ReactCanvasConfetti from "react-canvas-confetti/dist";

const commonOptions = {
  spread: 1000,
  ticks: 200,
  gravity: 2.3,
  decay: 0.9,
  startVelocity: 45,
  particleCount: 500,
  drift: 0,
  shapes: ["star", "circle", "square"],
  scalar: 0.6,
};

const angles = [1];
const doubleAngles = angles.concat(angles);

export default function Confetti({ isVisible }) {
  const instance = useRef();

  const onInit = useCallback(({ confetti }) => {
    instance.current = confetti;
  }, []);

  const realistic = useCallback(() => {
    if (!instance.current) return; // Ensure confetti instance is initialized

    doubleAngles.forEach((angle, index) => {
      instance.current({
        ...commonOptions,
        angle,
      });
    });
  }, []);

  useEffect(() => {
    if (isVisible) {
      realistic();
    }
    // Cleanup function to stop the confetti effect when `isVisible` is false
    return () => {
      if (instance.current) {
        instance.current.reset(); // This will stop the current confetti effect
      }
    };
  }, [isVisible, realistic]);

  return <ReactCanvasConfetti onInit={onInit} autorun={{ speed: 5 }} />;
}
