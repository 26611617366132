import { ChevronRightIcon } from "@heroicons/react/24/outline";
import {
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from "@material-tailwind/react";
import { useState, forwardRef } from "react";

const MenuListRender = forwardRef(({ option }, ref) => {
  const [openMenu, setOpenMenu] = useState(false);

  // Forward ref directly to the first DOM element in the returned JSX
  return option?.subOption?.length > 0 ? (
    <Menu
      placement="right-start"
      open={openMenu}
      handler={setOpenMenu}
      offset={15}
    >
      <MenuHandler>
        <MenuItem
          className="flex items-center gap-2"
          onClick={() => setOpenMenu((prev) => !prev)}
        >
          {option.SVG && <option.SVG className="h-4 w-4" />}
          <Typography
            variant="small"
            className={`font-medium ${
              option.isRedText ? "text-debatePalette-timer" : "text-black"
            }`}
          >
            {option.item}
          </Typography>
          <ChevronRightIcon
            strokeWidth={2.5}
            className={`h-3.5 w-3.5 transition-transform ${
              openMenu ? "rotate-90" : ""
            }`}
          />
        </MenuItem>
      </MenuHandler>
      <MenuList key={option?.value} className="p-1">
        {option.subOption.map((subOption, i) => (
          <MenuItem key={subOption?.value} disabled={subOption?.isLoggedInUser}>
            {subOption.isHRline && <hr className="my-2 border-blue-gray-50" />}
            <div
              className="flex items-center gap-2"
              onClick={(e) => subOption.handleClick(e)}
            >
              {subOption.SVG && <subOption.SVG className="h-4 w-4" />}
              <Typography
                variant="small"
                className={`font-medium ${
                  subOption.isRedText
                    ? "text-debatePalette-timer"
                    : "text-black"
                }`}
              >
                {subOption.item}
              </Typography>
            </div>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  ) : (
    !option?.isLoggedInUser && (
      <MenuItem disabled={option?.isLoggedInUser}>
        {option.isHRline && <hr className="my-2 border-blue-gray-50" />}
        <div
          ref={ref} // Attach the ref to the div, a DOM element
          className="flex items-center gap-2"
          onClick={(e) => option.handleClick(e)}
        >
          {option.SVG && <option.SVG className="h-4 w-4" />}
          <Typography
            variant="small"
            className={`font-medium ${
              option.isRedText ? "text-debatePalette-timer" : "text-black"
            }`}
          >
            {option.item}
          </Typography>
        </div>
      </MenuItem>
    )
  );
});

export default MenuListRender;
