import { RouterProvider } from "react-router-dom";
import { AuthProvider } from "./Context/AuthContext";
import { SubWorkspaceProvider } from "./Context/SubWorkspaceContext";
import { NotificationTabProvider } from "./Context/TabContext";
import { ToggleProvider } from "./Context/ToggleSidebar";
import { WorkspaceProvider } from "./Context/WorkspaceContext";
import { ProfileProvider } from "./Context/openProfileContext";
import { router } from "./Pages/Routes/routes";
import { FileViewerProvider } from "./Context/FileViewerContext";
import { SocketProvider } from "./Context/SocketContext";

function App() {
  return (
    <ToggleProvider>
      <SocketProvider>
        <AuthProvider>
          <WorkspaceProvider>
            <SubWorkspaceProvider>
              <FileViewerProvider>
                <NotificationTabProvider>
                  <ProfileProvider>
                    <RouterProvider router={router} />
                  </ProfileProvider>
                </NotificationTabProvider>
              </FileViewerProvider>
            </SubWorkspaceProvider>
          </WorkspaceProvider>
        </AuthProvider>
      </SocketProvider>
    </ToggleProvider>
  );
}

export default App;
