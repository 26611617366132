import React, { useEffect, useState } from "react";
import { NewsTabBar } from "../../Components/Functionality/NewsTabBar";
import { NewsCard } from "../../Components/UI/Cards/NewsCard";
import { InputFocusBlue, NEWS, NewsTabData } from "../../Utils/Constant";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Input } from "@material-tailwind/react";
import NewsDetail from "./NewsDetail";
import Container from "../../Components/UI/Container";

const News = () => {
  const [selected, setSelected] = useState("all");
  const [selectedCity] = useState("All");
  // const [toggle, setToggle1] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredNews, setFilteredNews] = useState(NEWS);
  const [selectedNews, setSelectedNews] = useState(null);

  useEffect(()=>{
    setSelectedNews(null)
  },[selected])

  useEffect(() => {
    const onSearch = () => {
      if (search === "")
        setFilteredNews(
          NEWS.filter((news) =>
            selectedCity === "All" ? news : selectedCity === news.city
          )
        );
      let filteredData = NEWS.filter((news) =>
        news.title.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredNews(
        filteredData.filter((news) =>
          selectedCity === "All" ? news : selectedCity === news.city
        )
      );
    };

    onSearch();
  }, [search, selectedCity]);


  return (
    <Container >
      <div className="w-full">
          <NewsTabBar
            type="horizontal"
            className={"!overflow-x-scroll lg:!overflow-x-hidden"}
            selected={selected}
            setSelected={setSelected}
            NewsTabData={NewsTabData}
          />
        {!selectedNews && (
          <div className="">
            <div className="my-2 flex flex-col md:flex-row items-center gap-4">
              {" "}
              <div className="hidden items-center gap-x-2 lg:flex">
                <div className="relative flex w-full gap-2 md:w-max">
                  <Input
                    type="search"
                    placeholder="Search"
                    containerProps={{
                      className: "min-w-[300px]",
                    }}
                    className={`!border-t-blue-gray-300 pl-9 placeholder:text-blue-gray-300 focus:!border-blue-gray-300 ${InputFocusBlue} `}
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="!absolute left-3 top-[11px]">
                    <MagnifyingGlassIcon className="h-5 w-5 text-debatePalette-title" />
                  </div>
                </div>
              </div>
              {/* <div className="w-full md:w-[60%] lg:w-[30%]">
            <DropDownMenu
              Items={CITIES}
              iconContent={selectedCity}
              setState={setSelectedCity}
              toggle={toggle}
              setToggle1={setToggle1}
            />
          </div> */}
            </div>
            <div className="mt-2">
              <div className="m-0 w-auto grid grid-cols-1 gap-3 place-items-center">
                {filteredNews.map((news, i) =>
                  selected === "all" ? (
                    <NewsCard news={news} key={i} handleClick={() => setSelectedNews(news)} />
                  ) : (
                    news.category === selected && (
                      <NewsCard news={news} key={i} handleClick={() => setSelectedNews(news)} />
                    )
                  )
                )}
              </div>
            </div>
          </div>
        )}
        {selectedNews && <NewsDetail selectedNews={selectedNews} />}
      </div>
    </Container>
  );
};

export default News;
