import React from 'react'
import EditableBodyPopup from './EditableBodyPopup';
import { Radio } from '@material-tailwind/react';

const SelectFutureDebateOptionPopup = ({handleClose, futureActionPopup, setFutureActionPopup, handleSubmission, futureAction, setFutureAction, loading}) => {
  return (
    <EditableBodyPopup
    open={futureActionPopup}
    handleClose={handleClose}
    title={"Debate action"}
    loading={loading}
    btn1={"Cancel"}
    btn2={"Invite"}
    onClickBtn1={() => {
      setFutureActionPopup(false);
      setFutureAction(null);
    }}
    onClickBtn2={() => handleSubmission("nominate")}
    disableBtn={!futureAction}
    children={
      <div className="flex flex-col gap-2 items-center">
        <p className="text-lg text-debatePalette-bodyText">
          If your invite is not answered within 24 hours, what do you want
          to do with it?
        </p>
        <div className="flex flex-col gap-2">
          <Radio
            color="blue"
            name="choose"
            onClick={(e) => setFutureAction(e.target.value)}
            label={"Save as draft"}
            value={"save"}
            id='save'
          />
          <Radio
            color="blue"
            name="choose"
            onClick={(e) => setFutureAction(e.target.value)}
            label={"Make a open debate"}
            value={"open"}
            id='open'
          />
          <Radio
            color="blue"
            name="choose"
            onClick={(e) => setFutureAction(e.target.value)}
            label={"Invite someone else"}
            value={"invite"}
            id='invite'
          />
        </div>
      </div>
    }
  />
  )
}

export default SelectFutureDebateOptionPopup