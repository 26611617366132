import { Button } from "@material-tailwind/react";
import React from "react";

const UserFollowButton = ({ isFollowing, handleFollow}) => {
  return (
    <div>
      {" "}
      {isFollowing && (
        <Button className="bg-primary text-black text-xs  p-2 px-4" onClick={handleFollow} >
          {'Unfollow'}
        </Button>
      )}
      {!isFollowing && (
        <Button className="bg-primary text-black text-xs p-2 px-4"  onClick={handleFollow}  >
          {"Follow"}
        </Button>
      )}
    </div>
  );
};

export default UserFollowButton;
