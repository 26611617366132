import { useState } from "react";

const useWordLimitedInput = (initialValue = "", wordLimit = 150) => {
  const [content, setContent] = useState(initialValue);

  const handleContentChange = (value) => {
    const inputText = value;
    const words = inputText.trim().split(/\s+/);
    if (words.length <= wordLimit) {
      setContent(inputText);
    } else {
      const limitedWords = words.slice(0, wordLimit).join(" ") + " ";
      setContent(limitedWords);
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const currentWords = content.split(/\s+/).filter((word) => word.length > 0);
    const newWords = pastedText.split(/\s+/).filter((word) => word.length > 0);
    const combinedWords = [...currentWords, ...newWords].slice(0, wordLimit);
    setContent(combinedWords.join(" "));
  };

  return { content, handleContentChange, handlePaste };
};

export default useWordLimitedInput;