import React, { useState } from "react";
import DebateAddCitation from "../DebateAddCitation";
import Logo from "../../../Assets/Images/DB_AI.png";
import AICitationSuggestion from "../../../Components/Functionality/AICitationSuggestion";
import { useCreateDebateContext } from "../../../Context/CreateDebateContext";
import useDebounce from "../../../Services/useDebounce";
import { useCitationSuggestAgent } from "../../../Web-Hooks/Agents/use-citation-agent";

const AddCitationWithAI = () => {
    const { debateInfo, handleStateUpdate, citation } = useCreateDebateContext();
    const argument = useDebounce(debateInfo.CreatorOpeningArgument, 1000);
    const [showAIValidator, setShowAIValidator] = useState(false);
    const [activeBlinkingButton, setActiveBlinkingButton] = useState();
    const { data: citationAgent } = useCitationSuggestAgent(argument);
  return (
    <div>
      <AICitationSuggestion
        open={showAIValidator}
        debateInfo={debateInfo}
        handleClose={() => setShowAIValidator(false)}
        handleStateUpdate={handleStateUpdate}
        pauseAIButtonBlinking={() => setActiveBlinkingButton(false)}
        setActiveBlinkingButton={setActiveBlinkingButton}
        argument={argument}
        citationAgent={citationAgent}
      />
      <div
        className="flex flex-row items-center  w-full"
        onMouseEnter={() => setActiveBlinkingButton(true)}
        onMouseLeave={() => setActiveBlinkingButton(false)}
      >
        <div className="flex flex-col items-end w-full">
          {argument && citation.length < 2 && (
            <button
              id="add-citation-with-ai"
              onClick={() => setShowAIValidator(true)}
              className={`rounded-full text-whiter ${
                activeBlinkingButton ? "animate-blinker" : ""
              }`}
            >
              <img
                src={Logo}
                alt="Logo"
                height="30"
                width="30"
                className="bg-gray-300 rounded-full  shadow-2xl shadow-black"
              />
            </button>
          )}
          <DebateAddCitation argument={argument} />

          {/* {isButtonBlinking("Citation") && (
                <CheckCircleIcon color="green" className="h-7 w-7" />
              )} */}
        </div>
      </div>
    </div>
  );
};

export default AddCitationWithAI;
