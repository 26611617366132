import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useSearchParams } from "react-router-dom";

import CustomSlider from "../../Components/Functionality/CustomSlider";
import DebateHeader from "../../Components/Functionality/DebateHeader";
import DebateChip from "../../Components/UI/Chip/DebateChip";
import Container from "../../Components/UI/Container";
import { useAuth } from "../../Context/AuthContext";
import { useCreateDebateContext } from "../../Context/CreateDebateContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { prepareFormData, ValidateRequiredFields } from "../../Utils/Helper";
import {
  useGetDebateDetails
} from "../../Web-Hooks/Debate/use-debate";
import AddArgumentWithAi from "./../CreateDebate/NewCreateDebate/AddArgumentWithAi";
import AddCategoryWithAI from "./../CreateDebate/NewCreateDebate/AddCategoryWithAI";
import AddCitationWithAI from "./../CreateDebate/NewCreateDebate/AddCitationWithAI";
import AddMotionWithAI from "./../CreateDebate/NewCreateDebate/AddMotionWithAI";

const UpdateDebate = () => {
  const {
    debateInfo,
    handleStateUpdate,
    handleDebateAndCitationAPI,
    isAddCitationPending,
    isAddDebatePending
  } = useCreateDebateContext();
  const { selectedSubWorkspace } = useSubWorkspace();
  const { user } = useAuth();
  const [error, setError] = useState({
    CreatorOpeningArgument: "",
    MotionOrClaim: "",
    Type: "",
    Fields: "",
    SideForDebate: "",
  });

  const location = useLocation();
  const selectedDebate = location?.state?.selectedDebate;
  const [query] = useSearchParams();
  const from = query?.get("from");
  const debateId = query?.get("debateId");
  const { data: debateDetail } = useGetDebateDetails(debateId, user?.userId);

  useEffect(() => {
    if (debateId) {
      handleStateUpdate({
        ...debateInfo,
        ...selectedDebate,
        Fields: Array.isArray(selectedDebate?.Fields)
          ? selectedDebate?.Fields
          : selectedDebate?.Fields?.split(";").map((field) => field.trim()),
        ScalerPoint: debateDetail?.userSideForDebateScalerPoint
          ? JSON.parse(debateDetail?.userSideForDebateScalerPoint)
          : 0,
        CustomRounds:
          debateDetail?.type === "custom"
            ? debateDetail?.totalRound || null
            : null,
        CustomTimeFrame: debateDetail?.timeFrame || null,
        CustomeMaxVotesToWin: debateDetail?.maxVotesToWin || null,
      });
    } // eslint-disable-next-line
  }, [debateDetail]);

  const fieldsToValidate = [
    {
      name: "SideForDebate",
      displayName: "Argument",
      errorMessage: "Please select side for debate",
    },
    {
      name: "CreatorOpeningArgument",
      displayName: "Argument",
      errorMessage: "Please add a creator opening argument",
    },
    {
      name: "MotionOrClaim",
      displayName: "Debate Type",
      errorMessage: "Please enter a motion or claim",
    },
    {
      name: "Type",
      displayName: "Debate Type",
      errorMessage: "Please select a debate type",
    },
    {
      name: "Fields",
      displayName: "Categories",
      errorMessage: "Please add at least one field",
    },
  ];

  const handlePublish = async (draft = false) => {
    const draftFieldsToValidate = fieldsToValidate.filter(
      (field) =>
        field.name !== "CreatorOpeningArgument" &&
        field.name !== "SideForDebate"
    );
    try {
      const isFormValidated = ValidateRequiredFields(
        draft ? draftFieldsToValidate : fieldsToValidate,
        debateInfo,
        setError
      );
      if (!isFormValidated) return;
      const formData = prepareFormData(
        from === "draft"
          ? {
              ...debateInfo,
              debateId: debateId,
            }
          : debateInfo,
        selectedSubWorkspace,
        user,
        true
      );
      await handleDebateAndCitationAPI(formData, false, debateId, () => handleSubmit());
    } catch (error) {
      console.error("Error in handlePublish:", error);
      toast.error(
        "An error occurred while processing your request. Please try again."
      );
    }
  };

  const handleSubmit = () => {
    localStorage.removeItem("Debate");
    localStorage.removeItem("Citation");
    localStorage.removeItem("AllCitation");
  }

  useEffect(() => {
    return () => {
      localStorage.removeItem("Debate");
      localStorage.removeItem("Citation");
      localStorage.removeItem("AllCitation");
    };
  }, []);

  const isDataAdded =
    debateInfo?.MotionOrClaim &&
    debateInfo?.CreatorOpeningArgument &&
    debateInfo?.SideForDebate &&
    debateInfo?.Fields?.length > 0;

  return (
    <Container>
      <div className="w-full md:w-4/4 lg:w-3/4 xl:w-2/4 ">
        <DebateHeader
          content={{
            headLine: "Update Debate",
            page: debateInfo?.Type === "custom" ? 4 : 3,
          }}
        />
        <div className="w-full flex flex-col gap-4">
          <AddCategoryWithAI />
          <AddMotionWithAI error={error} setError={setError} />
          <AddArgumentWithAi error={error} setError={setError} />
          <AddCitationWithAI />
          <div className="flex flex-col items-center gap-6 py-2">
            <CustomSlider
              debateInfo={debateInfo}
              handleStateUpdate={handleStateUpdate}
            />
            <DebateChip
              option={[{ label: "Disagree" }, { label: "Agree" }]}
              label={
                debateInfo?.SideForDebate
                  ? debateInfo?.SideForDebate?.toLowerCase() === "agree"
                    ? "Agree"
                    : "Disagree"
                  : ""
              }
              setLabel={() => {}}
              // setLabel={(vote) => {
              //   handleStateUpdate({ ...debateInfo, SideForDebate: vote });
              //   setError((prev) => ({ ...prev, SideForDebate: "" }));
              // }}
            />
            {error?.SideForDebate && (
              <p className="text-red-500 text-sm font-semibold">
                {error.SideForDebate}
              </p>
            )}
          </div>
          <div className="flex flex-col mt-4 sm:flex-row items-center justify-end w-full">
            <Button
              className={`rounded-md flex items-center justify-center text-debatePalette-title ${
                isDataAdded ? "bg-primary" : "bg-gray-400"
              }`}
              onClick={() => handlePublish(false)}
              disabled={!isDataAdded}
              loading={isAddDebatePending || isAddCitationPending}
            >
              <span className="text-center">Update</span>
            </Button>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default UpdateDebate;
