import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  clearChat,
  deleteMessage,
  getUserExternalData,
  getUserHistory,
  getUserList,
  getUsersList,
} from "../../api/chat/chat";
import toast from "react-hot-toast";

export const useGetUsersList = (userId) => {
  return useQuery({
    queryKey: ["chat-user-list", userId],
    queryFn: async () => {
      return await getUserList(userId);
    },
    select: (data) => data.data.data,
    enabled: !!userId,
  });
};
export const useGetChatHistory = (senderId, receiverId, workId, page, limit, subId) => {
  return useQuery({
    queryKey: ["chat-history", senderId, receiverId,workId, page, limit, subId],
    queryFn: async () => {
      return await getUserHistory(senderId, receiverId, workId, page, limit, subId);
    },
    select: (data) => data.data.data || [],
    enabled: !!senderId && !!receiverId,
  });
};
export const useGetExternalData = (
  userId,
  workId,
  subId = "",
  searchQuery = "",
  enabled,
  selectData = (data) => data.data.data,

) => {
  return useQuery({
    queryKey: ["user-workspace-list", userId, workId, subId, searchQuery],
    queryFn: async () => {
      return await getUserExternalData(userId, workId, subId, searchQuery);
    },
    select: selectData,
    enabled: !!userId && !!workId && enabled,
    // refetchOnWindowFocus: true,
  });
};
export const useDeleteMessageUsingId = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (messageObj) => {
      const { userId, messageId } = messageObj;
      return await deleteMessage(userId, messageId);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries([
        { queryKey: ["chat-history"] },
        { queryKey: ["user-workspace-list"] },
      ]);
      toast.success("Message deleted successfully");
    },
    onError: () => {
      toast.error("Failed to delete message");
    },
  });
};
export const useClearChat = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (chatObj) => {
      const { senderId, receiverId, workspaceId, subworkspaceId } = chatObj;
      return await clearChat(senderId, receiverId,workspaceId, subworkspaceId);
    },
    onSuccess: async () => {
      await queryClient?.invalidateQueries({ queryKey: ["chat-history"] });
      toast.success("chat deleted successfully");
    },
    onError: () => {
      toast.error("Failed to delete message");
    },
  });
};

export const useGetGroupUsersList = (
  groupId,
  enabled,
  selectData = (data) => data.data.data,
) => {
  return useQuery({
    queryKey: ["get-users-list", groupId],
    queryFn: async () => {
      return await getUsersList(groupId);
    },
    select: selectData,
    enabled: !!groupId && enabled,
    // refetchOnWindowFocus: true,
  });
};
