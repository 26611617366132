import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../Assets/Svg/Logo.svg";
import SignupFooter from "../../Components/Functionality/SignupFooter";
import { useAuth } from "../../Context/AuthContext";
import BasicInputField from "../../Layout/BasicInputField";
import { LoginInfo } from "../../Utils/Constant";
import { getInitialsFromEmail, getNameFromEmail } from "../../Utils/Helper";
import betaLogo from "../../Assets/Images/Beta.png";

export default function SignUpMain() {
  const navigate = useNavigate();
  const { initiateOTP } = useAuth();
  const [user, setUser] = useState({
    Email: "",
  });

  const [query] = useSearchParams();
  const Email = query?.get("Email");
  const WorkspaceId = query?.get("WorkspaceId");
  const SubWorkspaceId = query?.get("SubWorkspaceId");
  const [emailError, setEmailError] = useState(false);

  useEffect(() => {
    localStorage.clear();
    if (Email) {
      setUser({ Email: Email });
    } // eslint-disable-next-line
  }, []);
  const signIn = useMutation({
    mutationFn: async (user) => {
      return await initiateOTP(
        user.email,
        null,
        user.workspaceId,
        user.subWorkspaceId
      );
    },
    onSuccess: (response) => {
      if(response.status === 400) return
      const userData = {
        username: getNameFromEmail(user.Email),
        email: user.Email,
        Initials: getInitialsFromEmail(user.Email),
      };
      navigate(`/otp-login?Email=${user.Email}&WorkspaceId=${WorkspaceId}&SubWorkspaceId=${SubWorkspaceId}`, { state: { userData: userData } });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // SignUpp btn Function
  const signUpFn = async (e) => {
    e.preventDefault();
    if (user.Email) {
      signIn.mutate({
        email: user?.Email,
        accessToken: null,
        workspaceId: WorkspaceId,
        subWorkspaceId: SubWorkspaceId,
      });
    } else {
      setEmailError(true);
    }
    // console.log(loginValues, "Login Button Call");
  };

  // Form Change Event
  const handleChange = (e) => {
    const { name, value } = e.target;

    // Email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (name === "Email") {
      // Check if the email is valid
      if (!emailRegex.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }

    // Update user state
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <section className="flex justify-center items-center md:h-screen bg-white">
      <Card className=" flex-col justify-center md:max-h-[600px] shadow-none gap-4 px-4 md:px-10 py-3  border border-debatePalette-buttonBorder">
      <img src={betaLogo} alt="" className="absolute top-0 left-0" style={{ width: 100, height:100 }} />
        {/* Card Header with Logo */}
        <CardHeader
          variant="gradient"
          color="transparent"
          className="grid max-h-20 mt-2  place-items-center shadow-none !static"
        >
          <img src={Logo} alt="" className="max-h-20" />
        </CardHeader>
        <CardBody className="flex flex-col gap-4 p-0">
          {/* TagLines */}
          <div className="flex flex-col text-center justify-center items-center gap-2">
            <Typography className="text-7xl text-debatePalette-title font-semibold">
              Sign Up
            </Typography>
            <Typography className="text-5xl text-debatePalette-title font-semibold">
              {LoginInfo.headLine}
            </Typography>
            <Typography variant="small" className="text-debatePalette-bodyText">
              {LoginInfo.subLine}
            </Typography>
          </div>
          {/* Email Input */}
          <form className="max-w-[100%] py-5 my-6" onSubmit={signUpFn}>
            <div className="flex flex-col w-full justify-center items-center gap-4 p-0">
              <div className="mb-1 flex flex-col w-full md:w-2/3 gap-6">
                <BasicInputField
                  readOnly={Email}
                  value={user?.Email}
                  name="Email"
                  fieldSize="lg"
                  placeholder="name@workemail.com"
                  handleChange={handleChange}
                  className={"!border-debatePalette-buttonBorder"}
                  emailError={emailError}
                  type="email"
                  errorMessage="Please enter valid email address"
                  autoComplete="off"
                />

                {/* Continue Button */}
                <Button
                  id="signupBtn"
                  disabled={emailError || !user?.Email}
                  loading={signIn.isPending}
                  type="submit"
                  variant="gradient"
                  fullWidth
                  color="amber"
                  className="text-black text-md inline-flex items-center justify-center w-full"
                >
                  Continue
                </Button>
              </div>
            </div>
          </form>
        </CardBody>
        {/* Footer */}
        <CardFooter className="pt-0 mt-5">
          <SignupFooter info={LoginInfo} />
        </CardFooter>
      </Card>
    </section>
  );
}
