import React from "react";
import { useAuth } from "../../Context/AuthContext";
import { useGetSearchedUserList } from "../../Web-Hooks/Search/use-search";
import LoadingSpinner from "../../Components/UI/LoadingSpinner";
import NoData from "../../Components/Functionality/NoData";
import UserCard from "../../Components/UI/Cards/UserCard";

const SearchUsers = ({ query }) => {
  const { user: loggedInUser } = useAuth();
  const { data: userList, isLoading: isUserListLoading } =
    useGetSearchedUserList(loggedInUser?.userId, encodeURIComponent(query));
  return (
    <div className="w-full h-full">
      {isUserListLoading ? (
        <LoadingSpinner />
      ) : userList?.length > 0 ? (
        <div className="flex gap-4 flex-wrap">
          {userList.map((user, i) => (
            <UserCard key={user?.userId} user={user} />
          ))}
        </div>
      ) : (
        <NoData message={"No Users Found"} size={"w-full h-[80%]"} />
      )}
    </div>
  );
};

export default SearchUsers;
