import { BellIcon, PlusIcon } from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import {
  Avatar,
  Badge,
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
} from "@material-tailwind/react";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ImageOrInitials from "../../Components/Functionality/ImageOrInitials";
import { SideBarMenu } from "../../Components/UI/Menus/SideBarMenu";
import { useAuth } from "../../Context/AuthContext";
import { useSubWorkspace } from "../../Context/SubWorkspaceContext";
import { useWorkspace } from "../../Context/WorkspaceContext";
import { DebateOptions } from "../../Utils/Constant";
import { getInitials } from "../../Utils/Helper";
import { useGetUserById } from "../../Web-Hooks/Profile/use-profile";
import { useGetNotificationByUserId } from "../../Web-Hooks/Notification/use-notification";
import { getSubWorkspaceByWorkId } from "../../api/subworkspace/subworkspace";

const Sidebar = ({ isNewWorkSpace, openProfile }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const { user: loggedInUser, handleSetUser } = useAuth();
  const { workspaceList, selectWorkspace, selectedWorkspace } = useWorkspace();
  const { selectSubWorkspace, selectedSubWorkspace, subWorkspaceList, selectWorkspaceId } =
    useSubWorkspace();
  const { data: profileDetail } = useGetUserById(
    loggedInUser?.userId,
    selectedSubWorkspace?.subWorkSpaceId,
    selectedWorkspace?.workSpaceId
  );

  const { data } = useGetNotificationByUserId(loggedInUser?.userId);

  useEffect(() => {
    if (profileDetail) {
      handleSetUser({
        userName: profileDetail?.userName,
        role: profileDetail?.subworkspaceRole,
        userImage: profileDetail?.userImage,
      });
    }
    // eslint-disable-next-line
  }, [profileDetail]);

  const handleSelectWorkspace = async (workspace) => {
    selectWorkspace(workspace);

    if (workspace) {
      const subworkspaceList = await getSubWorkspaceByWorkId(
        workspace.workSpaceId,
        loggedInUser?.userId
      );
  
      const subworkspace = subworkspaceList?.listOfSubworkspaces[0];
      if (subworkspace) {
        selectWorkspaceId(workspace.workSpaceId);
        selectSubWorkspace(subworkspace);
      }
      navigate("/feed-public", { replace: true });
    }
  };

  const userCreatedWorkspace = workspaceList?.filter(
    (workspace) => workspace?.createdBy?.userId === loggedInUser?.userId
  );

  const pilotPlanRestriction = userCreatedWorkspace?.length >= 3;
  // const pilotPlanRestriction = false;


  return (
    <section className="h-full w-full bg-sideLayoutPrimary py-2">
      {!isNewWorkSpace && (
        <>
          {" "}
          <div className="flex md:flex-col flex-row h-full items-stretch justify-between">
            <div className="align-top">
              <div className="flex md:flex-col items-center md:item-start gap-4 md:gap-0 ">
                <Menu placement="bottom">
                  <MenuHandler>
                    {selectedWorkspace?.userImage === "" ||
                    !selectedWorkspace?.userImage ? (
                      <Button className="relative align-middle select-none font-sans font-medium text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none w-12 max-w-[100px] h-12 max-h-[100px] text-xs bg-white text-secondary shadow-md shadow-gray-900/10 hover:shadow-lg hover:shadow-gray-900/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none rounded-full">
                        <Tooltip
                          className="bg-debatePalette-background text-debatePalette-title border"
                          content={"debatebase"}
                        >
                          <span className="absolute transform -translate-x-1/2 font-bold text-lg -translate-y-1/2 top-1/2 left-1/2">
                            {selectedWorkspace?.workSpaceName &&
                              getInitials(selectedWorkspace?.workSpaceName)}
                          </span>
                        </Tooltip>
                      </Button>
                    ) : (
                      <Avatar
                        src={selectedWorkspace?.userImage}
                        className="h-12 w-12 rounded-full obj p-1 shadow-md"
                        alt="Profile"
                      />
                    )}
                  </MenuHandler>
                  <MenuList className="">
                    <div className="gap-3 max-h-48 overflow-y-auto hover:border-none hover:outline-none">
                      {workspaceList &&
                        workspaceList?.map((workspace) => (
                          <MenuItem
                            key={workspace?.workSpaceName}
                            className="flex items-center p-1 justify-between gap-3 min-w-60"
                            onClick={() => handleSelectWorkspace(workspace)}
                          >
                            <div className="flex items-center p-1 justify-start gap-3">
                              {workspace?.profileImage ? (
                                <Avatar
                                  src={workspace?.profileImage}
                                  alt={workspace?.username}
                                  className="h-10 w-10"
                                />
                              ) : (
                                <div className="inline-flex items-center justify-center h-10 w-10 uppercase font-bold shadow-lg border border-primary-basic text-secondary bg-white  rounded-full">
                                  {getInitials(workspace?.workSpaceName)}
                                </div>
                              )}
                              <p className="text-md font-semibold text-secondary xs:line-clamp-1  max-w-[180px]">
                                {workspace?.workSpaceName}
                              </p>
                            </div>
                          </MenuItem>
                        ))}
                    </div>
                    {!pilotPlanRestriction && (
                      <>
                      <hr className="my-3" />
                      <MenuItem
                        className="flex items-center p-1 justify-start gap-3 min-w-60"
                        onClick={() => {
                          navigate("/createworkspace");
                        }}
                      >
                        {
                          <PlusIcon className="h-10 w-10 text-debatePalette-background rounded-full bg-secondary" />
                        }
                        <p className="text-md font-semibold text-secondary">
                          Add a debatebase
                        </p>
                      </MenuItem>
                      </>
                    )}
                  </MenuList>
                </Menu>
                <div className="hidden md:block mt-4">
                  <Badge content={data?.notificationCount} invisible={data?.notificationCount === 0}>
                  <Button
                    variant="text"
                    ripple={false}
                    className="border-none p-2"
                    onClick={() => navigate("/notification")}
                  >
                    <Tooltip
                      content="Notification"
                      className="bg-white text-black border border-black"
                    >
                      <BellIcon className="h-8 w-8 text-debatePalette-background" />
                    </Tooltip>
                  </Button>
                  </Badge>
                </div>
              </div>
            </div>
            <div className="align-bottom justify-center items-center flex md:flex-col flex-row gap-2">
              {subWorkspaceList?.length !== 0 && (
                <SideBarMenu
                  isArrow={false}
                  label={
                    <PlusCircleIcon className="w-14 h-14 text-debatePalette-background text-center" />
                    // <RoundedButton
                    //   SVG={PlusIcon}
                    //   BtnWidth={"w-12"}
                    //   BtnHeight={"h-12"}
                    //   className={"md:bg-blue-gray-800 bg-white/15 "}
                    //   svgClass={"!text-debatePalette-background"}
                    // />
                  }
                  options={DebateOptions}
                  handleClick={(route, selectedDebate) =>
                    navigate(route, {
                      state: { selectedDebate: selectedDebate },
                    })
                  }
                />
              )}

              <div onClick={openProfile} className="p-0 cursor-pointer">
                <ImageOrInitials
                  imageSrc={
                    profileDetail?.userImage ? profileDetail?.userImage : null
                  }
                  initials={
                    profileDetail?.userName
                      ? profileDetail?.userName
                      : profileDetail?.email
                  }
                  size="h-12 w-12"
                />
              </div>

              {/* <RoundedButton
                istooltip={`${true}`}
                toolcontent={"Profile"}
                SVG={UserIcon}
                BtnHeight={"h-12"}
                BtnWidth={"w-12"}
                className={"bg-white"}
                onClick={openProfile}
              /> */}
            </div>
          </div>
          {/* <MobileFeedBar closeDrawer={closeDrawer} />
          </Drawer> */}
        </>
      )}
      {isNewWorkSpace && (
        <div className="w-12">
          {" "}
          {pathname !== "/createprofile" ? (
            <ImageOrInitials initials={selectedWorkspace?.workSpaceName} />
          ) : null}
        </div>
      )}
    </section>
  );
};

export default Sidebar;
