import React from "react";
import { Comment, TailSpin, ThreeDots } from "react-loader-spinner";

const LoadingSpinner = ({ type, color, height, width }) => {
  const renderLoader = () => {
    switch (type) {
      case "ThreeDots":
        return (
          <ThreeDots
            visible={true}
            height={height}
            width={width}
            color={color}
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        );
      case "Comment":
        return (
          <Comment
          visible={true}
          height={height}
          width={width}
          color={color}
          ariaLabel="comment-loading"
          wrapperStyle={{}}
          wrapperClass="comment-wrapper"
          backgroundColor="#008bfa"
          />
        );
      default:
        return (
          <TailSpin
            visible={true}
            height={height}
            width={width}
            color={color}
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        );
    }
  };
  return <div className="loading-spinner w-full h-full flex justify-center items-center">{renderLoader()}</div>;
};

export default LoadingSpinner;
