import { ArrowRightIcon, TrashIcon } from "@heroicons/react/24/solid";
import {
  Card,
  CardBody,
  IconButton,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import DocImage from "../../../Assets/Images/doc.png";
import PDFImage from "../../../Assets/Images/pdf.png";
import URLImage from "../../../Assets/Images/url.png";
import VIDImage from "../../../Assets/Images/video.png";
import { ReactComponent as DoubleChecked } from "../../../Assets/Svg/DoubleChecked.svg";
import { ReactComponent as SingleChecked } from "../../../Assets/Svg/SibgleChecked.svg";
import ImageOrInitials from "../../../Components/Functionality/ImageOrInitials";
import { useAuth } from "../../../Context/AuthContext";
import { useFileViewer } from "../../../Context/FileViewerContext";
import { useSocket } from "../../../Context/SocketContext";
import {
  formatTimestampToReadableDate,
  getUserImageUrl,
  isTodayOrYesterday,
} from "../../../Utils/Helper";
import { notifyCss } from "../../../Utils/Constant";

const GroupMessageCard = ({ message, group, members }) => {
  const { handleFile } = useFileViewer();
  const { content, senderId, timestamp } = message;
  const { deleteMessage } = useSocket();
  const { user } = useAuth();
  const sender = senderId;

  const getSenderName = (id) => {
    const sender = members?.find((member) => member.id === id);
    return sender?.username;
  };

  


  const renderContent = () => {
    switch (content?.type) {
      case "text":
        return <span>{content?.content?.text}</span>;

      case "image":
        return (
          <img
            src={content?.content?.url}
            className="w-72 sm:w-80 lg:w-96 cursor-zoom-in"
            alt="message-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "image",
                fileName: content?.content?.url?.split("/").pop(),
                createdBy: sender,
              });
            }}
          />
        );

      case "doc":
        return (
          <img
            src={DocImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="document-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "document",
                fileName: content?.content?.url?.split("/").pop(),
                createdBy: sender,
              });
            }}
          />
        );

      case "pdf":
        return (
          <img
            src={PDFImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="pdf-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "pdf",
                fileName: content?.content?.url?.split("/").pop(),
                createdBy: sender,
              });
            }}
          />
        );

      case "video":
        return (
          <img
            src={VIDImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="video-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "video",
                fileName: content?.content?.url?.split("/").pop(),
                createdBy: sender,
              });
            }}
          />
        );

      case "url":
        return (
          <img
            src={URLImage}
            className="inline max-w-[100px] max-h-[100px] cursor-zoom-in"
            alt="video-img"
            onClick={() => {
              handleFile({
                fileUrl: content?.content?.url,
                fileType: "video",
                fileName: content?.content?.url?.split("/").pop(),
                createdBy: sender,
              });
            }}
          />
        );
      case "profile":
        return (
          <div className="flex flex-col gap-2">
            <Link
              to={`/profile/${content?.content?.userId}?userName=${content?.content?.userName}`}
              className="flex items-center gap-x-2 text-sm text-debatePalette-link hover:underline"
            >
              {`http://localhost:3000/profile/${content?.content?.userId}?userName=${content?.content?.userName}`}
            </Link>
            <Card className="w-72 sm:w-80 lg:w-96">
              <CardBody className="p-3">
                <div
                  key={content?.content?.userId}
                  className="flex items-center justify-between"
                >
                  <div className="flex items-center gap-x-3">
                    <ImageOrInitials
                      imageSrc={content?.senderAvatar}
                      initials={content?.senderUsername}
                      className="shrink-0"
                    />
                    <div>
                      <Typography color="blue-gray" variant="h6">
                        {content?.content?.userName}
                      </Typography>
                      <Typography
                        variant="small"
                        className="truncate  text-secondary max-w-[250px] line-clamp-1 overflow-y-scroll"
                      >
                        {content?.content?.workspaceName}
                      </Typography>
                      <Link
                        to={`/profile/${content?.content?.userId}?userName=${content?.content?.userName}`}
                        className="flex items-center gap-x-2 text-sm text-debatePalette-link hover:underline"
                      >
                        View Profile <ArrowRightIcon className="size-4" />
                      </Link>
                    </div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        );

      case "debate":
        return (
          <Card className="min-w-72 sm:min-w-80 lg:min-w-96">
            <CardBody className="p-3">
              <div
                key={content?.content?.userId}
                className="flex items-center justify-between"
              >
                <div className="flex items-center gap-x-3">
                  <ImageOrInitials
                    imageSrc={content?.content?.userImage}
                    initials={content?.content?.userName}
                    className="shrink-0"
                  />
                  <div>
                    <Typography color="blue-gray" variant="h6">
                      {content?.content?.userName}
                    </Typography>
                    <Link
                      to={`/debate/${content?.content?.debateId}?round=${content?.content?.currentRound}`}
                      className="truncate text-secondary text-normal max-w-[250px] line-clamp-1 overflow-y-scroll"
                    >
                      {content?.content?.motion}
                    </Link>
                    <div className="flex gap-2 items-center ">
                      <Typography
                        variant="small"
                        className="truncate text-xs text-secondary max-w-[250px] line-clamp-1 overflow-y-scroll"
                      >
                        Posted in {content?.content?.workspaceName}
                      </Typography>
                      |
                      <Typography
                        variant="small"
                        className="truncate text-xs text-secondary max-w-[250px] line-clamp-1 overflow-y-scroll"
                      >
                        {formatTimestampToReadableDate(
                          content?.content?.createAt
                        )}
                      </Typography>
                      |
                      <Link
                        onClick={() => {}}
                        to={`/debate/${content?.content?.debateId}?round=${content?.content?.currentRound}`}
                        className="flex items-center text-xs text-debatePalette-link hover:underline"
                      >
                        View Debate
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        );
      default:
        return <span>Unsupported message type</span>;
    }
  };

  const renderGroupContent = () => {
    switch (content?.type) {
      case "user_added":
        return <span className={notifyCss}>{content?.content?.userName} has been added by {getSenderName(content?.content?.addedByUserId)}</span>;
      case "user_removed":
        return <span className={notifyCss}>{content?.content?.userName} has been removed by {getSenderName(content?.content?.removedByUserId)}</span>;
      case "user_left":
        return <span className={notifyCss}>{content?.content?.userName} has left the group</span>;
      case "group_updated":
        return <span className={notifyCss}>Group has been updated by {getSenderName(content?.content?.updatedByUserId)}</span>;
      default:
        return <span>Unsupported message type</span>;
    }
  };

  const isAllUserRead = () => {
    if (!message?.readBy) return false;
    return group?.users?.every((user) => message?.readBy.includes(user));
  };

  const isAllRead = isAllUserRead();

  const RenderMainContent = () => {
    switch (content?.type) {
      case "text":
      case "doc":
      case "pdf":
      case "image":
      case "profile":
      case "debate":
      case "url":
      case "video":
        return (
          <Fragment>
            {sender !== user.userId && (
              <div className="shrink-0 mr-4">
                <ImageOrInitials
                  imageSrc={getUserImageUrl(
                    message?.senderId,
                    message?.senderAvatar
                  )}
                  initials={message?.senderUsername}
                />
              </div>
            )}
            <div className="flex gap-2 group ">
              <div
                className={`message-bubble  ${
                  sender === user.userId ? "sent" : "received"
                }`}
              >
                {renderContent()}
                <sub className="flex flex-row ml-2 gap-1 justify-end items-end py-1 shrink-0">
                  <span className="text-xs">
                    {isTodayOrYesterday(timestamp) === "Today"
                      ? moment(timestamp).fromNow()
                      : moment(timestamp).format("DD MMM [at] HH:mm")}
                  </span>
                  {senderId === user.userId &&
                    (isAllRead ? (
                      <DoubleChecked className="w-4 text-debatePalette-background h-4" />
                    ) : (
                      <SingleChecked className="w-4 text-debatePalette-background h-4 " />
                    ))}
                </sub>
              </div>
              {sender === user.userId && (
                <div className="hidden justify-end shrink-0 mr-4 group-hover:flex ">
                  <IconButton
                    className="p-0"
                    variant="text"
                    size="sm"
                    onClick={handleDeleteMessage}
                  >
                    <TrashIcon className="h-4 w-4 text-debatePalette-timer" />
                  </IconButton>
                </div>
              )}
            </div>
          </Fragment>
        );
      case "user_added":
      case "user_removed":
      case "user_left":
      case "group_updated":
        return (
          <div className="flex justify-center w-full">
            {renderGroupContent()}
          </div>
        );
      default:
        return (
          <div className="flex justify-center w-full">
            <span>can't render</span>
          </div>
        );
    }
  };

  const handleDeleteMessage = async () => {
    await deleteMessage(senderId, group?.userId, message.id);
  };
  return (
    <div
      className={`message-container w-full ${
        sender === user.userId ? "justify-end" : "justify-start"
      }`}
      key={message.id}
    >
      {RenderMainContent()}
    </div>
  );
};

export default GroupMessageCard;
