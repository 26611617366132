import axios from "axios";
import { CREATE_GROUP_CHAT, GET_GROUP_CHAT, GET_USER_GROUP_LIST, LEAVE_GROUP, REMOVE_GROUP, UPDATE_GROUP } from "../chatApiRoutes";

const baseUrl = process.env.REACT_APP_CHAT_URL

export const createGroupChat = async (data) => {
    const response  = await axios.post(`${baseUrl}${CREATE_GROUP_CHAT}`, data);
    return response;
}
export const updateGroupChat = async (groupId, data) => {
    const response  = await axios.patch(`${baseUrl}${UPDATE_GROUP}/${groupId}`, data);
    return response;
}
export const addMember = async (groupId, data) => {
    const response  = await axios.post(`${baseUrl}${UPDATE_GROUP}/${groupId}`, data);
    return response;
}
export const removeMember = async (groupId, removerId, userId) => {
    const response  = await axios.delete(`${baseUrl}${REMOVE_GROUP}/${groupId}/${removerId}/${userId}`);
    return response;
}
export const leaveGroup = async (groupId, userId) => {
    const response  = await axios.delete(`${baseUrl}${LEAVE_GROUP}/${groupId}/${userId}`);
    return response;
}

export const getGroupList = async (userId, workId, subId) => {
    const data  = await axios.get(`${baseUrl}${GET_USER_GROUP_LIST}/${userId}/${workId}?subworkspaceId=${subId}`);
    return data;
}

export const getGroupHistory = async (groupId, page, limit) => {
    const data  = await axios.get(`${baseUrl}${GET_GROUP_CHAT}/${groupId}`, "Failed to fetch chat history");
    return data;
}