import { HeartIcon } from "@heroicons/react/24/outline";
import {
  HeartIcon as HeartFilled,
  PencilSquareIcon,
  TrashIcon
} from "@heroicons/react/24/solid";
import {
  Avatar,
  Button,
  Card,
  CardBody,
  Typography,
} from "@material-tailwind/react";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import DocImage from "../../Assets/Images/doc.png";
import PDFImage from "../../Assets/Images/pdf.png";
import URLImage from "../../Assets/Images/url.png";
import VideoImage from "../../Assets/Images/video.png";
import { useFileViewer } from "../../Context/FileViewerContext";
import {
  getFileNameFromURL,
  getFileType,
  getLocalTime,
  getUTCDate,
} from "../../Utils/Helper";

import toast from "react-hot-toast";
import { useWorkspace } from "../../Context/WorkspaceContext";
import {
  useDeleteReference,
  useSaveReference,
} from "../../Web-Hooks/Reference/use-reference";

const ReferenceCard = ({ reference, user, setSelectedReference }) => {
  const fileType = getFileType(`hello.${reference?.referenceType}`);
  const Src =
    fileType === "doc"
      ? DocImage
      : fileType === "pdf"
      ? PDFImage
      : reference?.referenceType === "url"
      ? URLImage
      : fileType === "video"
      ? VideoImage
      : reference?.referenceUrl;
  const fileTypeImage =
    fileType !== "doc" &&
    fileType !== "pdf" &&
    reference?.referenceType !== "url" &&
    fileType !== "video";

  const { handleFile } = useFileViewer();
  const {
    mutateAsync: addSaveReference,
    isPending: isAddSaveReferencePending,
  } = useSaveReference();
  const { mutateAsync: deleteReference } = useDeleteReference();
  const { selectedWorkspace } = useWorkspace();

  const selectFileToView = () => {
    if (reference.referenceType === "url") {
      window.open(reference?.referenceUrl, "_blank");
      return;
    }
    handleFile({
      fileUrl: reference?.referenceUrl,
      fileType: reference?.referenceType,
      fileName: getFileNameFromURL(reference?.referenceUrl),
      fileUploadedAt: getLocalTime(reference?.createdAt),
      createdBy: "",
      creatorImage: Src,
      channelName: "",
    });
  };

  const handleSave = (type) => {
    const saveObj = {
      ReferenceId: reference?.referenceId,
      WorkspaceId: selectedWorkspace?.workSpaceId,
      // SubworkspaceId: selectedSubWorkspace?.subWorkSpaceId,
      UserId: user?.userId,
      IsReferenceSave: type, // pass false for remove from list
      createdAt: getUTCDate(),
    };
    addSaveReference(saveObj).then(() => {
      if (type) {
        toast.success("Reference Saved");
      } else {
        toast.success("Reference Removed");
      }
    });
  };

  const handleDelete = () => {
    const deleteObj = {
      ReferenceId: reference?.referenceId,
      UserId: user?.userId,
    };
    deleteReference(deleteObj)
      .then(() => {
        toast.success("Reference Deleted");
      })
      .catch((err) => {
        toast.error("Failed to delete reference");
      });
  };

  return (
    <Card
      color="transparent"
      shadow={false}
      className="flex lg:flex-row gap-2 w-full border cursor-pointer hover:shadow-lg transition-shadow duration-200 ease-in-out"
    >
      <CardBody className="h-40 w-40 flex-shrink-0 p-3">
        <div
          className="h-full border cursor-zoom-in shadow-sm rounded-md flex items-center justify-center"
          onClick={selectFileToView}
        >
          <Avatar
            variant="rounded"
            src={Src}
            alt={reference?.referenceType}
            className={`rounded-md object-contain ${
              fileTypeImage ? "h-full w-full" : "h-28 w-28"
            }`}
          />
        </div>
      </CardBody>
      <CardBody className="flex flex-col p-3 gap-3 justify-between overflow-hidden lg:flex-1">
        <div className="flex flex-col gap-2">
          <div className="flex justify-between w-full">
            <Typography
              variant="h6"
              className="font-semibold text-debatePalette-title line-clamp-2"
            >
              {reference?.title}
            </Typography>
            <div className="flex gap-3 item-center">
            <PencilSquareIcon
              className="h-6 w-6 text-secondary"
              onClick={() => {
                setSelectedReference({
                  referenceId: reference?.referenceId,
                  WorkspaceId: reference?.workspaceId,
                  SubworkspaceId: reference?.subworkspaceId,
                  CreatedByUserId: user?.userId,
                  Title: reference?.title,
                  Description: reference?.title,
                  ReferenceUrl: reference?.referenceUrl,
                  ReferenceType: reference?.referenceType,
                  CreatedAt: reference?.createdAt,
                });
              }}
            />
            <Button
              className="flex gap-4 p-0 hover:bg-transparent"
              variant="text"
              ripple={false}
              disabled={isAddSaveReferencePending}
            >
              {reference?.isReferenceSaved ? (
                <HeartFilled
                  className="h-6 w-6 text-secondary"
                  onClick={() => handleSave(false)}
                />
              ) : (
                <HeartIcon
                  className="h-6 w-6"
                  onClick={() => handleSave(true)}
                />
              )}
            </Button>
            <TrashIcon
              className="h-6 w-6 text-debatePalette-timer"
              onClick={() => handleDelete()}
            />
            </div>
          </div>
          <Typography
            variant="paragraph"
            className="text-sm text-debatePalette-bodyText sm:line-clamp-4 lg:line-clamp-3"
          >
            {reference?.description}
          </Typography>
        </div>
        <div className="flex justify-between w-full self-end">
          <Link
            className="text-sm text-blue-500 hover:underline truncate"
            onClick={selectFileToView}
          >
            {reference?.referenceUrl}
          </Link>
          <Typography
            variant="paragraph"
            className="text-xs text-debatePalette-bodyText text-nowrap"
          >
            {moment(reference?.createdAt).format("DD-MMM-YYYY")}
          </Typography>
        </div>
      </CardBody>
    </Card>
  );
};

export default ReferenceCard;
