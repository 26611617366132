import { Textarea } from "@material-tailwind/react";
import React from "react";
import { CountWords } from "../../../Utils/Helper";

export default function ArgumentEditorTextArea({
  debateInfo,
  handleStateUpdate,
  shadowNormal,
  wordLimit = 500,
}) {
  // const handleChange = (value) => {
  //   // Split the text by spaces to count the words
  //   const words = value.split(/\s+/).filter((word) => word.length > 0); // Filter out any empty words

  //   // Check if the word count exceeds 500
  //   if (words.length >= maxLength) {
  //     // Truncate the value to the first 500 words
  //     const truncateValue = words.slice(0, maxLength).join(" ");
  //     handleStateUpdate({
  //       ...debateInfo,
  //       CreatorOpeningArgument: truncateValue,
  //     });
  //     return;
  //   } else {
  //     // If it's within the limit, just update the state as usual
  //     handleStateUpdate({
  //       ...debateInfo,
  //       CreatorOpeningArgument: value,
  //     });
  //   }
  // };
  const handleContentChange = (value) => {
    const inputText = value;
    const words = inputText.trim().split(/\s+/);
    if (words.length <= wordLimit) {
      handleStateUpdate({
        ...debateInfo,
        CreatorOpeningArgument: inputText,
      });
      return;
    } else {
      const limitedWords = words.slice(0, wordLimit).join(" ") + " ";
      handleStateUpdate({
        ...debateInfo,
        CreatorOpeningArgument: limitedWords,
      });
    }
  };

  return (
    <div className="relative overflow-auto !h-full">
      <Textarea
        id="argumentEditorTextArea"
        resize={true}
        className={` !border-2 text-debatePalette-title text-base !border-debatePalette-buttonBorder mr-3 ${shadowNormal} focus:!border-debatePalette-shadow focus:!shadow-[0_1px_4px_1.5px_rgba(0,_139,_250,_0.25)]`}
        containerProps={{
          className: "grid h-full",
        }}
        labelProps={{
          className: "before:content-none after:content-none",
        }}
        value={debateInfo?.CreatorOpeningArgument}
        onChange={(e) => {
          handleContentChange(e.target.value);
        }}
      />
      <span className="absolute bottom-0  right-3 text-sm ">
        {wordLimit - CountWords(debateInfo?.CreatorOpeningArgument)}
      </span>
    </div>
  );
}
