import { Typography } from "@material-tailwind/react";
import React from "react";
import ImageOrInitials from "../../Functionality/ImageOrInitials";
import { getUserImageUrl } from "../../../Utils/Helper";

const NewMessageCard = ({ user }) => {
  return (
    <div className="flex gap-4 truncate justify-between w-full">
      <div className="flex gap-4 truncate">
      <ImageOrInitials
        imageSrc={getUserImageUrl(user?.id, user?.avatar)}
        initials={user?.username || user?.email}
        showUserStatus={true}
        userStatus={user?.online}
      />

      <div className="flex flex-col">
        <Typography>{user?.username || user?.email?.split("@")[0]}</Typography>
        <Typography>{user?.email}</Typography>
      </div>
      </div>

      {/* <div id="action-message-btn">
        {
          <Button className="border-secondary" variant="outlined">
            Message
          </Button>
        }
      </div> */}
    </div>
  );
};

export default NewMessageCard;
