import { ADD_SUBWORKSPACE, ADD_USER_IN_SUBWORKSPACE, ADD_USERS_IN_SUBWORKSPACE, ADD_USERS_IN_SUBWORKSPACE_EMAILS, EDIT_USERS_IN_SUBWORKSPACE, GET_ALL_SUBWORKSPACE, GET_SUBWORKSPACE, GET_USERS_IN_SUBWORKSPACE, REMOVE_USER_FROM_SUBWORKSPACE, UPDATE_SUBWORKSPACE } from "../apiRoutes";
import AXIOS_INSTANCE from "../axiosInstance";
import { getDataINSTANCE } from "../controller";

export const getSubWorkspace = async () => {
    const data = await getDataINSTANCE(GET_ALL_SUBWORKSPACE,"Failed to fetch subworkspaces");
    return data;

};

export const getSubWorkspaceByWorkId = async (Id,userId) => {
    const data = await getDataINSTANCE(`${GET_SUBWORKSPACE}?workSpaceId=${Id}&userId=${userId}`,"Failed to fetch subworkspaces");
    return data;

}

export const addSubWorkspace = async (subworkspace) => {
  try {
    const data = await AXIOS_INSTANCE.post(ADD_SUBWORKSPACE, subworkspace);
    return data;
  } catch (error) {
    throw new Error("Failed to add subworkspace");
  }
};

export const updateSubWorkspace = async (updatedWorkspace) => {
  try {
    const data = await AXIOS_INSTANCE.post(`${UPDATE_SUBWORKSPACE}`, updatedWorkspace);
    return data;
  } catch (error) {
    throw new Error("Failed to update subworkspace");
  }
};

export const getSubWorkspaceById = async (subWorkspaceId) => {
    const data = await getDataINSTANCE(`${GET_SUBWORKSPACE}/${subWorkspaceId}`,"Failed to fetch subworkspace by id");
    return data;

};

export const getUsersBySubworkspaceID = async (Id, userId,search) => {
    const data = await getDataINSTANCE(`${GET_USERS_IN_SUBWORKSPACE}?subWorkSpaceId=${Id}&loginUserId=${userId}&keyword=${search}`,"Failed to fetch Sub Workspaces Users");
    return data;
}



export const deleteUserFromSubWorkspace = async (DeleteUser) => {
  try {
    const data = await AXIOS_INSTANCE.post(`${REMOVE_USER_FROM_SUBWORKSPACE}`, DeleteUser);
    return data;
  } catch (error) {
    throw new Error("Failed to delete Sub Workspaces Users");
  }
}
export const AddNewUsersToSubWorkSpace = async (User) => {
  try {
    const data = await AXIOS_INSTANCE.post(`${ADD_USERS_IN_SUBWORKSPACE}`, User);
    return data;
  } catch (error) {
    throw new Error("Failed to add Sub Workspaces Users");
  }
}

export const AddNewUserToSubWorkSpace = async (User) => {
  try {
    const data = await AXIOS_INSTANCE.post(`${ADD_USER_IN_SUBWORKSPACE}`, User);
    return data;
  } catch (error) {
    throw new Error("Failed to add Sub Workspaces Users");
  }
}

export const EditUserToSubWorkSpace = async (User) => {
  try {
    const data = await AXIOS_INSTANCE.post(`${EDIT_USERS_IN_SUBWORKSPACE}`, User);
    return data;
  } catch (error) {
    throw new Error("Failed to edit Sub Workspaces Users");
  }
}

export const AddNewUserToSubWorkSpaceByEmails = async (User) => {
  try {
    const data = await AXIOS_INSTANCE.post(`${ADD_USERS_IN_SUBWORKSPACE_EMAILS}`, User);
    return data;
  } catch (error) {
    throw new Error("Failed to add Sub Workspaces Users");
  }
}