// SignIn.js
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import { useMutation } from "@tanstack/react-query";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Apple from "../../Assets/Svg/Apple.svg";
import Google from "../../Assets/Svg/Google.svg";
import Logo from "../../Assets/Svg/Logo.svg";
import SignupFooter from "../../Components/Functionality/SignupFooter";
import { useAuth } from "../../Context/AuthContext";
import BasicInputField from "../../Layout/BasicInputField";
import { authApple, providerApple } from "../../Services/Apple-Signin/provider";
import { auth, provider } from "../../Services/Google-Signin/config";
import { LoginInfo, hoverTextStyle } from "../../Utils/Constant";
import { getInitialsFromEmail, getNameFromEmail } from "../../Utils/Helper";
import betaLogo from "../../Assets/Images/Beta.png";

export default function SignIn() {
  const location = useLocation();
  const { search: searchParams } = location;
  const query = new URLSearchParams(searchParams);
  const queryEmail = query?.get("Email");
  const queryWorkspaceId = query?.get("WorkspaceId");
  const querySubWorkspaceId = query?.get("SubWorkspaceId");
  const navigate = useNavigate();
  const { initiateOTP } = useAuth();
  const [user, setUser] = useState({ Email: queryEmail || "" });
  const [emailError, setEmailError] = useState(false);
  const isAuthenticated = localStorage.getItem("user");

  const signIn = useMutation({
    mutationFn: async (user) => {
      return initiateOTP(user?.email, user?.accessToken);
    },

    onSuccess: (response) => {
      if(response.status === 400) return
      const userData = {
        username: getNameFromEmail(user.Email),
        email: user.Email,
        Initials: getInitialsFromEmail(user.Email),
      };
      if (queryEmail === user?.Email) {
        navigate(
          `/otp-login?Email=${user.Email}&WorkspaceId=${queryWorkspaceId}&SubWorkspaceId=${querySubWorkspaceId}`,
          { state: { userData: userData } }
        );
        return;
      }
      navigate(`/otp-login`, { state: { userData: userData } });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // Redirect to home if user is authenticated
  useEffect(() => {
    const workspace = localStorage.getItem("selectedWorkspace");
    const subworkspace = localStorage.getItem("selectedSubWorkspace");
    if (isAuthenticated && workspace && subworkspace) {
      navigate("/feed-public"); // Redirect to the previous page
    } else {
      localStorage.clear(); // Clear local storage if not authenticated
    } // eslint-disable-next-line
  }, [isAuthenticated]);
  const handleGoogleLogin = () => {
    signInWithPopup(auth, provider)
      .then((userCredential) => {
        const credential =
          GoogleAuthProvider.credentialFromResult(userCredential);
        if (credential) {
          const accessToken = credential.accessToken;
          localStorage.setItem("accessToken", accessToken);
          const user = userCredential.user;
          setUser({
            Email: user?.email,
          });
          signIn.mutate({
            email: user?.email,
            accessToken: accessToken,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleAppleLogin = async () => {
    signInWithPopup(authApple, providerApple)
      .then((result) => {
        const user = result.user;
        if (user) {
          setUser({
            Email: user?.email,
          });
          signIn.mutate({
            email: user?.email,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loginFn = async (e) => {
    e.preventDefault();
    if (user.Email) {
      signIn.mutate({
        email: user?.Email,
      });
    } else {
      setEmailError(true);
    }
  };

  const signUpFn = async (e) => {
    e.preventDefault();
    navigate("/signup");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Email validation regex
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (name === "Email") {
      // Check if the email is valid
      if (!emailRegex.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }

    // Update user state
    setUser((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <section className="flex justify-center items-center md:h-screen bg-white">
      <Card className=" flex-col justify-center md:max-h-[600px] shadow-none gap-4 px-4 md:px-10 py-3  sm:border sm:border-debatePalette-buttonBorder">
        <img src={betaLogo} alt="" className="absolute top-0 left-0" style={{ width: 100, height:100 }} />
        <CardHeader
          variant="gradient"
          color="transparent"
          className="grid max-h-20 mt-2  place-items-center shadow-none !static"
        >
          <img src={Logo} alt="" className="max-h-20" />
        </CardHeader>
        <CardBody className="flex flex-col gap-4 p-0">
          <div className="flex flex-col text-center justify-center items-center gap-2 pb-4">
            <Typography className="text-5xl text-debatePalette-title font-semibold">
              {LoginInfo.headLine}
            </Typography>
            <Typography variant="small" className="text-debatePalette-bodyText">
              {LoginInfo.subLine}
            </Typography>
          </div>
          <form className="max-w-[100%] mt-6" onSubmit={loginFn}>
            <div className="flex flex-col w-full justify-center items-center">
              <div className="flex flex-col w-full md:w-2/3 gap-6 mb-3">
                <BasicInputField
                  value={user?.Email}
                  name="Email"
                  fieldSize="lg"
                  placeholder="name@workemail.com"
                  handleChange={handleChange}
                  className={"!border-debatePalette-buttonBorder"}
                  emailError={emailError}
                  type="email"
                  errorMessage="Please enter valid email address"
                  disabled={!!queryEmail}
                />
                <Button
                  id="loginBtn"
                  disabled={emailError || !user?.Email}
                  loading={signIn.isPending}
                  type="submit"
                  variant="gradient"
                  fullWidth
                  color="amber"
                  className="text-black capitalize text-md inline-flex items-center justify-center w-full"
                >
                  Continue
                </Button>
                <div className="inline-flex items-center justify-center w-full">
                  <hr className="w-full h-[1.5px] my-2 bg-debatePalette-hr border-0 dark:bg-gray-700" />
                  <span className="absolute px-3 font-medium text-debatePalette-hr -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">
                    OR
                  </span>
                </div>
                <div className="flex justify-center items-center gap-6">
                  {/* <GoogleLogin
                    onSuccess={handleGoogleLogin}
                    onError={() => {
                      console.log('Login Failed');
                    }}
                  /> */}
                  <Button
                    className="p-2 m-0 mt-1"
                    variant="text"
                    onClick={() => handleGoogleLogin()}
                    id="googleBtn"
                  >
                    <img src={Google} alt="" className="h-8 w-8" />
                  </Button>

                  <Button
                    className="p-2 m-0"
                    variant="text"
                    onClick={() => handleAppleLogin()}
                    id="appleBtn"
                  >
                    <img src={Apple} alt="" className=" h-8 w-8" />
                  </Button>
                </div>
              </div>
            </div>
            <div className="pt-0 flex flex-col  w-full justify-center items-center mb-2">
              <Typography variant="small" className=" flex justify-center">
                Don&apos;t have an account?
                <Button
                  id="signupBtn"
                  ripple={false}
                  variant="text"
                  color="black"
                  onClick={signUpFn}
                  className={`ml-1 font-bold capitalize text-md underline ${hoverTextStyle} p-0 focus:bg-transparent active:bg-transparent hover:bg-transparent`}
                >
                  Sign up
                </Button>
              </Typography>
            </div>
          </form>
        </CardBody>
        <CardFooter className="pt-0">
          <SignupFooter info={LoginInfo} />
        </CardFooter>
      </Card>
    </section>
  );
}
