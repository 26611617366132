// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkCategoryAgent } from "../../api/agents/agentsApi";
import { useAuth } from "../../Context/AuthContext";

export const useCategoryAgent = (motion, category, image) => {
  const { user: loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["category-validate", motion, category, image],
    queryFn: async () => {
      return await checkCategoryAgent({ motion, category, img_url: image, user_id: loggedInUser?.userId, sessionid: loggedInUser?.sessionId });
    },
    select: (data) => data.data,
    enabled: !!motion && !!category && !!loggedInUser,
  });
};
