import { Switch, Typography } from "@material-tailwind/react";
import React from "react";
import Tooltip from "../../Components/UI/Tooltip/Tooltip";
import { useAuth } from "../../Context/AuthContext";

const InvitationNotification = ({ type, settingInfo }) => {
  const { user } = useAuth();
  const handleChangeNotification = (type, value, workId) => {
    const notifyObj = {
      loggedInUserId: user?.userId,
      notificationStatus: value,
      Type: type,
    };
    const notifyWorkspaceObj = {
      loggedInUserId: user?.userId,
      workspaceId: workId,
      notificationStatus: value,
      Type: type,
    };

    workId ? console.log(notifyWorkspaceObj) : console.log(notifyObj);
  };

  return (
    <>
      <Typography
        variant="h6"
        className="text-debatePalette-title font-semibold py-3"
      >
        Debatebase Invitation
      </Typography>
      <hr className="border-gray-300" />
      <form className="flex flex-col gap-4 overflow-y-auto py-3">
        <div className="flex w-full items-center  justify-between">
          <label htmlFor="all" color="blue-gray" className="font-medium">
            Pause All
          </label>
          <Switch
            id="all"
            name="all"
            color="blue"
            checked={settingInfo?.PauseAllNotification}
            // onClick={() =>
            //   handleChangeNotification("Invitation", !settingInfo.PauseAllNotification)
            // }
            onChange={() =>
              handleChangeNotification(
                "Invitation",
                !settingInfo.PauseAllNotification
              )
            }
            containerProps={{
              className: "flex-shrink-0 p-0 w-16",
            }}
          />
        </div>
        <hr className="border-gray-300" />

        {!settingInfo.PauseAllNotification &&
          settingInfo?.debatebaseNotificationOptions?.map((workspace) => (
            <div className="flex w-full items-center justify-between">
              <Tooltip
                position="top-start"
                content={workspace?.workspaceName}
                containerClass="w-full"
              >
                <label
                  htmlFor={workspace?.workspaceId}
                  color="blue-gray"
                  className="font-medium line-clamp-1 overflow-x-hidden"
                >
                  {workspace?.workspaceName}
                </label>
              </Tooltip>
              <Switch
                id={workspace?.workspaceId}
                name={workspace?.workspaceId}
                color="blue"
                checked={workspace?.notificationStatus}
                // onClick={() => {}}
                onChange={() =>
                  handleChangeNotification(
                    "Invitation",
                    !settingInfo.PauseAllNotification,
                    workspace?.workspaceId
                  )
                }
                containerProps={{
                  className: "flex-shrink-0 p-0 w-16",
                }}
              />
            </div>
          ))}
      </form>
    </>
  );
};

export default InvitationNotification;
