// useArgumentAgent.js
import { useQuery } from "@tanstack/react-query";
import { checkMotionAgent } from "../../api/agents/agentsApi";
import { useAuth } from "../../Context/AuthContext";

export const useMotionAgent = (Debate_motion) => {
  const { user: loggedInUser } = useAuth();
  return useQuery({
    queryKey: ["motion-validate", Debate_motion],
    queryFn: async () => {
      return await checkMotionAgent({
        Debate_motion: Debate_motion,
        user_id: loggedInUser?.userId,
        sessionid: loggedInUser?.sessionId,
      });
    },
    select: (data) => data.data,
    enabled: !!Debate_motion && !!loggedInUser,
  });
};

